import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHandleClick } from "../../hooks/useHandleClick";

import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { DashIcon } from "../assets/Icons";

function SelectDropDown({
  options,
  variation,
  value,
  onChange,
  isLoading,
  label,
  errMessage,
  placeholder,
  onTrigger,
  isCompare,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const [selectedLabel, setSelectedLabel] = useState("");
  const ref = useHandleClick(() => setIsOpen(false));
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;

  const handleSelect = selectedValue => {
    onChange(selectedValue);
    setIsOpen(false);
    setSelectedLabel(options.find(option => option.value === selectedValue).label);
  };

  console.log(value);

  useEffect(() => {
    if (value && options) {
      const selectedOption = options?.find(option => option.value === value);
      if (!selectedOption) {
        setSelectedLabel(null);
        onChange(null);
      }
    }
  }, [onChange, options, value]);

  useEffect(() => {
    if (value && options) {
      const selectedOption = options.find(option => option.value === value);
      if (selectedOption) {
        setSelectedLabel(selectedOption.label);
      }
    } else {
      setSelectedLabel(null);
    }
  }, [value, options]);

  const dropdownWidth = buttonRef.current ? buttonRef.current.offsetWidth : "auto";

  const handleTrigger = () => {
    setIsOpen(!isOpen);
    onTrigger && onTrigger();
  };

  return (
    <DropdownContainer variation={variation}>
      {label && <Label>{label}</Label>}
      <div ref={ref} style={{ width: "100%", position: "relative" }}>
        <TriggerButton onClick={handleTrigger} ref={buttonRef} isCompare={isCompare}>
          {selectedLabel ? <span>{selectedLabel}</span> : <Placeholder>{placeholder || t.select}</Placeholder>}

          {isCompare ? <DashIcon /> : <StyledArrowIcon isOpen={isOpen} />}
        </TriggerButton>

        {isOpen && (
          <DropdownMenu width={dropdownWidth}>
            <ul>
              {/* {isLoading && <OptionItem>{"Loading..."}</OptionItem>} */}
              {options?.map(
                (option, index) =>
                  !option.hidden && (
                    <OptionItem key={index} onClick={() => handleSelect(option.value)}>
                      {option.label}
                    </OptionItem>
                  )
              )}
              {/* {options?.length === 0 && <OptionItem>{"No data"}</OptionItem>} */}
            </ul>
          </DropdownMenu>
        )}
      </div>
      <ErrorMessage ErrorMessage>{errMessage}</ErrorMessage>
    </DropdownContainer>
  );
}

export default memo(SelectDropDown);

// Styled Components
const DropdownContainer = styled.div`
  display: ${({ variation }) => (variation === "row" ? "flex" : "block")};
  width: ${({ variation }) => (variation === "row" ? "auto" : "100%")};
  align-items: ${({ variation }) => (variation === "row" ? "center" : "unset")};
  gap: calc((8 / 1440) * 100vw);
`;

const Label = styled.div`
  padding-bottom: calc((10 / 1440) * 100vw);
  /* padding-top: 0.4rem; */
  padding-top: calc((10 / 1440) * 100vw);
  z-index: 0;
  font-size: calc((14 / 1440) * 100vw);
  color: #4f4f4f;
`;

const TriggerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.isCompare ? "calc((16 / 1440) * 100vw)" : "calc((10 / 1440) * 100vw)")};
  border: ${props => (props.isCompare ? "none" : "calc((1 / 1440) * 100vw) solid #e5e5e5")};
  border-bottom: ${props => (props.isCompare ? "calc((1 / 1440) * 100vw) solid #e5e5e5" : "none")};
  border-radius: ${props => (props.isCompare ? "0" : "calc((8 / 1440) * 100vw)")};
  width: 100%;
  font-size: calc((14 / 1440) * 100vw);

  cursor: pointer;
  z-index: 1;
  background-color: white;

  &:focus {
    border-color: ${({ error }) => (error ? "red" : "black")};
  }
  span {
    color: #4f4f4f;
  }

  @media (max-width: 768px) {
    padding: calc((10 / 375) * 100vw);
    font-size: calc((12 / 375) * 100vw);
  }
`;

const DropdownMenu = styled.div`
  width: ${({ width }) => `${width}px` || "auto"};
  position: absolute;
  top: 100%;
  background-color: white;
  z-index: 10;
  padding: calc((8 / 1440) * 100vw);
  margin-top: calc((8 / 1440) * 100vw);
  border-radius: calc((8 / 1440) * 100vw);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-height: calc((200 / 1440) * 100vw);
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: calc((8 / 375) * 100vw);
    margin-top: calc((8 / 375) * 100vw);
    max-height: calc((200 / 375) * 100vw);
  }
`;

const OptionItem = styled.li`
  padding: calc((8 / 1440) * 100vw);
  border-radius: calc((8 / 1440) * 100vw);
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    padding: calc((8 / 375) * 100vw);
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-weight: 500;
  font-size: calc((14 / 1440) * 100vw);
`;

const StyledArrowIcon = styled(ArrowIcon)`
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease;
  stroke: #6b6b6b;
`;

const Placeholder = styled.div`
  color: #b9b9b9;
`;
