import React from "react";
import styled from "styled-components";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const StyledModal = ({ children }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return <StyledModalDiv language={language}>{children}</StyledModalDiv>;
};

const StyledModalDiv = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  width: 32.5vw;
  //   height: 34.653vw;
  background: #ffffff;
  border-radius: ${desktopSizes._8px};
  padding: ${desktopSizes._16px} ${desktopSizes._24px} ${desktopSizes._32px};
  .header {
    display: flex;
    align-items: center;
    gap: 9.514vw;
    margin-bottom: ${desktopSizes._32px};
    .close-btn {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      svg {
        width: ${desktopSizes._32px};
        height: ${desktopSizes._32px};
        @media (min-width: 1440px) {
          width: 32px;
          height: 32px;
        }
      }
    }
    .logo {
      width: 6.875vw;
      height: ${desktopSizes._24px};
      @media (min-width: 1440px) {
        width: 99px;
        height: 24px;
      }
    }
  }
  .title-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${desktopSizes._12px};
    margin-bottom: ${desktopSizes._24px};
    .title {
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: 500;
      font-size: ${desktopSizes._24px};
      line-height: ${desktopSizes._36px};
      color: #0d0a19;
      @media (min-width: 1440px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
    .text {
      width: 18.264vw;
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "400" : "300")};
      font-size: ${desktopSizes._14px};
      line-height: ${desktopSizes._21px};
      color: #9a9ea7;
      text-align: center;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .form-container {
    margin-bottom: ${desktopSizes._16px};
    .fields .input-div input {
      height: 4.375vw;
      padding: 1.528vw 1.111vw;
    }
    .fields .input-div .label_active {
      padding-top: 2.5vw;
      padding-bottom: 1.111vw;
    }
    .fields .input-div .password-input {
      padding-right: ${props => props.language == "en" && "4.167vw"};
      padding-left: ${props => props.language == "ar" && "4.167vw"};
    }
    .fields .input-div .mobile-input-div {
      input {
        padding: ${props =>
          props.language == "en" ? "1.667vw 1.111vw 1.667vw 8.681vw" : "1.667vw 8.681vw  1.667vw 1.111vw"};
      }
      .select-code {
        top: ${desktopSizes._21px};
        .select-input-div {
          .flag {
            height: 1.319vw;
          }
        }
        input {
          height: 1.319vw;
          padding: 0;
          background-position-y: 50%;
          // @media (min-width: 1440px) {
          //   height : 35px;
          // }
        }
      }
    }

    .fields .input-div .label-active {
      input {
        padding: ${props =>
          props.language == "en" ? "2.292vw 1.111vw 0.972vw 8.681vw" : "2.292vw 8.681vw  0.972vw 1.111vw"};
      }
      .select-code {
        top: ${desktopSizes._32px};
      }
    }
  }
  .switch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${desktopSizes._4px};

    p,
    button {
      font-style: normal;
      font-size: ${desktopSizes._16px};
      line-height: ${desktopSizes._24px};
      color: #0d0a19;
      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    p {
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-weight: 400;
    }
    button {
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-weight: ${props => (props.language == "en" ? "600" : "700")};
      border: none;
      outline: none;
      background: none;
      color: #154cd6;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    width: 91.467vw;
    border-radius: ${mobileSizes._8px};
    padding: ${mobileSizes._24px} ${mobileSizes._16px} ${mobileSizes._24px};
    .header {
      gap: 20.267vw;
      margin-bottom: ${mobileSizes._30px};
      .close-btn {
        svg {
          width: ${mobileSizes._30px};
          height: ${mobileSizes._30px};
        }
      }
      .logo {
        width: 26.667vw;
        height: ${mobileSizes._24px};
      }
    }
    .title-text {
      gap: ${mobileSizes._12px};
      margin-bottom: ${mobileSizes._16px};
      .title {
        font-size: ${mobileSizes._16px};
        line-height: ${mobileSizes._24px};
      }
      .text {
        width: 71.2vw;
        font-size: ${mobileSizes._14px};
        line-height: ${mobileSizes._21px};
      }
    }
    .form-container {
      margin-bottom: ${mobileSizes._16px};
      .fields .input-div input {
        width: 82.933vw;
        height: 14.667vw;
        padding: ${mobileSizes._18px} ${mobileSizes._16px};
      }
      .fields .input-div .label_active {
        padding-top: 6.933vw;
        padding-bottom: ${mobileSizes._18px};
      }
      .fields .input-div .password-input {
        padding-right: ${props => props.language == "en" && "12.8vw"};
        padding-left: ${props => props.language == "ar" && "12.8vw"};
      }
      .fields .input-div .mobile-input-div {
        input {
          padding: ${props =>
            props.language == "en"
              ? `${mobileSizes._20px} ${mobileSizes._16px} ${mobileSizes._20px} 30.4vw`
              : `${mobileSizes._20px} 30.4vw  ${mobileSizes._20px} ${mobileSizes._16px}`};
        }
        .select-code {
          top: ${mobileSizes._18px};
          .select-input-div {
            .flag {
              height: ${mobileSizes._19px};
            }
          }
          input {
            height: ${mobileSizes._19px};
            padding: 0;
            background-position-y: 50%;
          }
        }
      }
      .fields .input-div .label-active {
        input {
          padding: ${props =>
            props.language == "en"
              ? `${mobileSizes._30px} ${mobileSizes._16px} ${mobileSizes._8px} 30.4vw`
              : `${mobileSizes._30px} 30.4vw  ${mobileSizes._8px} ${mobileSizes._16px}`};
        }
        .select-code {
          top: 7.733vw;
        }
      }
      .btn-container {
        button {
          width: 82.933vw;
        }
      }
    }
    .switch {
      gap: ${mobileSizes._4px};

      p,
      button {
        font-size: ${mobileSizes._14px};
        line-height: ${mobileSizes._24px};
      }
    }
  }
`;

export default StyledModal;
