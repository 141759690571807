import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as RightArrow } from "../../images/arrow-right.svg";
import { Link } from "react-router-dom";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const NameDiv = ({ name }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const [nameOverflow, setNameOverflow] = useState();
  const nameRef = useRef();

  useEffect(() => {
    if (nameRef.current?.scrollWidth > nameRef.current?.clientWidth) {
      setNameOverflow(true);
    }
  }, [nameRef.current]);
  return (
    <div
      className="name"
      ref={nameRef}
      // style={nameOverflow && language == "ar" ? { direction: "ltr" } : {}}
    >
      {name}
    </div>
  );
};

const ModelsCard = ({ model }) => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledCard language={language}>
      <div className="logo-container">
        <img src={model.cars[0]?.car.meta_data?.images?.image1 || "/car-placeholder.jpg"} alt="logo" className="logo" />
      </div>
      <div className="model-and-cars">
        <div className="model">
          {language == "en" ? model.model_name : model.model_name_ar}{" "}
          <span style={{ fontVariantNumeric: "tabular-nums" }}>{model.cars[0]?.car.year}</span>
        </div>
        <div className="cars desktop-only">
          {model.cars.map(car_detail => (
            <div className="car desktop-only" key={car_detail.car.name}>
              {/* <div className="name">{car_detail.car.name}</div> */}
              <div className="name">
                {language == "en"
                  ? car_detail.car.name
                  : car_detail.car.name_ar.split(/(\d+)/).map((part, index) => {
                      if (/\d+/.test(part)) {
                        // Applying the font-variant-numeric style to the number part
                        return (
                          <span
                            key={index}
                            style={{
                              fontVariantNumeric: "tabular-nums",
                              fontFamily: "Poppins",
                            }}
                          >
                            {part}
                          </span>
                        );
                      } else return part;
                    })}
              </div>

              <Link className="price" to={"/car/" + car_detail.car.id}>
                <div className="price-value">
                  <p> {(+(+car_detail.car_price).toFixed(2)).toLocaleString().replace(/\.00$/, "")}</p>
                  <span className="currency">{t.sar}</span>
                </div>
                <RightArrow />
              </Link>
            </div>
          ))}
        </div>

        <div className="cars mobile-only">
          {model.cars.map(car_detail => (
            <div className="mobile-car mobile-only" key={car_detail.car.name}>
              <div className="name-price">
                <div className="name">
                  {language == "en"
                    ? car_detail.car.name
                    : car_detail.car.name_ar.split(/(\d+)/).map((part, index) => {
                        if (/\d+/.test(part)) {
                          // Applying the font-variant-numeric style to the number part
                          return (
                            <span
                              key={index}
                              style={{
                                fontVariantNumeric: "tabular-nums",
                                fontFamily: "Poppins",
                              }}
                            >
                              {part}
                            </span>
                          );
                        } else return part;
                      })}
                </div>
                <Link className="price" to={"/car/" + car_detail.car.id}>
                  <div className="price-value">
                    <p>{(+(+car_detail.car_price).toFixed(2)).toLocaleString().replace(/\.00$/, "")}</p>
                    <span className="currency">{t.sar}</span>
                  </div>
                </Link>
              </div>
              <Link className="arrow" to={"/car/" + car_detail.car.id}>
                <RightArrow />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  width: 57.6vw;
  display: flex;
  align-items: flex-start;
  gap: 1.111vw;
  padding: 1.111vw;
  background: #ffffff;
  border: 0.069vw solid #e4e6e8;
  border-radius: 0.278vw;
  .logo {
    width: 17.153vw;
    height: 12.847vw;
    object-fit: cover;
  }
  .model-and-cars {
    .model {
      margin-bottom: 1.806vw;
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "600" : "700")};
      font-size: 1.111vw;
      line-height: 1.667vw;
      color: #0d0a19;
      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .cars {
      display: flex;
      flex-direction: column;
      gap: 1.111vw;
      width: 32.5vw;
      .car {
        padding-bottom: 1.111vw;
        padding-right: ${props => props.language == "en" && " 1.389vw"};
        padding-left: ${props => props.language == "ar" && " 1.389vw"};
        border-bottom: 0.069vw solid #e4e6e8;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
          font-style: normal;
          font-weight: 500;
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: #0d0a19;
          width: 22.431vw;
          text-overflow: ellipsis;
          white-space: nowrap; /* keeps the text in a single line */
          overflow: hidden;

          @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 21px;
          }
        }
        .price {
          display: flex;
          align-items: center;
          gap: 0.417vw;
          svg {
            width: 1.667vw;
            height: 1.667vw;
            object-fit: contain;
            -webkit-transform: ${props => props.language == "ar" && " scaleX(-1)"};
            -moz-transform: ${props => props.language == "ar" && " scaleX(-1)"};
            -o-transform: ${props => props.language == "ar" && " scaleX(-1)"};
            transform: ${props => props.language == "ar" && " scaleX(-1)"};

            @media (min-width: 1440px) {
              width: 24px;
              height: 24px;
            }
          }
          .price-value {
            direction: ltr;
            display: flex;
            align-items: center;
            gap: 0.278vw;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 0.972vw;
            line-height: normal;
            color: #154cd6;
            @media (min-width: 1440px) {
              font-size: 14px;
              line-height: 21px;
            }

            .currency {
              font-size: ${desktopSizes._10px};
              line-height: normal;

              @media (min-width: 1440px) {
                font-size: 10px;
                line-height: 15px;
              }
            }
          }
        }
      }
      [class~="car"]:last-of-type {
        border: none;
      }
    }
  }

  @media (max-width: 768px) {
    width: 91.467vw;
    flex-direction: column;
    gap: ${mobileSizes._16px};
    padding: ${mobileSizes._8px} 0 ${mobileSizes._16px} 0;
    border: ${mobileSizes._1px} solid #e4e6e8;
    border-radius: ${mobileSizes._4px};
    .logo-container {
      width: 91.467vw;
      padding: 0 ${mobileSizes._8px};
      .logo {
        width: 87.2vw;
        height: 31.467vw;
        object-fit: contain;
      }
    }
    .model-and-cars {
      padding: ${props =>
        props.language == "en"
          ? ` 0 ${mobileSizes._16px} 0 ${mobileSizes._18px}`
          : ` 0 ${mobileSizes._18px} 0 ${mobileSizes._16px}`};

      .model {
        margin-bottom: ${mobileSizes._16px};
        font-size: ${mobileSizes._14px};
        line-height: ${mobileSizes._21px};
      }
      .cars {
        gap: ${mobileSizes._16px};
        width: 82.4vw;
        .mobile-car {
          padding: 0;
          border-bottom: ${mobileSizes._1px} solid #e4e6e8;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name-price {
            .name {
              font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
              font-style: normal;
              font-weight: 500;
              font-size: ${mobileSizes._12px};
              line-height: ${mobileSizes._18px};
              color: #0d0a19;
              width: 78vw;
              text-overflow: ellipsis;
              white-space: nowrap; /* keeps the text in a single line */
              overflow: hidden;
            }
            .price {
              .price-value {
                direction: ltr;
                display: flex;
                align-items: center;
                justify-content: ${props => props.language == "ar" && "flex-end"};
                gap: ${mobileSizes._4px};
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: ${mobileSizes._14px};
                line-height: ${mobileSizes._21px};
                color: #154cd6;
                .currency {
                  font-size: ${mobileSizes._10px};
                  line-height: ${mobileSizes._15px};
                }
              }
            }
          }
          .arrow {
            svg {
              width: ${mobileSizes._20px};
              height: ${mobileSizes._20px};
              -webkit-transform: ${props => props.language == "ar" && " scaleX(-1)"};
              -moz-transform: ${props => props.language == "ar" && " scaleX(-1)"};
              -o-transform: ${props => props.language == "ar" && " scaleX(-1)"};
              transform: ${props => props.language == "ar" && " scaleX(-1)"};
            }
          }
        }
        [class~="mobile-car"]:last-of-type {
          border: none;
        }

        // .mobile-car:last-of-type {
        //   border: none;
        // }
      }
    }
  }
`;
export default ModelsCard;
