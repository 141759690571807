import React from "react";
import styled from "styled-components";
import { CheckIon, XIcon } from "../assets/Icons";
import useTranslation from "../../hooks/useTranslation";

const Container = styled.div`
  margin: 0 auto;
  background-color: white;
  border-radius: calc(8 / 1440 * 100vw);
  box-shadow: 0 calc(4 / 1440 * 100vw) calc(6 / 1440 * 100vw) rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    border-radius: calc(8 / 375 * 100vw);
    box-shadow: 0 calc(4 / 375 * 100vw) calc(6 / 375 * 100vw) rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #1f2937;
  color: white;
  padding: calc(16 / 1440 * 100vw);
  border-top-left-radius: calc(8 / 1440 * 100vw);
  border-top-right-radius: calc(8 / 1440 * 100vw);
  font-size: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(16 / 375 * 100vw);
    border-top-left-radius: calc(8 / 375 * 100vw);
    border-top-right-radius: calc(8 / 375 * 100vw);
    font-size: calc(14 / 375 * 100vw);
  }
`;

const HeaderCell = styled.div`
  &:first-child {
    grid-column: span 1;
  }
  &:not(:first-child) {
    text-align: center;
  }
`;

const SectionHeader = styled.div`
  background-color: #dbeafe;
  padding: calc(16 / 1440 * 100vw);
  font-weight: 600;
  color: #1f2937;
  font-size: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(12 / 375 * 100vw);
    font-size: calc(20 / 375 * 100vw);
    background-color: #fff;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: calc(1 / 1440 * 100vw) solid #e5e7eb;

  @media (max-width: 768px) {
    border-bottom: calc(1 / 375 * 100vw) solid #e5e7eb;
    grid-template-columns: repeat(6, 1fr);
  }
`;

const Label = styled.div`
  padding: calc(16 / 1440 * 100vw);
  background-color: #f9fafb;
  font-weight: 500;
  color: #374151;
  font-size: calc(14 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(12 / 375 * 100vw);
    font-size: calc(14 / 375 * 100vw);
    grid-column: span 6;
    background-color: #d0dbf7;
    text-align: center;
    color: black;
    font-weight: 600;
  }
`;

const Value = styled.div`
  padding: calc(16 / 1440 * 100vw);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(14 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(12 / 375 * 100vw);
    font-size: calc(14 / 375 * 100vw);
    grid-column: span 3;
  }
`;

const Section = styled.div``;

const CheckIcon = styled(CheckIon)`
  width: calc(16 / 1440 * 100vw);
  height: calc(16 / 1440 * 100vw);
  color: #059669;

  @media (max-width: 768px) {
    width: calc(14 / 375 * 100vw);
    height: calc(14 / 375 * 100vw);
  }
`;

const CheckMarkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: calc(8 / 1440 * 100vw);
  font-size: calc(14 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(6 / 375 * 100vw);
    font-size: calc(12 / 375 * 100vw);
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: calc(16 / 1440 * 100vw);
  padding-inline-start: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(12 / 375 * 100vw);
    padding-inline-start: calc(12 / 375 * 100vw);
  }
`;

// إضافة أنماط عامة للجدول
const TableContainer = styled.div`
  * {
    font-family: ${props => (props.language === "en" ? "Poppins" : "Almarai")};
  }
`;

// إضافة أنماط للحالات الخاصة
const EmptyState = styled.div`
  text-align: center;
  padding: calc(32 / 1440 * 100vw);
  color: #6b7280;
  font-size: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(24 / 375 * 100vw);
    font-size: calc(14 / 375 * 100vw);
  }
`;

const LoadingState = styled.div`
  text-align: center;
  padding: calc(32 / 1440 * 100vw);
  color: #6b7280;

  @media (max-width: 768px) {
    padding: calc(24 / 375 * 100vw);
  }
`;

const ComparisonTable = ({ car1, car2 }) => {
  const { t, language } = useTranslation();
  const renderCheckmark = value => {
    return value ? (
      <CheckMarkContainer>
        <CheckIcon />
        {value}
      </CheckMarkContainer>
    ) : (
      ""
    );
  };

  if (!car1 || !car2) {
    return null;
  }

  const renderSection = (title, fields) => (
    <Section>
      <SectionHeader>{title}</SectionHeader>
      {fields.map(({ label, value1, value2 }) => (
        <Row key={label}>
          <Label>{label}</Label>
          <Value>{value1}</Value>
          <Value>{value2}</Value>
        </Row>
      ))}
    </Section>
  );

  const getOverviewSection = () => ({
    title: t.comparison.overview,
    fields: [
      {
        label: t.comparison.name,
        value1: language === "en" ? car1?.car?.name : car1?.car?.name_ar || car1?.car?.name,
        value2: language === "en" ? car2.car.name : car2.car.name_ar || car2.car.name,
      },
      {
        label: t.comparison.startingPrice,
        value1: `${car1?.car_price} ${t.sar}`,
        value2: `${car2?.car_price} ${t.sar}`,
      },
    ],
  });

  const getPerformanceSection = () => ({
    title: t.comparison.performanceFeatures,
    fields: [
      {
        label: t.comparison.fuelType,
        value1: car1?.car.fuel_type,
        value2: car2.car.fuel_type,
      },
      {
        label: t.comparison.engineBatteryCapacity,
        value1: `${car1?.car.litre_capacity}L`,
        value2: `${car2.car.litre_capacity}L`,
      },
      {
        label: t.comparison.cylinders,
        value1: `${car1?.car.cylinders} ${t.comparison.cylinders}`,
        value2: `${car2.car.cylinders} ${t.comparison.cylinders}`,
      },
      {
        label: t.comparison.transmission,
        value1: car1?.car.transmission,
        value2: car2.car.transmission,
      },
      {
        label: t.comparison.horsepower,
        value1: `${car1?.car.horse_power} hp`,
        value2: `${car2.car.horse_power} hp`,
      },
      {
        label: t.comparison.gearBox,
        value1: car1?.car.gear_box?.toUpperCase(),
        value2: car2.car.gear_box?.toUpperCase(),
      },
      {
        label: t.comparison.consumption,
        value1: car1?.car.consumption,
        value2: car2.car.consumption,
      },
      {
        label: t.comparison.driveTrain,
        value1: car1?.car.drive_train?.toUpperCase(),
        value2: car2.car.drive_train?.toUpperCase(),
      },
    ],
  });

  const safetyOptions = [
    { value: "has_blind_spot_sensor", label: t.comparison.blindSpotSensor },
    { value: "has_alarm_system", label: t.comparison.alarmSystem },
    { value: "has_lane_departure_warning", label: t.comparison.laneDepartureWarning },
    { value: "has_tire_pressure_monitor", label: t.comparison.tirePressureMonitor },
    { value: "has_side_airbags", label: t.comparison.sideAirbags },
    { value: "has_rear_airbags", label: t.comparison.rearAirbags },
    { value: "has_stability_control", label: t.comparison.stabilityControl },
    { value: "has_brake_assist", label: t.comparison.brakeAssist },
    { value: "has_front_rear_sensors", label: t.comparison.frontRearSensors },
    { value: "has_collision_avoidance", label: t.comparison.collisionAvoidance },
    { value: "has_driver_passenger_airbags", label: t.comparison.driverPassengerAirbags },
    { value: "has_driving_modes", label: t.comparison.drivingModes },
    { value: "has_fog_lights", label: t.comparison.fogLights },
    { value: "has_protection_visors", label: t.comparison.protectionVisors },
    { value: "has_360_camera", label: t.comparison.camera360 },
    { value: "has_seat_belt_reminder", label: t.comparison.seatBeltReminder },
    { value: "has_abs", label: t.comparison.abs },
    { value: "has_electronic_brake_force_distribution", label: t.comparison.electronicBrakeForceDistribution },
  ];

  const luxuryOptions = [
    { value: "has_cooled_seats", label: t.comparison.cooledSeats },
    { value: "has_head_up_display", label: t.comparison.headUpDisplay },
    { value: "has_leather_seats", label: t.comparison.leatherSeats },
    { value: "has_premium_wheels", label: t.comparison.premiumWheels },
    { value: "has_active_motion_front_seats", label: t.comparison.activeMotionFrontSeats },
    { value: "has_wireless_charging_pad", label: t.comparison.wirelessChargingPad },
    { value: "has_integrated_chrome", label: t.comparison.integratedChrome },
    { value: "has_door_courtesy_lamp_with_logo", label: t.comparison.doorCourtesyLamp },
    { value: "has_interior_ambient_lighting", label: t.comparison.interiorAmbientLighting },
    { value: "has_display", label: "Display" },
    { value: "has_power_seats_lumbar_control", label: t.comparison.powerSeatsLumbarControl },
  ];

  const convenienceAndComfortOptions = [
    { value: "has_cruise_control", label: t.comparison.cruiseControl },
    { value: "has_keyless_entry", label: t.comparison.keylessEntry },
    { value: "has_start_engine_button", label: t.comparison.startEngineButton },
    { value: "has_rear_camera", label: t.comparison.rearCamera },
    { value: "has_memory_seating", label: t.comparison.memorySeating },
    { value: "has_power_locks", label: t.comparison.powerLocks },
    { value: "has_power_windows", label: t.comparison.powerWindows },
    { value: "has_steering_wheel_controls", label: t.comparison.steeringWheelControls },
    { value: "has_Remote_start", label: t.comparison.remoteStart },
    { value: "has_power_side_mirrors", label: t.comparison.powerSideMirrors },
    { value: "has_intelligent_cruise_control", label: t.comparison.intelligentCruiseControl },
    { value: "has_leather_steering_wheel", label: t.comparison.leatherSteeringWheel },
    { value: "has_auto_dimming_rear_view", label: t.comparison.autoDimmingRearView },
    { value: "has_floor_mats", label: t.comparison.floorMats },
    { value: "has_multi_zone_climate_control", label: t.comparison.multiZoneClimateControl },
  ];

  const entertainmentAndTechnologyOptions = [
    { value: "has_navigation", label: t.comparison.navigation },
    { value: "has_bluetooth", label: t.comparison.bluetooth },
    { value: "has_voice_command", label: t.comparison.voiceCommand },
    { value: "has_premium_sound_system", label: t.comparison.premiumSoundSystem },
    { value: "has_secuirty_system", label: t.comparison.securitySystem },
    { value: "has_apple_carplay", label: t.comparison.appleCarplay },
    { value: "has_radio", label: t.comparison.radio },
  ];

  const getSafetySection = () => ({
    title: t.comparison.safety,
    fields: [
      {
        label: t.comparison.airbags,
        value1: car1?.car.airbag ? `${car1?.car.airbag} ${t.comparison.airbags}` : " - ",
        value2: car2.car.airbag ? `${car2.car.airbag} ${t.comparison.airbags}` : " - ",
      },
      {
        label: t.comparison.sensors,
        value1: car1?.car.sensor,
        value2: car2.car.sensor,
      },
      {
        label: t.comparison.safetyOptions,
        value1: (
          <OptionsContainer>
            {safetyOptions.map(({ value, label }) => {
              return car1?.car[value] ? renderCheckmark(label) : null;
            })}
          </OptionsContainer>
        ),
        value2: (
          <OptionsContainer>
            {safetyOptions.map(({ value, label }) => {
              return car2.car[value] ? renderCheckmark(label) : null;
            })}
          </OptionsContainer>
        ),
      },
    ],
  });

  const getLuxurySection = () => ({
    title: t.comparison.luxury,
    fields: [
      {
        label: t.comparison.roofType,
        value1: car1?.car.roof,
        value2: car2.car.roof,
      },
      {
        label: t.comparison.luxuryOptions,
        value1: (
          <OptionsContainer>
            {luxuryOptions.map(({ value, label }) => {
              return car1?.car[value] ? renderCheckmark(label) : null;
            })}
          </OptionsContainer>
        ),
        value2: (
          <OptionsContainer>
            {luxuryOptions.map(({ value, label }) => {
              return car2.car[value] ? renderCheckmark(label) : null;
            })}
          </OptionsContainer>
        ),
      },
    ],
  });

  const getConvenienceSection = () => ({
    title: t.comparison.convenienceComfort,
    fields: [
      {
        label: t.comparison.convenienceOptions,
        value1: (
          <OptionsContainer>
            {convenienceAndComfortOptions.map(({ value, label }) => {
              return car1?.car[value] ? renderCheckmark(label) : null;
            })}
          </OptionsContainer>
        ),
        value2: (
          <OptionsContainer>
            {convenienceAndComfortOptions.map(({ value, label }) => {
              return car2.car[value] ? renderCheckmark(label) : null;
            })}
          </OptionsContainer>
        ),
      },
    ],
  });

  const getEntertainmentSection = () => ({
    title: t.comparison.entertainmentTechnology,
    fields: [
      {
        label: t.comparison.speakers,
        value1: `${car1?.car.speakers} ${t.comparison.speakers}`,
        value2: `${car2.car.speakers} ${t.comparison.speakers}`,
      },
      {
        label: t.comparison.entertainmentOptions,
        value1: (
          <OptionsContainer>
            {entertainmentAndTechnologyOptions.map(({ value, label }) => {
              return car1?.car[value] ? renderCheckmark(label) : null;
            })}
          </OptionsContainer>
        ),
        value2: (
          <OptionsContainer>
            {entertainmentAndTechnologyOptions.map(({ value, label }) => {
              return car2.car[value] ? renderCheckmark(label) : null;
            })}
          </OptionsContainer>
        ),
      },
    ],
  });

  const getSpecificationsSection = () => ({
    title: t.comparison.specifications,
    fields: [
      {
        label: t.comparison.wheelSize,
        value1: car1?.car.wheel_size,
        value2: car2.car.wheel_size,
      },
      {
        label: t.comparison.builtInCamera,
        value1: car1?.car.builtin_camera,
        value2: car2.car.builtin_camera,
      },
      {
        label: t.comparison.lightingType,
        value1: car1?.car.lighting_type,
        value2: car2.car.lighting_type,
      },
      {
        label: t.comparison.maxOutput,
        value1: car1?.car.max_output,
        value2: car2.car.max_output,
      },
      {
        label: t.comparison.maxTorque,
        value1: car1?.car.max_torque,
        value2: car2.car.max_torque,
      },
      {
        label: t.comparison.warrantyService,
        value1: `${car1?.car.warranty_years} ${t.comparison.years} / ${car1?.car.warranty_km} ${t.comparison.km}`,
        value2: `${car2.car.warranty_years} ${t.comparison.years} / ${car2.car.warranty_km} ${t.comparison.km}`,
      },
    ],
  });

  const sections = [
    getOverviewSection(),
    getPerformanceSection(),
    getSafetySection(),
    getLuxurySection(),
    getConvenienceSection(),
    getEntertainmentSection(),
    getSpecificationsSection(),
  ];

  return (
    <TableContainer>
      <Container>
        {sections.map(section => (
          <div key={section.title}>{renderSection(section.title, section.fields)}</div>
        ))}
      </Container>
    </TableContainer>
  );
};

export default ComparisonTable;
