import React from "react";
import styled from "styled-components";
import useTranslation from "../../hooks/useTranslation";
import { Controller, useForm } from "react-hook-form";
import { FormRow, Input } from "../MyAccount/FormRow";
import PhoneInputField from "../assets/PhoneInput";

function SellerJoinForm() {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const benefits = [
    t.sellerJoin.increasedVisibility,
    t.sellerJoin.enhancedCustomerReach,
    t.sellerJoin.improvedCustomerEngagement,
    t.sellerJoin.directMarketingOpportunities,
    t.sellerJoin.interactiveVehicleComparisons,
    t.sellerJoin.targetedAdvertisingOptions,
    t.sellerJoin.twentyFourSevenAvailabilityForCustomers,
  ];

  const onSubmit = data => {
    console.log(data);
  };

  return (
    <Container>
      <FormSection>
        <FormTitle>{t.sellerJoin.formTitle}</FormTitle>
        <Subtitle>{t.sellerJoin.formSubtitle}</Subtitle>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormRow label={t.sellerJoin.name} error={errors?.name?.message}>
            <Input
              invalid={!!errors?.name}
              type="text"
              placeholder={t.sellerJoin.enterYourName}
              {...register("name", {
                required: t.thisFieldIsRequired,
              })}
            />
          </FormRow>

          <FormRow label={t.sellerJoin.email} error={errors?.email?.message}>
            <Input
              invalid={!!errors?.email}
              type="email"
              placeholder={t.sellerJoin.enterYourEmail}
              {...register("email", { required: t.thisFieldIsRequired })}
            />
          </FormRow>

          <Controller
            control={control}
            name="phone"
            rules={{
              required: t.thisFieldIsRequired,
              pattern: {
                value: /^\d{10}$/,
                message: t.phoneNumberMustBe10Digits,
              },
            }}
            render={({ field }) => (
              <PhoneInputField label={t.phoneNumber} errMessage={errors?.phone?.message} {...field} />
            )}
          />

          <SubmitButton disabled={false} type="submit">
            {t.send}
          </SubmitButton>
        </Form>
      </FormSection>

      <BenefitsSection>
        <Title>{t.sellerJoin.benefitsTitle}</Title>
        <BenefitList>
          {benefits.map((benefit, index) => (
            <BenefitItem key={index}>{benefit}</BenefitItem>
          ))}
        </BenefitList>
      </BenefitsSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  gap: calc((96 / 1440) * 100vw);
  width: calc(1248 / 1440 * 100vw);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: calc((24 / 768) * 100vw);
  }
`;

const FormSection = styled.div`
  flex: 1;
  background: white;
  padding: calc((30 / 1440) * 100vw);
  border-radius: calc((8 / 1440) * 100vw);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    border-radius: calc((8 / 375) * 100vw);
    padding: calc((12 / 375) * 100vw);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: calc((24 / 1440) * 100vw);

  @media (max-width: 768px) {
    gap: calc((20 / 375) * 100vw);
  }
`;

const FormTitle = styled.p`
  font-size: calc((16 / 1440) * 100vw);
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: calc((16 / 375) * 100vw);
  }
`;

const BenefitsSection = styled.div`
  flex: 1;
  background: white;
  padding: calc((30 / 1440) * 100vw);
  border-radius: calc((8 / 1440) * 100vw);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    border-radius: calc((8 / 375) * 100vw);
    padding: calc((12 / 375) * 100vw);
  }
`;

const Title = styled.h1`
  font-size: calc((28 / 1440) * 100vw);
  color: #000;
  margin-bottom: calc((30 / 1440) * 100vw);

  @media (max-width: 768px) {
    font-size: calc((16 / 375) * 100vw);
    margin-bottom: calc((16 / 375) * 100vw);
  }
`;

const Subtitle = styled.p`
  color: #3b445399;
  margin-top: calc((16 / 1440) * 100vw);
  margin-bottom: calc((24 / 1440) * 100vw);
  font-size: calc((14 / 1440) * 100vw);
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: calc((12 / 375) * 100vw);
    margin-bottom: calc((16 / 375) * 100vw);
    margin-top: calc((16 / 375) * 100vw);
  }
`;

const SubmitButton = styled.button`
  background: #1a56db;
  color: white;
  padding: calc((12 / 1440) * 100vw) calc((32 / 1440) * 100vw);
  border: none;
  border-radius: calc((10 / 1440) * 100vw);
  font-size: calc((15 / 1440) * 100vw);
  margin-top: calc((24 / 1440) * 100vw);
  cursor: pointer;
  transition: background 0.2s;
  width: fit-content;

  &:hover {
    background: #1544c0;
  }

  /* Loading state */
  &[disabled] {
    background: #1a56db;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: calc((15 / 375) * 100vw);
    padding: calc((12 / 375) * 100vw) calc((32 / 375) * 100vw);
    border-radius: calc((10 / 375) * 100vw);
    width: 100%;
  }
`;

const BenefitList = styled.ul`
  list-style: none;
  padding: 0;
`;

const BenefitItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: calc((16 / 1440) * 100vw);
  color: #4a5568;
  font-size: calc((16 / 1440) * 100vw);

  &:before {
    content: "✓";
    color: #10b981;
    margin-inline-end: calc((12 / 1440) * 100vw);
    font-weight: bold;
  }
  @media (max-width: 768px) {
    font-size: calc((16 / 375) * 100vw);
    margin-bottom: calc((16 / 375) * 100vw);
  }
`;

export default SellerJoinForm;
