import React, { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as CallIcon } from "../../images/call.svg";
import { ReactComponent as UserIcon } from "../../images/user.svg";

import { ReactComponent as LanguageIcon } from "../../images/language.svg";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../../redux/slices/LanguageSlice";

import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { desktopSizes } from "../../util/sizes";
import AuthContext from "../../hooks/Auth/providers/AuthContext";
import UserDropDown from "../MyAccount/UserDropdown";
import { CalcIcon, CarIcon } from "../assets/Icons";

const Header = ({}) => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);

  return (
    <StyledHeader language={language} className="desktop-only">
      <div className="col1">
        <CallIcon />
        <div className="callnumber">+996512345679</div>
      </div>

      <div className="col2">
        <button
          className="link-group"
          onClick={() => {
            dispatch(changeLanguage());
          }}
        >
          <LanguageIcon />
          <p>{t.header.lang}</p>
        </button>
        {!user ? (
          <Link to="/sign-up" className="link-group">
            <UserIcon />
            <p>{t.header.signup}</p>
          </Link>
        ) : (
          <UserDropDown />
        )}
        <BuildYourCarLink to="/build-car">
          <CarIcon />
          <p>{t.header.buildYourCar}</p>
        </BuildYourCarLink>
        <CalcYouLoanLink to="/car-finance">
          <CalcIcon />
          <p>{t.header.calcYouLoan}</p>
        </CalcYouLoanLink>
      </div>
    </StyledHeader>
  );
};

const CalcYouLoanLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.417vw;
  background-color: #154cd6;
  padding: 0.556vw 1.111vw;
  border-radius: 0.556vw;
  color: #fff;
  &:hover {
    background-color: #154cd6;
  }
`;

const BuildYourCarLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.417vw;
  padding: 0.556vw 1.111vw;
  border-radius: 0.556vw;
  border: 1px solid #154cd6;
  color: #154cd6;
  transition: all 0.3s ease;
  &:hover {
    background-color: #154cd6;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

const StyledHeader = styled.div`
  width: calc(1248 / 1440 * 100vw);
  margin: 0 auto;
  direction: ${props => (props.language === "en" ? "ltr" : "rtl")};
  * {
    font-family: ${props => (props.language === "en" ? "Poppins" : "Almarai")};
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.111vw 0;
  background: #fff;
  .col1 {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.556vw;
    .callnumber {
      direction: ltr;
      font-weight: 600;
      font-size: 0.972vw;
      line-height: 1.667vw;
      color: #0d0a19;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .col2 {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 1.806vw;
    button {
      background: none;
    }
    .link-group {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.417vw;
      svg {
        width: 1.25vw;
        height: 1.25vw;
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #0d0a19;

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
`;

export default Header;
