import React, { useState, useEffect } from "react";
import styled from "styled-components";
import g1 from "../../images/build-car/gr1.png";
import g2 from "../../images/build-car/gr2.png";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const SectionCard = ({ image, step }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledSection language={language} step={step}>
      <div className="title">
        <p className="row1">{t.buildCarPage.sectionCard.title_line1}</p>
        <p className="row2">{t.buildCarPage.sectionCard.title_line2} ...</p>
      </div>
      <div className="img-container">
        <img src={image} />
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  @keyframes image-fade-in {
    100% {
      opacity: 1;
    }
  }
  width: 46.944vw;
  height: 49.236vw;
  background: #154cd6;
  border-radius: 1.389vw;
  background-image: url(${g1}), url(${g2});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  .title {
    padding: 1.806vw 4.167vw 0 4.167vw;
    p {
      font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
      font-weight: 700;
      line-height: 5.417vw;
      font-size: 3.333vw;
      color: #ffffff;
      @media (min-width: 1440px) {
        font-size: 48px;
        line-height: 78px;
      }
    }
  }
  .img-container {
    opacity: 0;
    animation: image-fade-in 2s ease-in forwards;
    height: 36.528vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 38.681vw;
      height: 25.903vw;
      object-fit: contain;
      -webkit-transform: ${props => props.language === "ar" && props.step != 5 && "scaleX(-1)"};
      transform: ${props => props.language === "ar" && props.step != 5 && "scaleX(-1)"};
    }
  }
`;
export default SectionCard;
