import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as Eye } from "../images/eye.svg";
import { ReactComponent as ClosedEye } from "../images/eye-close.svg";
import Card from "../Components/Auth/Card";
import logo from "../images/logo.svg";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Google } from "../images/social-media/google.svg";
import { ReactComponent as FB } from "../images/social-media/fb2.svg";
import { ReactComponent as Twitter } from "../images/social-media/twitter.svg";
import StyledForm from "../Components/assets/StyledForm";
import { useDispatch, useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import StyledAuthPage from "../Components/Auth/StyledAuthPage";
import { desktopSizes, mobileSizes } from "../util/sizes";
import { useForm } from "react-hook-form";
import SelectMenu from "../Components/assets/SelectMenu";
import { countriesCodes } from "../util/data";
import AuthContext from "../hooks/Auth/providers/AuthContext";
import { login } from "../hooks/Auth/signin/authFunctions";
import history from "../util/history";
import LanguageButton from "../Components/assets/LanguageButton";

const Login = () => {

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const returnUrl = params.get('returnUrl');
  const returnUrl = localStorage.getItem("saved_path");


  const { loginHandler, user, isLoggedIn } = useContext(AuthContext);
  const [loginLoading, setLoginLoading] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  const [formData, setFormData] = useState({
    mobile: "",
    password: "",
    countryCode: "+966",
  });
  const [passwordType, setPasswordType] = useState("password");
  const isDisabled = () => {
    const disabled = Object.values(formData).some(x => x === null || x === "");
    return disabled;
  };
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();


  const loginSubmit = handleSubmit(async data => {
    const { countryCode, mobile, ...formData } = data;
    const formattedMobile = String(mobile).replace(/^0+/, "");
    const formattedData = {
      phonenumber: `${countryCode}${formattedMobile}`,
      ...formData,
    };
    console.log("Final Login Data : ", formattedData);

    setLoginLoading(true);

    try {
      const response = await login(formattedData);
      if (response?.access && response?.refresh) {
        loginHandler({
          accessToken: response.access,
          refreshToken: response.refresh,
        });
        // history.push("/");
        setUserIsLoggedIn(true);
      } else {
        throw new Error("Invalid login response");
      }
    } catch (err) {
      console.error("Error during login:", err);
    } finally {
      setLoginLoading(false);
    }
  });

  useEffect(() => {
    if (user && (userIsLoggedIn || isLoggedIn) && user?.is_phone_verified) {
      if (returnUrl) {
        history.push(returnUrl);
        if (user && user?.is_phone_verified) {
          localStorage.removeItem("saved_path")
        }
      } else {
        history.push('/');
      }
    }
  }, [user, userIsLoggedIn, returnUrl, isLoggedIn]);


  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;

  function isArabic(text) {
    var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    let result = pattern.test(text);
    return result;
  }

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  useEffect(() => {
    // Set the value of countryCode in react-hook-form when formData changes
    setValue("countryCode", formData.countryCode);
  }, [formData.countryCode, setValue]);

  const codeField = {
    name: "code",
    options: countriesCodes,
  };

  return (
    <StyledPage language={language}>
      <StyledAuthPage>
        <Card page={"login"} />
        <div className="container">
          <LangContainer>
            <LanguageButton />
          </LangContainer>
          {/* {loginLoading && <LoadingSpinner />} */}
          <Link to="/" className="desktop-only">
            <img src={logo} className="logo" alt="logo" />
          </Link>
          <div className="title">{t.authPages.login_page.header}</div>
          <div className="text desktop-only">{t.authPages.login_page.text}</div>
          <StyledForm
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className="fields">
              <div className="input-div" key="mobile">
                <div className="mobile-input-div">
                  <input
                    name="mobile"
                    placeholder={t.authPages.mobile}
                    // type={"number"}
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    required={true}
                    onKeyDown={e => {
                      if (e.key === "e" || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                    {...register("mobile", {
                      required: {
                        value: true,
                        message: "required",
                      },
                      validate: value => {
                        if (!isNaN(value) || formData["mobile"]) {
                          return true;
                        } else return "required";
                      },
                      valueAsNumber: true,

                      onChange: e => {
                        const value = e.target.value.replace(/\D/g, "");
                        setFormData({
                          ...formData,
                          mobile: value,
                        });
                      },
                    })}
                    value={formData["mobile"] || ""}
                  />
                  <div className="select-code">
                    <SelectMenu
                      field={codeField}
                      value={formData.countryCode}
                      setValue={value => {
                        setFormData({
                          ...formData,
                          countryCode: value,
                        });
                      }}
                    />
                  </div>
                </div>
                {errors["mobile"]?.message && (
                  <p className="error-text">
                    {language == "en"
                      ? `${t.authPages.mobile} is ${t[errors["mobile"]?.message]}`
                      : `${t[errors["mobile"]?.message]} ${t.authPages.mobile}`}
                  </p>
                )}
              </div>

              <div className="input-div" key="password">
                {formData["password"] && <p className="label">{t.authPages.password}</p>}
                <input
                  name="password"
                  placeholder={t.authPages.password}
                  type={passwordType}
                  required={true}
                  value={formData["password"] || ""}
                  className={`password-input ${formData["password"] ? "label_active" : ""}`}
                  {...register("password", {
                    onChange: e =>
                      setFormData({
                        ...formData,
                        password: e.target.value,
                      }),

                    required: {
                      value: true,
                      message: "required",
                    },
                  })}
                />
                <button
                  className="password-icon"
                  onClick={e => {
                    e.preventDefault();
                    passwordType == "password" ? setPasswordType("text") : setPasswordType("password");
                  }}
                >
                  {passwordType == "password" ? <ClosedEye /> : <Eye />}
                </button>
                {errors["password"]?.message && (
                  <p className="error-text">
                    {language == "en"
                      ? `${t.authPages.password} is ${t[errors["password"]?.message]}`
                      : `${t[errors["password"]?.message]} ${t.authPages.password}`}
                  </p>
                )}
              </div>
            </div>
            <div className="forget-password">
              <Link to="/forget-password">{t.authPages.forget_password}</Link>
            </div>
            <div className="btn-container">
              <button
                disabled={loginLoading}
                className="submit"
                type="submit"
                onClick={loginSubmit}
                //  disabled={isDisabled()}
              >
                {t.authPages.sign_in}
              </button>
            </div>
          </StyledForm>
          <div className="mobile-footer mobile-only">
            <p>{t.authPages.dontHaveAccount}</p>
            <Link to="/sign-up">{t.authPages.sign_up}</Link>
          </div>
        </div>
      </StyledAuthPage>
    </StyledPage>
  );
};
const StyledPage = styled.div`
  .container {
    form {     
      .fields{
        gap : ${desktopSizes._8px};
      }
      .forget-password {
        margin-top: 1.778vh;
        display: flex;
        justify-content: end;
        a {
          font-family :  ${props => (props.language == "en" ? "Inter" : "Almarai")};
          font-weight:  ${props => (props.language == "en" ? "600" : "700")};
          font-size: 0.972vw;
          line-height: ${desktopSizes._21px};
          color: #154cd6;
          // text-align: right;
          @media(min-width:1440px){
            font-size: 14px;
            line-height: 21px;
          }
        }
        @media (min-width: 769px) and (max-width: 1024px) {
          margin-top: 1.111vw;
        }
      }
    }
    .line {
      display: flex;
      align-items: center;
      gap: 1.111vw;
      margin: 1.667vw 0;
      div {
        width: 12.847vw;
        border: 0.069vw solid #ececf2;
      }
      p {
        font-family :  ${props => (props.language == "en" ? "Inter" : "Almarai")};
        font-weight:  ${props => (props.language == "en" ? "400" : "500")};
        font-size: 0.972vw;
        line-height: ${desktopSizes._21px};
        color: #9a9ea7;

        @media(min-width:1440px){
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .oauth {
      display: flex;
      flex-direction: column;
      gap: 0.417vw;

      button {
        display: flex;
        align-items: center;
        justify-content : start;
        gap: 7.5vw;
        padding : 1.778vh 1.25vw;
        background: #ffffff;
        border: 0.069vw solid #eeeeee;
        border-radius: 0.694vw;
        width: 29.167vw;
        // height: 3.75vw;
        cursor pointer;
        @media (min-width: 769px) and (max-width: 1024px) {
          padding : 1.111vw 1.25vw;
        }
        svg {
          width: 1.111vw;
          height: 1.111vw;
          fill: #9a9ea7;
          path {
            fill: #9a9ea7;
          }
          @media(min-width:1440px){
            width: 16px;
            height: 16px;
          }
        }
        p {
          font-family :  ${props => (props.language == "en" ? "Inter" : "Almarai")};
          font-weight:  ${props => (props.language == "en" ? "600" : "700")};
          font-size: .972vw;
          line-height: ${desktopSizes._21px};
          color: #9a9ea7;

          @media(min-width:1440px){
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
     
    }
  }

  @media(max-width : 768px){
    .container {
      form {  
        .fields{
          gap : ${mobileSizes._24px};
        }   
        .forget-password {
          margin-top: ${mobileSizes._8px};
          a {
            font-family :  ${props => (props.language == "en" ? "Inter" : "Almarai")};
            font-weight:  ${props => (props.language == "en" ? "400" : "500")};
            font-size: ${mobileSizes._12px};
            line-height: ${mobileSizes._18px};
          }
        }
      }
      .line {
        display: flex;
        align-items: center;
        gap: ${mobileSizes._4px};
        margin: ${mobileSizes._24px} 0;
        div {
          width: 42vw;
          border: ${mobileSizes._1px} solid #ececf2;
        }
        p {
          font-family :  ${props => (props.language == "en" ? "Inter" : "Almarai")};
          font-weight:  ${props => (props.language == "en" ? "400" : "500")};
          font-size: ${mobileSizes._14px};
          line-height:${mobileSizes._21px};
        }
      }
      .oauth {
        display: flex;
        flex-direction: column;
        gap: ${mobileSizes._6px};
  
        button {
          gap: 18.933vw;
          padding : ${mobileSizes._16px} ${mobileSizes._18px};
          border: ${mobileSizes._1px} solid #eeeeee;
          border-radius: ${mobileSizes._10px};
          width: 91.467vw;
          svg {
            width: ${mobileSizes._16px};
            height:  ${mobileSizes._16px};
            fill: #9a9ea7;
            path {
              fill: #9a9ea7;
            }
         
          }
          p {
            font-family :  ${props => (props.language == "en" ? "Poppins" : "Almarai")};
            font-weight: 500;
            font-size: ${mobileSizes._14px};
            line-height: ${mobileSizes._21px};
          }
        }
      }
    }
  }
`;
const LangContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
export default Login;
