import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { desktopSizes, mobileSizes } from "../../util/sizes";


const formatDate = (isoDate) => {
  const date = new Date(isoDate);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  return date.toLocaleDateString('en-US', options);
};

const ItemCard = ({ item, ...props }) => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledCard language={language} {...props}>
      <img className="item-img" src={item.image} alt="item-img" />
      <div className={`item-title ${props.page == "carReviews" ? "title-reviews-styles" : "title-styles"}`}>
        {language == "en" ? item.title : item.title_ar}
      </div>
      <div className="item-text">{language == "en" ? item.text : item.text_ar}</div>
    </StyledCard>
  );
};

export const BlogCard = ({ withDate, item, ...props }) => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledCard language={language} {...props}>
      {item.main_image && <img className="item-img" src={item.main_image} alt="main" />}
      {item.video && <video className="item-img" src={item.video} alt="main-video"></video>}
      {withDate && (
        <div className="date-info">
          <h5 className="date-data blogger">
            {language === "en" ? item.blogger.name : item.blogger.name_ar || item.blogger.name}
          </h5>
          <h5 className="date-data">{formatDate(item.updated_at)}</h5>
        </div>
      )}
      <div className={`item-title ${props.page === "carReviews" ? "title-reviews-styles" : "title-styles"}`}>
        {language === "en" ? item.title : item.title}
      </div>
      {/* <div className="item-text">
        {language === "en" ? item.content : item.content}
      </div> */}
    </StyledCard>
  );
};

const StyledCard = styled.div`
  direction: ${props => (props.language === "en" ? "ltr" : "rtl")};
  width: calc(425 / 1440 * 100vw);
  /* height: calc(260 / 1440 * 100vw); */
  .item-img {
    width: calc(330 / 1440 * 100vw);
    height: calc(200 / 1440 * 100vw);
    margin-bottom: calc(16 / 1440 * 100vw);
    object-fit: cover;
    border-radius: ${desktopSizes._8px};
  }
  .date-info {
    padding-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
  }
  .date-info .date-data {
    color: #3a3a3a;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
  }
  .date-info .date-data.blogger {
    color: #0a47c4;
  }
  .item-title {
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: 600;
    font-size: 1.111vw;
    line-height: 1.667vw;
    width: 25vw;
    height: 1.667vw;
    color: #0d0a19;
    text-overflow: ellipsis;
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden;
    margin-bottom: 0.278vw;

    @media (min-width: 1440px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .item-text {
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: ${props => (props.language == "en" ? "400" : "300")};
    font-size: 0.972vw;
    line-height: 1.458vw;
    color: #5a5a5a;
    height: 5.833vw;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;

    @media (min-width: 1440px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  @media (max-width: 768px) {
    width: calc(320 / 375 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props => (props.page === "carReviews" ? "none" : "#ffffff")};
    .item-img {
      width: calc(335 / 375 * 100vw);
      height: calc(200 / 375 * 100vw);
      margin-bottom: ${mobileSizes._16px};
      border-radius: 2.133vw;
    }
    .item-title {
      font-size: 4.267vw;
      line-height: 6.4vw;
      width: ${props => (props.page == "carReviews" ? "91.467vw" : "54.933vw")};
      height: ${props => (props.page == "carReviews" ? mobileSizes._24px : "12.8vw")};
      margin-bottom: ${mobileSizes._8px};
    }
    .title-styles {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;
      margin-bottom: ${mobileSizes._4px};
    }
    .title-reviews-styles {
      margin-bottom: ${mobileSizes._4px};
    }
    .item-text {
      font-size: 3.733vw;
      line-height: ${mobileSizes._21px};
      width: ${props => (props.page == "carReviews" ? "91.467vw" : "54.933vw")};
      height: ${props => (props.page == "carReviews" ? "22.4vw" : "39.2vw")};
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${props => (props.page == "carReviews" ? "4" : "7")};
      white-space: pre-wrap;
    }
  }
`;

export default ItemCard;
