import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import styled from "styled-components";
import useTranslation from "../../hooks/useTranslation";

const countryList = [
  {
    label: "Saudi Arabia",
    dialCode: "+966",
    value: "SA",
  },
  {
    label: "Egypt",
    dialCode: "+20",
    value: "EG",
  },
];

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${props =>
    props.$isDialCode &&
    `
    width: calc((130 / 1440) * 100vw);
  `}
  ${props =>
    props.$isPhone &&
    `
    flex: 1;
  `}

  @media (max-width: 768px) {
    ${props =>
      props.$isDialCode &&
      `
      width: calc((130 / 375) * 100vw);
    `}
  }
`;

const Label = styled.label`
  font-size: calc(14 / 1440 * 100vw);
  color: #333;
  margin-bottom: calc(6 / 1440 * 100vw);
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: calc((14 / 375) * 100vw);
    margin-bottom: calc((6 / 375) * 100vw);
  }
`;

const SelectWrapper = styled.div`
  position: relative;
`;

const SelectTrigger = styled.button`
  width: 100%;
  padding: calc((13 / 1440) * 100vw) calc((14 / 1440) * 100vw);
  border: 1px solid ${props => (props.$invalid ? "#ef4444" : "#e2e8f0")};
  border-start-start-radius: calc((8 / 1440) * 100vw);
  border-end-start-radius: calc((8 / 1440) * 100vw);
  font-size: calc(14 / 1440 * 100vw);
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  @media (max-width: 768px) {
    padding: calc((13 / 375) * 100vw) calc((14 / 375) * 100vw);
    font-size: calc((14 / 375) * 100vw);
    border-start-start-radius: calc((8 / 375) * 100vw);
    border-end-start-radius: calc((8 / 375) * 100vw);
  }
`;

const SelectContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: calc((4 / 1440) * 100vw);
  margin-top: calc((4 / 1440) * 100vw);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  z-index: 1000;
  display: ${props => (props.$isOpen ? "block" : "none")};
  font-size: calc(14 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc((14 / 375) * 100vw);
  }
`;

const SelectList = styled.div`
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
`;

const SelectItem = styled.div`
  width: 100%;
  padding: calc((10 / 1440) * 100vw) calc((14 / 1440) * 100vw);
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    background: #f8fafc;
  }

  @media (max-width: 768px) {
    font-size: calc((14 / 375) * 100vw);
    padding: calc((4 / 375) * 100vw) calc((6 / 375) * 100vw);
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: calc((13 / 1440) * 100vw) calc((14 / 1440) * 100vw);
  border: 1px solid ${props => (props.$invalid ? "#ef4444" : "#e2e8f0")};
  border-end-end-radius: calc((8 / 1440) * 100vw);
  border-start-end-radius: calc((8 / 1440) * 100vw);
  font-size: calc(14 / 1440 * 100vw);

  &:disabled {
    background: #f1f5f9;
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    font-size: calc((14 / 375) * 100vw);
    padding: calc((13 / 375) * 100vw) calc((14 / 375) * 100vw);
    border-end-end-radius: calc((8 / 375) * 100vw);
    border-start-end-radius: calc((8 / 375) * 100vw);
  }
`;

const ErrorMessage = styled.span`
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;

  @media (max-width: 768px) {
    font-size: calc((12 / 375) * 100vw);
  }
`;

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PhoneInputField = React.memo(({ onChange, value, errMessage, disabled }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const selectRef = useRef(null);

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      // Add escape key handler
      document.addEventListener("keydown", e => {
        if (e.key === "Escape") {
          setIsOpen(false);
        }
      });
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleClickOutside);
    };
  }, [isOpen]);

  const [dialCode, number] = useMemo(() => {
    if (!value) return [countryList.find(country => country.value === "SA")?.dialCode, ""];
    for (const country of countryList) {
      if (value.startsWith(country.dialCode)) {
        return [country.dialCode, value.slice(country.dialCode.length)];
      }
    }
    return [countryList.find(country => country.value === "SA")?.dialCode, value];
  }, [value]);

  const handleDialCodeChange = useCallback(
    newDialCode => {
      onChange(`${newDialCode}${number}`);
      setIsOpen(false);
    },
    [onChange, number]
  );

  const handleNumberChange = useCallback(
    e => {
      const newNumber = e.target.value.replace(/^0+/, "");
      onChange(`${dialCode}${newNumber}`);
    },
    [onChange, dialCode]
  );

  const selectedCountry = useMemo(() => countryList.find(option => option.dialCode === dialCode), [dialCode]);

  return (
    <Container>
      <InputContainer>
        <FormGroup $isDialCode>
          <Label>{t.phoneNumber}</Label>
          <SelectWrapper ref={selectRef}>
            {" "}
            {/* Added ref here */}
            <SelectTrigger
              type="button"
              disabled={disabled}
              $invalid={!!errMessage}
              onClick={() => !disabled && setIsOpen(!isOpen)}
              aria-expanded={isOpen}
              aria-haspopup="listbox"
            >
              <FlagContainer>
                <ReactCountryFlag countryCode={selectedCountry?.value} svg />
                {dialCode || "Dial Code"}
              </FlagContainer>
            </SelectTrigger>
            <SelectContent $isOpen={isOpen} role="listbox" aria-label="Select country code">
              <SelectList>
                {countryList.map(option => (
                  <SelectItem
                    key={option.value}
                    onClick={() => handleDialCodeChange(option.dialCode)}
                    role="option"
                    aria-selected={option.dialCode === dialCode}
                  >
                    <ReactCountryFlag countryCode={option.value} svg style={{ marginRight: "8px" }} />
                    <span>{`${option.value} (${option.dialCode})`}</span>
                  </SelectItem>
                ))}
              </SelectList>
            </SelectContent>
          </SelectWrapper>
        </FormGroup>
        <FormGroup $isPhone>
          <Label>&nbsp;</Label>
          <StyledInput
            disabled={disabled}
            id="mobile"
            type="text"
            placeholder={t.enterPhoneNumber}
            $invalid={!!errMessage}
            value={number}
            onChange={handleNumberChange}
          />
        </FormGroup>
      </InputContainer>
      {errMessage && <ErrorMessage>{errMessage}</ErrorMessage>}
    </Container>
  );
});

PhoneInputField.displayName = "PhoneInputField";

export default PhoneInputField;
