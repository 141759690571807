import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Close } from "../../images/black-close.svg";

import logo from "../../images/logo.svg";

import Modal from "../assets/Modal";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import StyledModal from "../Car/StyledModal";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const BookSuccess = ({ detail }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <Modal urlKey={"book-success"}>
      <StyledModal>
        <StyledBookModal language={language}>
          <div className="header">
            <button
              className="close-btn"
              onClick={() => {
                navigate(location.pathname);
              }}
            >
              <Close />
            </button>
            <Link to="/">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </div>
          <div className="title-text">
            <p className="title">{t.carPage.quotationModal.title}</p>
            <p className="text">{t.carPage.quotationModal.text}</p>
          </div>

          <button
            className="confirm-btn"
            onClick={() => {
              navigate(location.pathname);
            }}
          >
            {t.carPage.quotationModal.Okay}
          </button>
        </StyledBookModal>
      </StyledModal>
    </Modal>
  );
};

const StyledBookModal = styled.div`
  .title-text {
    .text {
      width: auto;
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "400" : "300")};
      font-size: ${desktopSizes._14px};
      line-height: ${desktopSizes._21px};
      color: #0d0a19;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .confirm-btn {
    cursor: pointer;
    background: #154cd6;
    border-radius: ${desktopSizes._10px};
    width: 29.167vw;
    height: 3.75vw;
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: ${props => (props.language == "en" ? "600" : "700")};
    font-size: ${desktopSizes._16px};
    line-height: ${desktopSizes._21px};
    margin-bottom: ${desktopSizes._16px};
    color: #ffffff;
    @media (min-width: 1440px) {
      font-size: 16px;
      line-height: 21px;
    }
  }
  @media (max-width: 768px) {
    .title-text {
      .text {
        font-size: ${mobileSizes._14px};
        line-height: ${mobileSizes._21px};
        color: #666;
        width: 82.933vw;
      }
    }
    .confirm-btn {
      border-radius: ${mobileSizes._4px};
      width: 82.933vw;
      height: 14.4vw;
      font-size: ${mobileSizes._16px};
      line-height: ${mobileSizes._21px};
      margin-bottom: ${mobileSizes._16px};
    }
  }
`;

export default BookSuccess;
