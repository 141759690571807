import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useApi from "../../hooks/useApi";
import { ArrowIcon2, LocationIcon, SellerWalletIcon, TrustedIcon } from "../assets/Icons";
import useTranslation from "../../hooks/useTranslation";
import { Link } from "react-router-dom";
import NavButton from "../assets/NavButton";

const SellersListing = () => {
  const { get, loading, data } = useApi();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t, language } = useTranslation();

  useEffect(() => {
    const fetchSellers = async () => {
      const response = await get(`/seller?page=${currentPage}&page_size=4`);
      if (response?.count) {
        setTotalPages(Math.ceil(response.count / 4));
      }
    };
    fetchSellers();
  }, [get, currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  return (
    <Container language={language}>
      <Header>
        <TitleContainer language={language}>
          <Title>{t.seller.carSellers}</Title>
          {/* <SeeAllLink href="/sellers">{t.seller.seeAllSellers}</SeeAllLink> */}
          {/* <ArrowIcon2 /> */}
        </TitleContainer>
        <HeaderRight>
          <PaginationControls>
            <NavButton position="start" onClick={handlePreviousPage} disabled={currentPage === 1} language={language}>
              <ArrowIcon2 />
            </NavButton>
            <NavButton
              position="end"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              language={language}
            >
              <ArrowIcon2 />
            </NavButton>
          </PaginationControls>
        </HeaderRight>
      </Header>

      {loading ? (
        <LoadingWrapper>Loading sellers...</LoadingWrapper>
      ) : (
        <Grid>
          {data?.results?.map(seller => (
            <SellerCard key={seller.id}>
              <TrustedBadge>
                <TrustedIcon />
                {t.seller.trustedSeller}
              </TrustedBadge>

              <LogoContainer>
                <SellerLogo src={seller.logo} alt={seller.name} />
              </LogoContainer>

              <SellerInfo>
                <SmallLogo src={seller.logo} alt={seller.name} />
                <div>
                  <SellerName>{language === "en" ? seller.name : seller.name_ar}</SellerName>
                  <SellerType>{t.seller.seller}</SellerType>
                </div>
              </SellerInfo>

              <SellerDetails>
                <DetailItem>
                  <LocationIcon />
                  <span>{t.seller.allCities}</span>
                </DetailItem>
                <DetailItem>
                  <SellerWalletIcon />
                  <span>{t.seller.specialFinancingAvailable}</span>
                </DetailItem>
              </SellerDetails>

              <ViewDetailsLink to={`/seller/${seller.id}`} language={language}>
                {t.seller.sellerDetails}
                <ArrowIcon2 />
              </ViewDetailsLink>
            </SellerCard>
          ))}
        </Grid>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: calc(1248 / 1440 * 100vw);
  direction: ${props => (props.language === "en" ? "ltr" : "rtl")};
  * {
    font-family: ${props => (props.language === "en" ? "Poppins" : "Almarai")};
  }
  margin: 0 auto;
  padding-top: calc(50 / 1440 * 100vw);

  @media (max-width: 768px) {
    margin-top: calc(24 / 375 * 100vw);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: calc(10 / 1440 * 100vw);

  svg {
    rotate: ${props => (props.language === "ar" ? "180deg" : "0deg")};
  }

  @media (max-width: 768px) {
    gap: calc(10 / 375 * 100vw);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(24 / 1440 * 100vw);

  @media (max-width: 768px) {
    margin-bottom: calc(24 / 375 * 100vw);
  }
`;

const Title = styled.h2`
  font-size: calc(24 / 1440 * 100vw);
  font-weight: 600;
  margin: 0;

  @media (max-width: 768px) {
    font-size: calc(24 / 375 * 100vw);
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: calc(24 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(24 / 375 * 100vw);
  }
`;

const PaginationControls = styled.div`
  display: flex;
  gap: calc(8 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(8 / 375 * 100vw);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: calc(24 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(10 / 375 * 100vw);
    overflow-x: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
`;

const SellerCard = styled.div`
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: calc(12 / 1440 * 100vw);
  padding: calc(16 / 1440 * 100vw);
  position: relative;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    padding: calc(16 / 375 * 100vw);
    border-radius: calc(8 / 375 * 100vw);
    margin-bottom: calc(10 / 375 * 100vw);
    width: calc(294 / 375 * 100vw);
  }
`;

const TrustedBadge = styled.div`
  /* position: absolute; */
  top: calc(16 / 1440 * 100vw);
  left: calc(16 / 1440 * 100vw);
  color: #34c759;
  padding: calc(4 / 1440 * 100vw) calc(8 / 1440 * 100vw);
  border-radius: calc(6 / 1440 * 100vw);
  font-size: calc(12 / 1440 * 100vw);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: calc(4 / 1440 * 100vw);
  border: 1px solid #34c759;
  margin-bottom: calc(16 / 1440 * 100vw);
  width: fit-content;
  svg {
    width: calc(16 / 1440 * 100vw);
    height: calc(16 / 1440 * 100vw);
  }

  @media (max-width: 768px) {
    margin-bottom: calc(16 / 375 * 100vw);
    font-size: calc(12 / 375 * 100vw);
    padding: calc(4 / 375 * 100vw) calc(8 / 375 * 100vw);
    border-radius: calc(6 / 375 * 100vw);
    svg {
      width: calc(16 / 375 * 100vw);
      height: calc(16 / 375 * 100vw);
    }
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    margin-bottom: calc(16 / 375 * 100vw);
  }
`;

const SellerLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const SellerInfo = styled.div`
  text-align: start;
  margin-bottom: calc(16 / 1440 * 100vw);
  display: flex;
  align-items: center;
  gap: calc(8 / 1440 * 100vw);

  @media (max-width: 768px) {
    margin-bottom: calc(16 / 375 * 100vw);
  }
`;

const SmallLogo = styled.img`
  width: calc(32 / 1440 * 100vw);
  height: calc(32 / 1440 * 100vw);
  border-radius: calc(8 / 1440 * 100vw);
  border: 1px solid #e5e7eb;
  padding: calc(4 / 1440 * 100vw);
  object-fit: cover;

  @media (max-width: 768px) {
    width: calc(32 / 375 * 100vw);
    height: calc(32 / 375 * 100vw);
    border-radius: calc(8 / 375 * 100vw);
  }
`;

const SellerName = styled.h3`
  font-size: calc(16 / 1440 * 100vw);
  font-weight: 600;
  margin: 0 0 calc(4 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(16 / 375 * 100vw);
    margin: 0 0 calc(4 / 375 * 100vw);
  }
`;

const SellerType = styled.div`
  color: #6b7280;
  font-size: calc(14 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(14 / 375 * 100vw);
  }
`;

const SellerDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(8 / 1440 * 100vw);
  padding: calc(16 / 1440 * 100vw) 0;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(16 / 375 * 100vw) 0;
    gap: calc(8 / 375 * 100vw);
  }
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: calc(8 / 1440 * 100vw);
  color: #374151;
  font-size: calc(14 / 1440 * 100vw);
  font-weight: 500;
  svg {
    width: calc(16 / 1440 * 100vw);
    height: calc(16 / 1440 * 100vw);
  }

  @media (max-width: 768px) {
    font-size: calc(14 / 375 * 100vw);
    svg {
      width: calc(16 / 375 * 100vw);
      height: calc(16 / 375 * 100vw);
    }
  }
`;

const ViewDetailsLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(4 / 1440 * 100vw);
  color: #2563eb;
  text-decoration: none;
  font-weight: 500;
  font-size: calc(14 / 1440 * 100vw);

  &:hover {
    text-decoration: underline;
  }
  svg {
    rotate: ${props => (props.language === "en" ? "0deg" : "180deg")};
    width: calc(16 / 1440 * 100vw);
    height: calc(16 / 1440 * 100vw);
  }

  @media (max-width: 768px) {
    font-size: calc(14 / 375 * 100vw);
    gap: calc(4 / 375 * 100vw);
    margin-top: calc(16 / 375 * 100vw);
    svg {
      width: calc(16 / 375 * 100vw);
      height: calc(16 / 375 * 100vw);
    }
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(200 / 1440 * 100vw);
  color: #6b7280;

  @media (max-width: 768px) {
    height: calc(200 / 375 * 100vw);
  }
`;

export default SellersListing;
