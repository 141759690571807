import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SelectMenu from "../assets/SelectMenu";
import { desktopSizes, mobileSizes } from "../../util/sizes";

import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const CarCustomization = ({
  colors,
  materials,
  materialColors,
  selectedColor,
  selectedMaterial,
  setSelectedColor,
  selectedMaterialColor,
  setSelectedMaterialColor,
  setSelectedMaterial,
  ...props
}) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const color_field = {
    name: "car_color",
    label: t.carPage.availability.body_color,
    displayName: t.carPage.availability.body_color,
    type: "select",
    options: colors,
    isColor: true,
  };

  const material_field = {
    name: "interior_fabric",
    label: t.carPage.availability.interior_material,
    displayName: t.carPage.availability.interior_material,
    type: "select",
    options: materials,
  };

  const materialColor_field = {
    name: "interior_color",
    label: t.carPage.availability.interior_color,
    displayName: t.carPage.availability.interior_color,
    type: "select",
    options: materialColors,
    isColor: true,
  };

  return (
    <StyledCard language={language}>
      <div className="card-header">
        {t.carPage.availability.car_customization}
      </div>
      <div className="inputs">
        <div className="input-div color-input">
          <p>{t.carPage.availability.body_color}</p>
          <div
            className="value-color-circle"
            style={{
              background: selectedColor,
              border:
                selectedColor.toLowerCase() == "white"
                  ? "1px solid #E4E6E8"
                  : 0,
            }}
          ></div>
          <SelectMenu
            inputWidth="20.903vw"
            field={color_field}
            value={selectedColor}
            disabled={false}
            setValue={(value) => {
              setSelectedColor(value);
            }}
          />
        </div>
        <div className="input-div">
          <p>{t.carPage.availability.interior_material}</p>
          <SelectMenu
            inputWidth="20.903vw"
            field={material_field}
            value={selectedMaterial}
            disabled={false}
            setValue={(value) => {
              setSelectedMaterial(value);
            }}
          />
        </div>
        <div className="input-div color-input">
          <p>{t.carPage.availability.interior_color}</p>
          <div
            className="value-color-circle"
            style={{
              background: selectedMaterialColor,
              border:
                selectedColor.toLowerCase() == "white"
                  ? "1px solid #E4E6E8"
                  : 0,
            }}
          ></div>
          <SelectMenu
            inputWidth="20.903vw"
            field={materialColor_field}
            disabled={false}
            value={selectedMaterialColor}
            setValue={(value) => {
              setSelectedMaterialColor(value);
            }}
          />
        </div>
      </div>
    </StyledCard>
  );
};
const StyledCard = styled.div`
  width: calc(360 / 1440 * 100vw);
  padding: ${desktopSizes._32px} ${desktopSizes._16px};
  background: #ffffff;
  border: ${desktopSizes._1px} solid #e4e6e8;
  border-radius: ${desktopSizes._4px};
  .card-header {
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: ${props => (props.language == "en" ? "600" : "700")};
    font-size: ${desktopSizes._20px};
    line-height: ${desktopSizes._32px};
    color: #0d0a19;
    margin-bottom: ${desktopSizes._24px};

    @media (min-width: 1440px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: ${desktopSizes._24px};

    .input-div {
      width: 22.778vw;
      position: relative;
      .value-color-circle {
        position: absolute;
        z-index: 1000;
        top: 3.056vw;
        left: ${props => props.language == "en" && desktopSizes._16px};
        right: ${props => props.language == "ar" && desktopSizes._16px};
        width: 1.319vw;
        height: 1.319vw;
        border-radius: 50%;

        @media (min-width: 1440px) {
          width: 20px;
          height: 20px;
          top: 44px;
        }

        @media (max-width: 768px) {
          width: ${mobileSizes._16px};
          height: ${mobileSizes._16px};
        }
      }
      p {
        font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
        font-style: normal;
        font-weight: 500;
        font-size: ${desktopSizes._14px};
        line-height: ${desktopSizes._18px};
        color: #0d0a19;
        margin-bottom: ${desktopSizes._8px};

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 8px;
        }
      }

      input {
        width: 22.778vw;
        height: auto;
        padding: ${props =>
          props.language == "en"
            ? ` ${desktopSizes._16px} ${desktopSizes._28px}
          ${desktopSizes._16px} ${desktopSizes._16px}`
            : `${desktopSizes._16px}  ${desktopSizes._16px}
            ${desktopSizes._16px} ${desktopSizes._28px}`};
        // height: 4.306vw;
        background-size: 0.694vw 0.694vw;
        background-position: ${props => (props.language == "en" ? " 91% 50%" : "9% 50%")};
        font-family: "Inter";
        font-style: normal;
        font-size: 1.111vw;
        line-height: 1.319vw;
        font-weight: 400;
        outline: none;
        background-color: #ffffff;
        border: ${desktopSizes._1px} solid #e4e6e8;
        border-radius: ${desktopSizes._8px};
        // background-repeat: no-repeat;

        @media (min-width: 1440px) {
          font-size: 16px;
          line-height: 20px;
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
      input::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.319vw;
        color: #9a9ea7;
        opacity: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (min-width: 1440px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .color-input {
      input {
        padding-left: ${props => props.language == "en" && desktopSizes._48px};
        padding-right: ${props => props.language == "ar" && desktopSizes._48px};
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    border: 0;
    background: none;
    margin-bottom: ${mobileSizes._24px};
    .card-header {
      font-weight: 600;
      font-size: ${mobileSizes._18px};
      line-height: ${mobileSizes._27px};
      margin-bottom: ${mobileSizes._16px};
    }
    .inputs {
      display: flex;
      flex-direction: column;
      gap: ${mobileSizes._16px};

      .input-div {
        width: 100%;
        position: relative;
        .value-color-circle {
          position: absolute;
          z-index: 1000;
          top: 12vw;
          left: ${props => props.language == "en" && mobileSizes._16px};
          right: ${props => props.language == "ar" && mobileSizes._16px};
          width: ${mobileSizes._19px};
          height: ${mobileSizes._19px};
          border-radius: 50%;
        }
        p {
          font-family: Poppins;
          font-weight: 500;
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
          margin-bottom: ${mobileSizes._8px};
        }

        input {
          width: 100%;
          height: auto;
          padding: ${props =>
            props.language == "en"
              ? `${mobileSizes._15px} ${mobileSizes._40px}
          ${mobileSizes._15px} ${mobileSizes._16px}`
              : `${mobileSizes._15px} ${mobileSizes._16px}
          ${mobileSizes._15px} ${mobileSizes._40px}`};
          background-size: ${mobileSizes._10px} ${mobileSizes._10px};
          font-size: ${mobileSizes._16px};
          line-height: ${mobileSizes._19px};
          background-color: #ffffff;
          border: ${mobileSizes._1px} solid #e4e6e8;
          border-radius: ${mobileSizes._8px};
        }
        input::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: ${mobileSizes._16px};
          line-height: ${mobileSizes._19px};
          color: #9a9ea7;
          opacity: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .select-input-div input {
        background-position: ${props => (props.language == "en" ? "95% 50%" : "5% 50%")};
      }
      .color-input {
        input {
          padding-left: ${props => props.language == "en" && "  11.733vw"};
          padding-right: ${props => props.language == "ar" && "  11.733vw"};
        }
      }
    }
  }
`;

export default CarCustomization;
