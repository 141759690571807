import React from "react";
import { LanguageIcon } from "./Icons";
import { changeLanguage } from "../../redux/slices/LanguageSlice";
import { useDispatch, useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import styled from "styled-components";

function LanguageButton() {
  const language = useSelector(state => state.language);
  const dispatch = useDispatch();
  const t = language === "en" ? en : ar;

  return (
    <LangButton
      language={language}
      className=""
      onClick={() => {
        dispatch(changeLanguage());
      }}
    >
      <LanguageIcon />
      <p>{t.header.lang}</p>
    </LangButton>
  );
}

export default LanguageButton;

const LangButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  background-color: transparent;
  cursor: pointer;
  p {
    font-family: ${props => (props.language === "en" ? "Inter" : "Almarai")} !important;
    margin: 0;
  }
`;
