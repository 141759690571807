import React from "react";
import styled from "styled-components";
import { bannerImage } from "../../pages/Account/Account";

import { ReactComponent as BackIcon } from "../../images/Account/back.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
function AccTitle({ title, description }) {
  const language = useSelector(state => state.language);
  return (
    <BannerContainer language={language}>
      <StyledImage src={bannerImage} alt="account banner" />
      <TitleCard>
        <Card>
          <BackLink language={language} to="/account">
            <BackIcon className="icon" />
          </BackLink>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </Card>
      </TitleCard>
    </BannerContainer>
  );
}

export default AccTitle;

const BannerContainer = styled.div`
  position: relative;
  color: white;
  font-family: ${props => (props.language === "en" ? "Inter" : "Almarai")} !important;
  & * {
    font-family: inherit;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
`;

const TitleCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  top: 0;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  padding: 1.5rem;
  border-radius: 10px;
  justify-content: start;
  align-items: center;

  div {
    line-height: 1.6;
    p {
      font-size: 0.8rem;
    }
  }
`;

const BackLink = styled(Link)`
  background-color: white;
  padding: 1rem 1.3rem;
  border-radius: 10px;
  &:hover {
    background-color: #edecec;
    transition: all 0.5s ease;
  }
  svg {
    transform: ${props => (props.language === "ar" ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;
