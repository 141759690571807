import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Brands from "../Components/Home/Brands";
import BuildCar from "../Components/Home/BuildCar";
import CarShapes from "../Components/Home/CarShapes";
import Intro from "../Components/Home/Intro";
import LoanCalculator from "../Components/Home/LoanCalculator";
import News from "../Components/Home/News";
import Reviews from "../Components/Home/Reviews";
import Sellers from "../Components/Home/Sellers";
import SpecialCars from "../Components/Home/SpecialCars";
import shape from "../images/shape2.png";
import { axiosCarvenui } from "../util/integration";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import SellersListing from "../Components/Home/SellerSection";
import Banks from "../Components/Home/Banks";

const Home = () => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const ids = [1];
  const [similarsCars, setSimilarsCars] = useState([]);
  const [BrandsLoading, setBrandsLoading] = useState(false);
  const [carsLoading, setCarsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [nextBrands, setNextBrands] = useState("");

  useEffect(() => {
    const getBrands = async () => {
      try {
        setBrandsLoading(true);
        const res = await axiosCarvenui.get(`/brand?page_size=50`);
        const data = res.data.results.map(brand => {
          return {
            ...brand,
            value: brand.id,
          };
        });
        setBrands(data);
        setBrandsLoading(false);
        setNextBrands(res.data.next);
        // setFormData({ brand: data[0].id });
      } catch (error) {
        console.log(error);
      }
    };
    getBrands();
  }, []);

  useEffect(() => {
    const getSpecialCars = async () => {
      try {
        setCarsLoading(true);
        let res = await axiosCarvenui.get(`/car_detail/details_by_car/?car_in=${ids.join(",")}`);
        console.log("res similar cars", res);
        setSimilarsCars(res.data.splice(0, 4));
        setCarsLoading(false);
      } catch (e) {}
    };
    getSpecialCars();
  }, []);
  return (
    <StyledPage language={language}>
      <Intro initialBrands={brands} initialLoading={BrandsLoading} initialNextBrands={nextBrands} />
      <div className="brands-section">
        <Brands initialBrands={brands} initialLoading={BrandsLoading} initialNextBrands={nextBrands} />
      </div>
      <CarShapes />
      {/* <BuildCar /> */}
      <LoanCalculator initialBrands={brands} initialLoading={BrandsLoading} initialNextBrands={nextBrands} />

      <div className="cars-section">
        <div className="special-cars">
          {!carsLoading && (
            <SpecialCars
              title={language == "en" ? "Best Selling Cars" : "أفضل بيع السيارات"}
              homePage={true}
              cars={similarsCars}
            />
          )}
        </div>
      </div>
      <Banks />
      <SellersListing />
      <News />
    </StyledPage>
  );
};
const StyledPage = styled.div`
  background: #fbfbfb;
  .section-header {
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: ${props => (props.language == "en" ? "600" : "700")};
    font-size: 1.667vw;
    line-height: 2.5vw;
    color: #0d0a19;
    * {
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")} !important;
    }

    @media (min-width: 1440px) {
      font-size: 24px;
      line-height: 36px;
    }
    @media (max-width: 768px) {
      font-size: calc(16 / 375 * 100vw);
      line-height: 7.2vw;
    }
  }
  .text-info {
    margin-top: 2.222vw;
    margin-bottom: 1.667vw;
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: ${props => (props.language == "en" ? "400" : "300")};
    font-size: 1.111vw;
    line-height: 2.361vw;
    color: #5a5a5a;

    @media (min-width: 1440px) {
      font-size: 16px;
      line-height: 34px;
    }

    @media (max-width: 768px) {
      margin-top: 2.133vw;
      margin-bottom: 4.267vw;
      font-size: 4.267vw;
      line-height: 6.4vw;
    }
  }
  .section-btn {
    width: 22.778vw;
    height: 3.194vw;
    background: #154cd6;
    border-radius: 0.278vw;
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: ${props => (props.language == "en" ? "600" : "700")};
    font-size: 1.111vw;
    line-height: 1.458vw;
    color: #ffffff;
    cursor: pointer;

    @media (min-width: 1440px) {
      font-size: 16px;
      line-height: 21px;
    }

    @media (max-width: 768px) {
      font-family: ${props => (props.language === "en" ? "Inter" : "Almarai")};
      font-size: 4.267vw;
      line-height: 5.067vw;
      width: 91.467vw;
      height: 13.6vw;
      border-radius: 2.133vw;
    }
  }
  .brands-section {
    padding: 0 calc(99 / 1440 * 100vw) 10vw;
    margin-top: calc(50 / 1440 * 100vw);

    .home-brands-section {
      position: absolute;
      top: calc((579 / 1440) * 100vw);
    }
    @media (max-width: 768px) {
      padding: 0;
      margin-top: calc(154 / 375 * 100vw);
    }
  }
  .cars-section {
    background: url(${shape}) no-repeat 0 100%;
    background-position: -2vw 0;
    position: relative;
    overflow: hidden;
    margin-top: 3.889vw;

    .special-cars {
      width: calc(1248 / 1440 * 100vw);
      margin: 0 auto;
      display: block;
      z-index: 10000;
      .cars .row .seller .seller-name {
        width: 16vw;
      }
    }

    @media (max-width: 768px) {
      background: none;
      margin-top: 6.4vw;
      padding: ${props => (props.language === "en" ? "0 0 0 4.267vw" : "0 4.267vw 0 0")};

      .special-cars {
        padding: 0;
        margin: 0;
        width: 100%;
        .cars .row .seller .seller-name {
          width: 37.867vw;
        }
      }
    }

    // .shape {
    //   position: absolute;
    //   width: 42.292vw;
    //   height: 27.153vw;
    //   right: 66.528vw;
    //   top: 0;
    //   z-index: 0;
    // }
  }
`;
export default Home;
