import React from "react";
import styled from "styled-components";
import {
  ConsumptionIcon,
  CylinderIcon,
  DriveTrainIcon,
  EngineIcon,
  GasIcon,
  GearIcon,
  HorsePowerIcon,
  TransmissionIcon,
} from "../assets/Icons";
import useTranslation from "../../hooks/useTranslation";

const PerformanceFeatures = ({ car }) => {
  const { t, language } = useTranslation();

  console.log(car);

  const features = [
    { label: t.car.fuelType, value: car.fuel_type, Icon: GasIcon },
    { label: t.car.engineCapacity, value: `${car.litre_capacity} L`, Icon: EngineIcon },
    { label: t.car.cylinders, value: `${car.cylinders} Cylinders`, Icon: CylinderIcon },
    { label: t.car.transmission, value: car.transmission, Icon: TransmissionIcon },
    { label: t.car.horsepower, value: `${car.horse_power} hp`, Icon: HorsePowerIcon },
    { label: t.car.gearBox, value: car.gear_box, Icon: GearIcon },
    { label: t.car.consumption, value: car.consumption, Icon: ConsumptionIcon },
    { label: t.car.driveTrain, value: car.drive_train, Icon: DriveTrainIcon },
  ];

  return (
    <PerformanceContainer language={language}>
      <Title>{t.car.performanceFeatures}</Title>
      <FeaturesGrid>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <IconWrapper>
              <feature.Icon size={24} color="#4B5563" />
            </IconWrapper>
            <FeatureInfo>
              <FeatureLabel>{feature.label}</FeatureLabel>
              <FeatureValue>{feature.value}</FeatureValue>
            </FeatureInfo>
          </FeatureCard>
        ))}
      </FeaturesGrid>
    </PerformanceContainer>
  );
};

export default PerformanceFeatures;

const PerformanceContainer = styled.div`
  * {
    font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
  }
  margin-top: calc(32 / 1440 * 100vw);
  padding: calc(24 / 1440 * 100vw);
  background-color: #ffffff;
  border-radius: calc(12 / 1440 * 100vw);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    margin-top: calc(16 / 375 * 100vw);
    padding: calc(16 / 375 * 100vw);
  }
`;

const Title = styled.h2`
  font-size: calc(20 / 1440 * 100vw);
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: calc(32 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(16 / 375 * 100vw);
    margin-bottom: calc(24 / 375 * 100vw);
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    /* gap: calc(16 / 375 * 100vw); */
  }
`;

const FeatureCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: calc(16 / 1440 * 100vw);
  padding: calc(16 / 1440 * 100vw);
  background-color: #ffffff;
  border-radius: calc(8 / 1440 * 100vw);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 768px) {
    padding: calc(16 / 375 * 100vw);
    gap: calc(12 / 375 * 100vw);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(40 / 1440 * 100vw);
  height: calc(40 / 1440 * 100vw);
  background-color: #f5f6f7;
  border-radius: calc(8 / 1440 * 100vw);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  svg {
    width: calc(24 / 1440 * 100vw);
    height: calc(24 / 1440 * 100vw);
  }

  @media (max-width: 768px) {
    width: calc(32 / 375 * 100vw);
    height: calc(32 / 375 * 100vw);
    svg {
      width: calc(20 / 375 * 100vw);
      height: calc(20 / 375 * 100vw);
    }
  }
`;

const FeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeatureLabel = styled.span`
  font-size: calc(14 / 1440 * 100vw);
  color: #6b7280;
  margin-bottom: calc(4 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(12 / 375 * 100vw);
    margin-bottom: calc(2 / 375 * 100vw);
  }
`;

const FeatureValue = styled.span`
  font-size: calc(16 / 1440 * 100vw);
  font-weight: 600;
  color: #1a1a1a;

  @media (max-width: 768px) {
    font-size: calc(14 / 375 * 100vw);
  }
`;
