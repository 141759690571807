import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const Review = ({ review }) => {
  const [readMore, setReadMore] = useState(false);
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const textRef = useRef(null);

  useEffect(() => {
    const text = textRef.current;
    const lineHeight = parseInt(getComputedStyle(text).lineHeight);
    const maxHeight = lineHeight * 11; // maximum height in pixels
    if (text.clientHeight < maxHeight) {
      // hide "read more" button if the text is less than 11 lines
      text.nextElementSibling.style.display = "none";
    }
  }, []);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  const reviewLength_en = isMobile ? 346 : 454;
  const reviewLength_ar = isMobile ? 346 : 422;
  const review_text = language == "en" ? review.text : review.text_ar;
  const reviewLength = language == "en" ? reviewLength_en : reviewLength_ar;
  return (
    <StyledSection language={language}>
      <div className="review-title">
        {t.reviews.review}:{language == "en" ? review.title : review.title_ar}
      </div>

      {/* {readMore ? (
        <div className="review-text">
          <p>{review_text}</p>
          <button onClick={() => setReadMore(false)}>
            {t.reviews.readless}
          </button>
        </div>
      ) : review_text.length > reviewLength ? (
        <div className="review-text">
          <p>{review_text.slice(0, reviewLength)}..</p>
          <button onClick={() => setReadMore(true)}>
            {t.reviews.readMore}
          </button>
        </div>
      ) : (
        <div className="review-text">
          <p>{review_text}</p>
        </div>
      )} */}

      <div className="review-text-div">
        <p
          className={`review-text ${readMore ? "view-all" : ""}`}
          ref={textRef}
        >
          {review_text}
        </p>
        <button onClick={() => setReadMore(!readMore)}>
          {readMore ? t.reviews.readless : t.reviews.readMore}
        </button>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  width: 31.736vw;
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  .review-title {
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: ${props => (props.language == "en" ? "600" : "700")};
    font-size: 1.111vw;
    line-height: 1.667vw;
    color: #0d0a19;
    @media (min-width: 1440px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .review-text-div {
    margin-top: 0.833vw;
    .review-text {
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "400" : "300")};
      font-size: 0.972vw;
      line-height: 1.458vw;
      color: #5a5a5a;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 11; /* number of lines to show */
      -webkit-box-orient: vertical;
      white-space: pre-line;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
    .view-all {
      -webkit-line-clamp: unset;
    }
    button {
      display: inline;
      border: none;
      background: none;
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: 600;
      font-size: 0.972vw;
      line-height: 1.458vw;
      color: #154cd6;
      cursor: pointer;
      // margin-left: 0.417vw;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 91.467vw;
    .review-title {
      font-size: 4.8vw;
      line-height: 7.2vw;
    }

    .review-text-div {
      margin-top: 2.133vw;
      .review-text {
        font-size: 4.267vw;
        line-height: 6.4vw;
      }
      button {
        font-size: 4.267vw;
        line-height: 6.4vw;
        // margin-left: 1.6vw;
      }
    }
  }
`;
export default Review;
