import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BrandCard from "./BrandCard";
import { ReactComponent as Down } from "../../images/down-arrow.svg";
import { mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import useApi from "../../hooks/useApi";

const Brands = ({ sellerId }) => {
  const DESKTOP_ITEMS_PER_PAGE = 8;
  const MOBILE_ITEMS_PER_PAGE = 4;

  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [page, setPage] = useState(1);
  const [allBrands, setAllBrands] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const { get, loading } = useApi();

  const itemsPerPage = isMobile ? MOBILE_ITEMS_PER_PAGE : DESKTOP_ITEMS_PER_PAGE;

  // Fetch brands with pagination
  const fetchBrands = async pageNumber => {
    try {
      const response = await get(`/brand`, {
        page: pageNumber,
        page_size: itemsPerPage,
        seller: sellerId,
      });

      if (response?.results) {
        if (pageNumber === 1) {
          // If it's the first page, reset the brands list
          setAllBrands(response.results);
        } else {
          // Append new brands to existing list
          setAllBrands(prev => [...prev, ...response.results]);
        }

        // Check if we have more pages
        setHasMore(response.results.length === itemsPerPage);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // Initial fetch
  useEffect(() => {
    setPage(1);
    fetchBrands(1);
  }, [itemsPerPage]); // Refetch when itemsPerPage changes (mobile/desktop switch)

  // Handle resize
  useEffect(() => {
    const onResize = () => {
      const newIsMobile = checkIsMobile();
      if (newIsMobile !== isMobile) {
        setIsMobile(newIsMobile);
        // Reset pagination when screen size changes
        setPage(1);
        setAllBrands([]);
        setHasMore(true);
      }
    };

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [isMobile]);

  const handleLoadMore = () => {
    if (hasMore) {
      // Load next page
      const nextPage = page + 1;
      setPage(nextPage);
      fetchBrands(nextPage);
    } else {
      // Reset to first page
      setPage(1);
      setAllBrands([]);
      setHasMore(true);
      fetchBrands(1);

      // Scroll to top
      const element = document.getElementById("header");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  if (loading && page === 1) return null; // Only show loading on initial load

  return (
    <StyledSection className="home-brands-section" language={language} showMore={!hasMore}>
      <div className="section-header" id="header">
        {isMobile ? t.brands.mobileTitle : t.brands.title}
      </div>

      <div className="brands">
        {allBrands.map(brand => (
          <Link to="cars" state={{ brand: brand.id }} className="brand" key={brand.id}>
            <BrandCard brand={brand} />
          </Link>
        ))}
      </div>

      {allBrands.length >= itemsPerPage && (
        <div className="show-more">
          <button onClick={handleLoadMore} disabled={loading}>
            <Down />
            <p>{loading ? t.loading : !hasMore ? t.showLess : t.showMore}</p>
          </button>
        </div>
      )}
    </StyledSection>
  );
};

const StyledSection = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  /* position: absolute; */
  padding: calc(27 / 1440 * 100vw) calc(40 / 1440 * 100vw);
  background-color: #fff;
  /* top: calc((579 / 1440) * 100vw); */
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: calc(1248 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: 0.556vw;
    top: calc(620 / 375 * 100vw);
    padding: calc(20 / 375 * 100vw) calc(16 / 375 * 100vw);
    inset-inline-start: calc(20 / 375 * 100vw);
  }

  .brands {
    margin-top: 2.222vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2.222vw 1.944vw;
  }

  .show-more {
    margin-top: 2.361vw;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.556vw;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      p {
        font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
        font-style: normal;
        font-weight: 700;
        font-size: 1.389vw;
        line-height: 1.458vw;
        color: #154cd6;

        @media (min-width: 1440px) {
          font-size: 20px;
          line-height: 21px;
        }
      }
    }

    svg {
      width: 1.667vw;
      height: 1.667vw;
      transform: ${props => (props.showMore ? "scaleY(-1)" : "none")};

      @media (min-width: 1440px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: ${mobileSizes._20px} ${mobileSizes._16px};

    .brands {
      margin-top: ${mobileSizes._16px};
      grid-template-columns: 1fr 1fr;
      gap: ${mobileSizes._8px} ${mobileSizes._8px};
    }

    .show-more {
      margin-top: ${mobileSizes._16px};

      button {
        gap: 0.556vw;

        p {
          font-weight: 600;
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
        }
      }

      svg {
        width: ${mobileSizes._24px};
        height: ${mobileSizes._24px};
      }
    }
  }
`;

export default Brands;