import React, { useEffect } from "react";
import useApi from "../../hooks/useApi";
import styled from "styled-components";
import useTranslation from "../../hooks/useTranslation";

const BanksContainer = styled.section`
  background: #e5ecf7;
  margin-top: calc(50 / 1400 * 100vw);
  * {
    font-family: ${props => (props.language === "en" ? "Poppins" : "Almarai")};
  }

  @media (max-width: 768px) {
    padding-top: calc(20 / 375 * 100vw);
  }
`;

const BanksWrapper = styled.div`
  width: calc(1248 / 1440 * 100vw);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`;

const BanksTitle = styled.h2`
  font-size: calc(24 / 1440 * 100vw);
  color: #333;
  text-align: right;
  margin-bottom: calc(12 / 1440 * 100vw);
  font-weight: 600;

  @media (max-width: 768px) {
    margin-bottom: calc(12 / 375 * 100vw);
    font-size: calc(24 / 375 * 100vw);
  }
`;

const BanksGrid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BankCard = styled.div`
  padding: calc(12 / 1440 * 100vw);
  border-radius: calc(8 / 1440 * 100vw);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: calc(12 / 375 * 100vw);
    border-radius: calc(8 / 375 * 100vw);
  }
`;

const BankImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: calc(60 / 1440 * 100vw);

  @media (max-width: 768px) {
    max-height: calc(60 / 375 * 100vw);
  }
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(200 / 375 * 100vw);

  &:after {
    content: "";
    width: calc(40 / 375 * 100vw);
    height: calc(40 / 375 * 100vw);
    border: calc(4 / 375 * 100vw) solid #f3f3f3;
    border-top: calc(4 / 375 * 100vw) solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function Banks() {
  const { get, data, loading } = useApi();
  const { t, language } = useTranslation();

  useEffect(() => {
    get("/bank", { page: 1, page_size: 6 });
  }, [get]);

  if (loading) {
    return (
      <BanksContainer>
        <BanksWrapper>
          <LoadingSpinner />
        </BanksWrapper>
      </BanksContainer>
    );
  }

  return (
    <BanksContainer language={language} dir={language === "ar" ? "rtl" : "ltr"}>
      <BanksWrapper>
        <BanksTitle>{t.weTrust}</BanksTitle>
        <BanksGrid>
          {data?.results.map(bank => (
            <BankCard key={bank.id}>
              <BankImage src={bank.logo} alt={bank.name} loading="lazy" />
            </BankCard>
          ))}
        </BanksGrid>
      </BanksWrapper>
    </BanksContainer>
  );
}

export default Banks;
