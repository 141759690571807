import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowIcon2 } from "../assets/Icons";
import useTranslation from "../../hooks/useTranslation";
import Skeleton from "../Seller/Skeleton";
import useApi from "../../hooks/useApi";
import NavButton from "../assets/NavButton";
const LoanCarCard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCars, setTotalCars] = useState(0);
  const { language, t } = useTranslation();
  const { get, loading, data } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const response = await get(`/car_detail?page=${currentPage}&page_size=1`);
      if (response?.count) {
        setTotalCars(response.count);
      }
    };

    fetchData();
  }, [currentPage, get]);

  const handlePreviousCar = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleNextCar = () => {
    if (currentPage < totalCars) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const carData = data?.results?.[0];
  const images = carData?.car?.meta_data?.images ? Object.values(carData.car.meta_data.images) : [];

  const getVisibleDots = (current, total) => {
    if (total <= 5) {
      return [...Array(total)].map((_, i) => ({
        pageNumber: i + 1,
        isActive: current === i + 1,
      }));
    }

    let dots = [];
    let start = Math.max(1, Math.min(current - 2, total - 4));
    let end = Math.min(start + 4, total);

    if (end === total) {
      start = total - 4;
    }

    for (let i = start; i <= end; i++) {
      dots.push({
        pageNumber: i,
        isActive: current === i,
      });
    }

    return dots;
  };

  let carName = language === "en" ? carData?.car?.name : carData?.car?.name_ar;

  return (
    <Container>
      <NavigationSection>
        <NavButton position="start" language={language} onClick={handlePreviousCar} disabled={currentPage === 1}>
          <ArrowIcon2 />
        </NavButton>
        <PageIndicator>
          {getVisibleDots(currentPage, totalCars).map(({ pageNumber, isActive }) => (
            <span key={pageNumber} className={isActive ? "active" : ""} onClick={() => setCurrentPage(pageNumber)} />
          ))}
        </PageIndicator>
        <NavButton position="end" language={language} onClick={handleNextCar} disabled={currentPage === totalCars}>
          <ArrowIcon2 />
        </NavButton>
      </NavigationSection>
      {loading ? (
        <Skeleton width="100%" height="291px" />
      ) : (
        <CarContainer>
          <ImageContainer>
            <CarImage src={images[0] || "/car-placeholder.jpg"} alt={carData?.car?.name} />
          </ImageContainer>
          <InfoSection>
            <CarDetails>
              <CarName>{carName}</CarName>
              <SellerInfo>
                <SellerLogo src={carData?.car?.model?.brand?.logo} alt={carData?.seller?.name} />
                <SellerDetails>
                  <SellerName>{language === "en" ? carData?.seller?.name : carData?.seller?.name_ar}</SellerName>
                  <SellerType>{t.seller.seller}</SellerType>
                </SellerDetails>
              </SellerInfo>
            </CarDetails>
            <PriceTag>
              <PriceTagTitle>{t.price}</PriceTagTitle>
              <PriceContainer>
                <Price>{Number(carData?.car_price)?.toLocaleString()}</Price>
                <Currency>{t.sar}</Currency>
              </PriceContainer>
            </PriceTag>
          </InfoSection>
        </CarContainer>
      )}
    </Container>
  );
};
const Container = styled.div`
  flex: 1;
  height: calc(353 / 1440 * 100vw);
  max-width: calc(512 / 1440 * 100vw);
  display: flex;
  flex-direction: column;
  gap: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    width: calc(338 / 375 * 100vw);
    max-width: calc(338 / 375 * 100vw);
    height: calc(280 / 375 * 100vw);
  }
`;

const NavigationSection = styled.div`
  width: 100%;
  height: calc(62 / 1440 * 100vw);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: calc(40 / 375 * 100vw);
  }
`;

const CarContainer = styled.div`
  border-radius: 50px;
  height: calc(291 / 1440 * 100vw);

  @media (max-width: 768px) {
    height: calc(280 / 375 * 100vw);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: calc(186 / 1440 * 100vw);

  @media (max-width: 768px) {
    height: calc(180 / 375 * 100vw);
  }

  /* overflow: hidden; */
`;

const CarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: calc(12 / 1440 * 100vw);
  border-top-left-radius: calc(12 / 1440 * 100vw);

  @media (max-width: 768px) {
    border-top-right-radius: calc(8 / 375 * 100vw);
    border-top-left-radius: calc(8 / 375 * 100vw);
  }
`;

const InfoSection = styled.div`
  width: 100%;
  height: calc(108 / 1440 * 100vw);
  background-color: #0b1837;
  border-radius: 0 0 calc(12 / 1440 * 100vw) calc(12 / 1440 * 100vw);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  padding: calc(17 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(12 / 375 * 100vw);
    border-radius: 0 0 calc(8 / 375 * 100vw) calc(8 / 375 * 100vw);
    height: calc(80 / 375 * 100vw);
  }
`;

const CarDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CarName = styled.h3`
  font-size: calc(18 / 1440 * 100vw);
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: calc(13 / 375 * 100vw);
  }
`;

const SellerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: calc(10 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(8 / 375 * 100vw);
  }
`;

const SellerLogo = styled.img`
  width: calc(40 / 1440 * 100vw);
  height: calc(40 / 1440 * 100vw);
  border: 1px solid #fff;
  border-radius: calc(10 / 1440 * 100vw);
  padding: calc(5 / 1440 * 100vw);

  @media (max-width: 768px) {
    width: calc(30 / 375 * 100vw);
    height: calc(30 / 375 * 100vw);
    border-radius: calc(8 / 375 * 100vw);
  }
`;

const SellerDetails = styled.div`
  margin-top: calc(11 / 1440 * 100vw);
`;

const SellerName = styled.h4`
  font-size: calc(16 / 1440 * 100vw);
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: calc(11 / 375 * 100vw);
  }
`;

const SellerType = styled.p`
  font-size: calc(14 / 1440 * 100vw);
  font-weight: 400;
  color: #d8d8d8;

  @media (max-width: 768px) {
    font-size: calc(12 / 375 * 100vw);
  }
`;

const PriceTag = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceTagTitle = styled.p`
  font-size: calc(14 / 1440 * 100vw);
  font-weight: 400;
  color: #d8d8d8;

  @media (max-width: 768px) {
    font-size: calc(12 / 375 * 100vw);
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: calc(4 / 1440 * 100vw);
  align-items: center;
`;

const Price = styled.p`
  font-size: calc(24 / 1440 * 100vw);
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: calc(20 / 375 * 100vw);
  }
`;

const Currency = styled.p`
  font-size: calc(16 / 1440 * 100vw);
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: calc(14 / 375 * 100vw);
  }
`;

const PageIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: calc(8 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(4 / 375 * 100vw);
  }

  span {
    width: calc(8 / 1440 * 100vw);
    height: calc(8 / 1440 * 100vw);
    border-radius: 50%;
    background-color: #d9d9d9;
    transition: all 0.3s ease;

    &.active {
      background-color: #154cd6;
      width: calc(24 / 1440 * 100vw);
      border-radius: calc(4 / 1440 * 100vw);
    }

    @media (max-width: 768px) {
      width: calc(10 / 375 * 100vw);
      height: calc(10 / 375 * 100vw);

      &.active {
        width: calc(20 / 375 * 100vw);
        border-radius: calc(4 / 375 * 100vw);
      }
    }
  }
`;

export default LoanCarCard;
