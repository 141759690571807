import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { desktopSizes, mobileSizes } from "../util/sizes";
import { BlogCard } from "../Components/Home/ItemCard";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import mobileheader_bg from "../images/header-reviews.png";
import LatestBlog from "../Components/Blogs/LatestBlog";
import { useGetAllBlogs } from "../hooks/blog/blogHooks";
import { Link } from "react-router-dom";
import Pagination from "../Components/assets/Pagination";
import CalendarBlog from "../Components/Blogs/components/CalendarBlog";
import Tags from "../Components/Blogs/components/Tags";
import CardSkeleton from "../Components/assets/Skeleton/CardSkeleton";
import Empty from "../Components/assets/Empty";
import { ReactComponent as Search } from "../Components/assets/icons/search.svg";
import { ReactComponent as CalendarIcon } from "../Components/assets/icons/calendar.svg";
import { ReactComponent as TagsIcon } from "../Components/assets/icons/tags.svg";


const CarReviews = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const [dateValue, onChangeDate] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [debouncedSearchKey, setDebouncedSearchKey] = useState('');
  const [tags, setTags] = useState('');

  const [showCalendar, setShowCalendar] = useState(window.innerWidth >= 768);
  const [showTags, setShowTags] = useState(window.innerWidth >= 768);

  const toggleCalendar = () => setShowCalendar(!showCalendar);
  const toggleTags = () => setShowTags(!showTags);

  const formattedDate = dateValue && dateValue.toLocaleDateString('en-GB');

  const finalTags = tags.length > 0 ? tags.join(",") : '';


  const [query, setQuery] = useState({
    page: 1,
    page_size: 6,
    created_at_date: formattedDate,
    title__icontains: '',
    keywords: ''
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchKey(searchKey); // Only set the debounced value after 500ms
    }, 1000);

    return () => {
      clearTimeout(handler); // Cleanup timeout if user types within 500ms
    };
  }, [searchKey]);


  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      created_at_date: formattedDate,
      title__icontains: debouncedSearchKey,
      keywords: finalTags
    }));
  }, [formattedDate, debouncedSearchKey, finalTags]);

  // console.log("query: ", query);
  // console.log("CarReviews value (formatted): ", formattedDate);
  // console.log("searchKey: ", searchKey);
  // console.log("debouncedSearchKey: ", debouncedSearchKey);
  // console.log("Tag clicked: ", tags);
  // console.log("finalTags: ", finalTags);
  console.log("showCalendar: ", showCalendar);
  console.log("showTags: ", showTags);

  useEffect(() => {
    const handleResize = () => {
      const isWideScreen = window.innerWidth >= 768;
      setShowCalendar(isWideScreen);
      setShowTags(isWideScreen);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const { isFetching, data: all_reviews, error } = useGetAllBlogs(query);

  const handlePageChange = (page) => {
    setQuery({ ...query, page });
  };

  console.log("all_reviews: ", all_reviews)

  return (
    <StyledPage language={language}>
      <div className="shape1 desktop-only"></div>
      <div className="shape2 desktop-only"></div>

      <div className="title">{t.carReviewsPage.title}</div>
      <p className="section-title">{t.carReviewsPage.latestReviews}</p>
      <div className="grid-blog">

        <div className="lists">
          <LatestBlog />

          <div className="reviews all-blogs">
            <p className="section-title">
              {t.carReviewsPage.allReviews}
            </p>
            <div className="list car-blogs">
              {(all_reviews?.results.length === 0 || !all_reviews) && isFetching && (
                <>
                  <CardSkeleton />
                  <CardSkeleton />
                </>
              )}
              {all_reviews?.results?.map((r, i) => (
                <Link to={"/blog/" + r.id}>
                  <BlogCard item={r} key={i} page={"carReviews"} />
                </Link>
              ))}
            </div>
            <Pagination
              pageSize={query.page_size}
              currentPage={query.page}
              totalItems={all_reviews?.count}
              onPageChange={handlePageChange}
            />
          </div>

          {all_reviews?.results.length === 0 && !isFetching && (
            <Empty title='Blogs' />
          )}
        </div>

        <div className="filter-column">
          <div className="compact-search">
            <div className="search">
              <h2 className="search-title">{t.intro.search.search}</h2>
              <div className="search-form">
                <input
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  className="search-input"
                  placeholder={t.carsFilter.searchforBrand}
                  id="search-brand"
                />
                <div className="search">
                  <Search />
                </div>
              </div>
            </div>
            <div className="icon-filter">
              <div onClick={toggleCalendar} className="filter-icon">
                <CalendarIcon className={showCalendar && "colored-icon"} />
              </div>
              <div onClick={toggleTags} className="filter-icon">
                <TagsIcon className={showTags && "colored-icon"} />
              </div>
            </div>
          </div>

          {showCalendar && (
            <div className="calendar">
              <CalendarBlog onChangeDate={onChangeDate} dateValue={dateValue} />
            </div>
          )}
          {showTags && (
            <div className="tags">
              <Tags setTags={setTags} tags={tags} />
            </div>
          )}
        </div>

      </div>
    </StyledPage>
  );
};

const StyledPage = styled.div`
  overflow: hidden;
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  position: relative;
  padding: 3.611vw 10.694vw 5.556vw 10.417vw;
  .shape1 {
    position: absolute;
    z-index: 0;
    top: -30.347vw;
    left: -15.347vw;
    width: 130.625vw;
    height: 44.167vw;
    border-radius: 60%;
    border: ${desktopSizes._1px} solid #979797;
    opacity: 0.25;
  }
  .shape2 {
    position: absolute;
    z-index: 0;
    top: -30.347vw;
    left: -15.347vw;
    width: 130.625vw;
    height: 47.847vw;
    border-radius: 60%;
    border: ${desktopSizes._1px} solid #979797;
    opacity: 0.25;
  }
  .colored-icon {
    color: #154CD6;
  }
  .filter-icon svg {
    transition: .3s;
  }
  .filter-icon:hover svg {
    color: #154CD6;
  }
  .compact-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
  .compact-search .search {
    flex : 1
  }
  .section-title {
      margin-bottom: ${desktopSizes._32px};
      color: #0d0a19;
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-size: ${desktopSizes._24px};
      line-height: ${desktopSizes._36px};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "600" : "700")};
      @media (min-width: 1440px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
  .filter-column {
    z-index: 2000;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .search {
      background: white;
      padding: 25px;
      box-shadow: 0 0 8px 1px #00000012;
    }
    .search .search-title{
      color: #292929;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    }

    .search-form {
        position: relative;
        margin-top: 1.111vw;
        .search-input {
          width: 100%;
          background: #F7F7F7;
          border: 0.069vw solid rgb(228 230 232 / 28%);
          border-radius: 4px;
          font-weight: 500;
          font-size: 1.111vw;
          line-height: 2.222vw;
          color: rgb(13, 10, 25);
          padding: 1.111vw;
          padding-right: ${props => props.language == "en" && " 3.125vw"};
          padding-left: ${props => props.language == "ar" && " 3.125vw"};

          @media (min-width: 1440px) {
            font-size: 16px;
            line-height: 32px;
          }
        }
        .search-input::placeholder {
          font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
          font-style: normal;
          font-weight: ${props => (props.language == "en" ? "400" : "300")};
          color: #0d0a19;
          opacity: 0.5;

          @media (min-width: 1440px) {
            font-size: 16px;
            line-height: 32px;
          }
        }
        .search {
          position: absolute;
          background: transparent;
          box-shadow: none;
          right: 10px;
          top: 50%;
          padding: 0;
          transform: translateY(-50%);
          svg {
            width: 1.667vw;
            height: 1.667vw;
            @media (min-width: 1440px) {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
  }
  .title {
    text-align: center;
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-size: 3.194vw;
    line-height: 4.792vw;
    font-style: normal;
    font-weight: ${props => (props.language == "en" ? "600" : "700")};
    margin-bottom: ${desktopSizes._32px};
    @media (min-width: 1440px) {
      font-size: 46px;
      line-height: 69px;
    }
  }
  .grid-blog {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ${desktopSizes._28px};
  }
  .grid-blog > .lists {
    grid-column: 1 / 3;
  }
  .lists {
    display: flex;
    flex-direction: column;
    gap: 3.125vw;
    .reviews {
      z-index: 1000;
      
      .list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: ${desktopSizes._28px};
      }
      .list.latest {
        display: grid;
        grid-template-columns: 1fr;
        gap: ${desktopSizes._28px};
      }
      .list.latest > div {
        width: 100% !important;
      }
      .list.car-blogs > a > div {
        width: 100% !important;
      }
      .list.car-blogs > a > div img{
        width: 100% !important;
      }
      .list.latest .item-img {
        height: auto !important;
        width: 100% !important;
      }
    }
  }


.icon-filter {
  display: none;
}

@media (max-width: 768px) {
  .grid-blog {
    gap: 30px;
    margin-top: 20px;
  }

  .filter-column {
    display: flex;
  }

  .icon-filter {
    display: flex;
    gap: 10px;
    justify-content: space-around;
    min-width: 100px;
  }
  .section-title {
      font-size: 16px;
  }

  .filter-icon {
    cursor: pointer;
  }

  .filter-column .search {
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
}
  .filter-column .search .search-title {
    display: none !important;
}
    .filter-column .search-form {
    margin-top: 0 !important;
    }
    .filter-column .search-form input{
        height: 30px;
    }
    .filter-column .search-form input::placeholder {
      font-size: 16px !important
  }

  @media (max-width: 768px) {
    .grid-blog {
        grid-template-columns: 1fr; 
    }

    .grid-blog > .lists {
        grid-column: auto; 
    }
    .grid-blog > .filter-column {
      order: -1;
    }
    padding: ${mobileSizes._6px} ${mobileSizes._16px} ${mobileSizes._24px};
    background-image: url(${mobileheader_bg});
    background-repeat: no-repeat;
    // background-size: contain;
    background-position: 0 -13.333vw;
    .title {
      font-size: ${mobileSizes._24px};
      line-height: ${mobileSizes._36px};
      margin-bottom: ${mobileSizes._24px};
    }
    .lists {
      display: flex;
      flex-direction: column;
      gap: ${mobileSizes._24px};
      .reviews {
        z-index: 1000;
        .section-title {
          margin-bottom: ${mobileSizes._24px};
          font-size: ${mobileSizes._20px};
          line-height: ${mobileSizes._30px};
        }
        .list {
          display: flex;
          flex-direction: column;
          gap: ${mobileSizes._15px};
        }
      }
    }
  }
`;
export default CarReviews;
