import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const StyledAuthPage = ({ children, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledPage language={language}>
      <>{children}</>
    </StyledPage>
  );
};

const StyledPage = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  display: flex;
  align-items: center;
  gap: 10.903vw;
  padding: 2.222vh 2.083vw 0 2.083vw;
  background: #fff;
  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 1.944vw 2.083vw 0 2.083vw;
  }
  .container {
    width: 29.167vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-bottom: 3.556vh;
      width: 11.042vw;
      height: 3.333vw;

      @media (min-width: 1440px) {
        width: 195px;
        height: 47px;
      }
      @media (min-width: 769px) and (max-width: 1024px) {
        margin-bottom: 2.222vw;
      }
    }
    .title {
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: 700;
      font-size: 2.083vw;
      line-height: ${desktopSizes._48px};
      color: #0d0a19;
      margin-bottom: 1.333vh;
      @media (min-width: 769px) and (max-width: 1024px) {
        margin-bottom: ${desktopSizes._12px};
      }
      @media (min-width: 1440px) {
        font-size: 30px;
        line-height: 48px;
      }
    }
    .text {
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: 500;
      font-size: 0.972vw;
      line-height: ${desktopSizes._21px};
      color: #9a9ea7;
      margin-bottom: 1.778vh;
      @media (min-width: 769px) and (max-width: 1024px) {
        margin-bottom: ${desktopSizes._24px};
      }
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
    form {
      .fields {
        gap: 2.667vh;
        .input-div {
          input {
            padding-top: 2.667vh;
            padding-bottom: 2.667vh;
          }
          .label_active {
            padding-top: 2.5vw;
            padding-bottom: 1.111vw;
          }
        }
        .name-feilds {
          display: flex;
          gap: 0.556vw;

          input {
            width: 14.306vw;
          }
        }
        @media (min-width: 769px) and (max-width: 1024px) {
          gap: ${desktopSizes._8px};
          .input-div {
            input {
              padding-top: ${desktopSizes._24px};
              padding-bottom: ${desktopSizes._24px};
            }
          }
        }
      }
      .btn-container {
        margin-top: 2.667vh;
        @media (min-width: 769px) and (max-width: 1024px) {
          margin-top: ${desktopSizes._24px};
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: ${mobileSizes._12px} ${mobileSizes._16px} ${mobileSizes._24px} ${mobileSizes._16px};
    background: #fbfbfb;
    .container {
      width: 91.467vw;
      align-items: flex-start;
      .title {
        font-size: ${mobileSizes._20px};
        font-weight: ${props => (props.language == "en" ? "600" : "700")};
        line-height: ${mobileSizes._30px};
        margin-bottom: ${mobileSizes._24px};
      }
      form {
        .fields {
          gap: ${mobileSizes._16px};
          .input-div {
            input {
              padding-top: ${mobileSizes._20px};
              padding-bottom: ${mobileSizes._20px};
            }

            .label_active {
              padding-top: ${mobileSizes._30px};
              padding-bottom: ${mobileSizes._8px};
            }
          }
          .name-feilds {
            display: flex;
            flex-direction: column;
            gap: ${mobileSizes._16px};

            input {
              width: 91.467vw;
            }
          }
        }
        .btn-container {
          margin-top: ${mobileSizes._24px};
        }
      }
    }
    .mobile-footer {
      display: flex;
      align-items: center;
      gap: ${mobileSizes._4px};
      width: 100%;
      justify-content: center;
      padding-top: ${mobileSizes._24px};
      * {
        font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
        font-size: ${mobileSizes._14px};
        font-style: normal;
        font-weight: ${props => (props.language == "en" ? "600" : "700")};
        line-height: ${mobileSizes._18px};
      }

      a {
        color: #154cd6;
      }
    }
  }
`;
export default StyledAuthPage;
