import * as React from "react";
import AuthContext from "../../hooks/Auth/providers/AuthContext";

import styled from "styled-components";
import { LogoutIcon, UserIcon } from "../assets/Icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const UserDropdown = () => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const [open, setOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);

  const { user, logoutHandler } = React.useContext(AuthContext);

  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownOpener language={language} onClick={handleClick} ref={dropdownRef}>
      <img
        src={user?.extra_details?.image || "https://avatar.iran.liara.run/public/boy"}
        alt="user"
        style={{ width: 25, height: 25, borderRadius: "15%" }}
      />
      <Name>{user?.name}</Name>
      <ArrowIcon />
      <Menu isOpen={open}>
        <Link to="/account">
          <MenuItem>
            <UserIcon />
            {t.account.myAccount}
          </MenuItem>
        </Link>
        <MenuItem onClick={logoutHandler}>
          <LogoutIcon />
          {t.header.logout}
        </MenuItem>
      </Menu>
    </DropdownOpener>
  );
};

export default UserDropdown;

const Name = styled.div`
  margin-left: 5px;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

const DropdownOpener = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-family: ${props => (props.language === "en" ? "Inter" : "Almarai")} !important;
  & * {
    font-family: inherit;
  }
`;

const Menu = styled.div`
  width: fit-content;
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  transform: translateY(${props => (props.isOpen ? "0" : "-10px")});
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
`;

const MenuItem = styled.div`
  // nowrap
  white-space: nowrap;
  display: flex;
  justify-content: start;
  gap: 4px;
  align-items: center;
  padding: 10px 15px;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    color: #154cd6;
    svg {
      fill: #154cd6;
    }
  }
  svg {
    width: 25px;
    height: 25px;
    color: #000;
  }
`;

const ArrowIcon = styled.span`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;
