import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const ItemCard = ({ itemValue, image = null, onClick, step, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledCard
      isSelected={props.isSelected}
      onClick={onClick}
      language={language}
      step={step}
    >
      {image && <img src={image} />}
      <div className="item-name">{itemValue}</div>
    </StyledCard>
  );
};
const StyledCard = styled.button`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  cursor: pointer;
  background: #ffffff;
  border: ${props => (props.isSelected ? `0.139vw solid #154cd6` : `0.069vw solid #f1f1f1`)};
  border-radius: 0.278vw;
  width: 18.264vw;
  height: 5.556vw;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.667vw;
  padding-left: ${props => props.language == "en" && "1.667vw"};
  padding-right: ${props => props.language == "ar" && "1.667vw"};
  .item-name {
    direction: ${props => props.step == 2 && "ltr"};
    font-family: ${props => (props.language == "en" || props.step == 2 ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: 600;
    font-size: 1.25vw;
    line-height: 1.875vw;
    color: #0d0a19;

    @media (min-width: 1440px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
  img {
    width: 2.778vw;
    height: 2.778vw;
    object-fit: contain;

    @media (min-width: 1440px) {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 768px) {
    border: ${props => (props.isSelected ? `${mobileSizes._2px} solid #154cd6` : `${mobileSizes._1px} solid #f1f1f1`)};
    border-radius: ${mobileSizes._4px};
    width: 91.467vw;
    height: 14.933vw;
    gap: ${mobileSizes._12px};
    padding-left: ${props => props.language == "en" && mobileSizes._8px};
    padding-right: ${props => props.language == "ar" && mobileSizes._8px};

    .item-name {
      font-size: ${mobileSizes._18px};
      line-height: ${mobileSizes._27px};
      // padding-left: ${mobileSizes._8px};
    }
    img {
      width: ${mobileSizes._32px};
      height: ${mobileSizes._32px};
      object-fit: contain;
    }
  }
`;
export default ItemCard;
