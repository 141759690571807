import { useSelector } from "react-redux";
import ar from "../util/locales/ar";
import en from "../util/locales/en";

const useTranslation = () => {
  const language = useSelector(state => state.language);
  const t = language === "ar" ? ar : en;

  return { language, t };
};

export default useTranslation;
