import React, { useState } from "react";
import styled from "styled-components";
import { CheckIon } from "../assets/Icons";
import useTranslation from "../../hooks/useTranslation";

const CarFeatures = ({ carData }) => {
  const [activeTab, setActiveTab] = useState("specifications");
  const { t } = useTranslation();

  const tabs = [
    { id: "specifications", label: t.comparison.specifications },
    { id: "safety", label: t.comparison.safety },
    { id: "luxury", label: t.comparison.luxury },
    { id: "convenience", label: t.comparison.convenienceComfort },
    { id: "entertainment", label: t.comparison.entertainmentTechnology },
  ];

  const specifications = [
    { label: t.comparison.wheelSize, value: carData?.wheel_size || "-" },
    { label: t.comparison.bodyType, value: carData?.body_type || "-" },
    { label: t.comparison.vinNumber, value: carData?.vin_number || "-" },
    { label: t.comparison.builtInCamera, value: carData?.builtin_camera || "-" },
    { label: t.comparison.bodyVolume, value: `${carData?.num_of_seats || 0} Seats` },
    { label: t.comparison.interiorToolKit, value: carData?.interior_tool_kit || "-" },
    { label: t.comparison.lightingType, value: carData?.lighting_type || "-" },
    { label: t.comparison.maxTorque, value: `${carData?.max_torque || 0} lb-ft` },
    { label: t.comparison.warrantyService, value: `${carData?.warranty_km || 0} km` },
  ];

  const safetyFeatures = [
    { label: t.comparison.airbags, value: carData?.airbag },
    { label: t.comparison.blindSpotSensor, value: carData?.has_blind_spot_sensor },
    { label: t.comparison.sideAirbags, value: carData?.has_side_airbags },
    { label: t.comparison.stabilityControl, value: carData?.has_stability_control },
    { label: t.comparison.driverPassengerAirbags, value: carData?.has_driver_passenger_airbags },
    { label: t.comparison.frontRearSensors, value: carData?.has_front_rear_sensors },
    { label: t.comparison.alarmSystem, value: carData?.has_alarm_system },
    { label: t.comparison.brakeAssist, value: carData?.has_brake_assist },
    { label: t.comparison.laneDepartureWarning, value: carData?.has_lane_departure_warning },
    { label: t.comparison.rearAirbags, value: carData?.has_rear_airbags },
    { label: t.comparison.electronicBrakeForceDistribution, value: carData?.has_electronic_brake_force_distribution },
  ];

  const luxuryFeatures = [
    { label: t.comparison.panoramaRoof, value: carData?.roof === "panorama" },
    { label: t.comparison.cooledSeats, value: carData?.has_cooled_seats },
    { label: t.comparison.integratedChrome, value: carData?.has_integrated_chrome },
    { label: t.comparison.headUpDisplay, value: carData?.has_head_up_display },
    { label: t.comparison.activeMotionFrontSeats, value: carData?.has_active_motion_front_seats },
    { label: t.comparison.leatherSeats, value: carData?.has_leather_seats },
    { label: t.comparison.wirelessChargingPad, value: carData?.has_wireless_charging_pad },
  ];

  const entertainmentFeatures = [
    { label: t.comparison.navigation, value: carData?.has_navigation },
    { label: t.comparison.premiumSoundSystem, value: carData?.has_premium_sound_system },
    { label: `${carData?.speakers || 0} ${t.comparison.speakers}`, value: true },
    { label: t.comparison.bluetooth, value: carData?.has_bluetooth },
    { label: t.comparison.securitySystem, value: carData?.has_secuirty_system },
    { label: t.comparison.voiceCommand, value: carData?.has_voice_command },
    { label: t.comparison.radio, value: carData?.has_radio },
  ];
  const renderFeatures = () => {
    const getFeaturesList = () => {
      switch (activeTab) {
        case "specifications":
          return specifications.map(spec => (
            <SpecificationRow key={spec.label}>
              <CheckIon />
              <SpecLabel>{spec.label}</SpecLabel>
              <SpecValue>{spec.value}</SpecValue>
            </SpecificationRow>
          ));
        case "safety":
          return safetyFeatures.map(
            feature =>
              feature.value && (
                <FeatureItem key={feature.label}>
                  <StyledCheck />
                  <span>{feature.label}</span>
                </FeatureItem>
              )
          );
        case "luxury":
          return luxuryFeatures.map(
            feature =>
              feature.value && (
                <FeatureItem key={feature.label}>
                  <StyledCheck />
                  <span>{feature.label}</span>
                </FeatureItem>
              )
          );
        case "entertainment":
          return entertainmentFeatures.map(
            feature =>
              feature.value && (
                <FeatureItem key={feature.label}>
                  <StyledCheck />
                  <span>{feature.label}</span>
                </FeatureItem>
              )
          );
        default:
          return null;
      }
    };

    return <ContentSection>{getFeaturesList()}</ContentSection>;
  };

  return (
    <Container>
      <ContentWrapper>
        <Title>{t.car.features}</Title>

        <div className="tabWrapper">
          <TabsContainer>
            {tabs.map(tab => (
              <TabButton key={tab.id} isActive={activeTab === tab.id} onClick={() => setActiveTab(tab.id)}>
                {tab.label}
              </TabButton>
            ))}
          </TabsContainer>
        </div>

        {renderFeatures()}
      </ContentWrapper>
    </Container>
  );
};

export default CarFeatures;

// Styled Components
const Container = styled.div`
  width: calc(1248 / 1440 * 100vw);
  margin: 0 auto;
  background: white;
  border-radius: calc(8 / 1440 * 100vw);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  * {
    font-family: ${props => (props.language === "en" ? "Inter" : "Almarai")};
  }
`;

const ContentWrapper = styled.div`
  margin-top: calc(32 / 1440 * 100vw);
  padding: calc(24 / 1440 * 100vw);

  .tabWrapper {
    overflow-x: auto;
  }

  @media (max-width: 768px) {
    margin-top: calc(16 / 375 * 100vw);
    padding: calc(16 / 375 * 100vw);
  }
`;

const Title = styled.h2`
  font-size: calc(24 / 1440 * 100vw);
  font-weight: bold;
  margin-bottom: calc(24 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(20 / 375 * 100vw);
    margin-bottom: calc(16 / 375 * 100vw);
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e7eb;

  @media (max-width: 768px) {
    width: max-content;
    overflow: scroll;
    border-bottom: none;
    padding-bottom: calc(8 / 375 * 100vw);
  }
`;

const TabButton = styled.button`
  padding: calc(8 / 1440 * 100vw) calc(16 / 1440 * 100vw);
  margin-bottom: -1px;
  color: ${props => (props.isActive ? "#2563eb" : "#6b7280")};
  border-bottom: ${props => (props.isActive ? "2px solid #2563eb" : "none")};
  font-weight: 800;
  transition: all 0.2s;
  background-color: transparent;
  font-size: calc(16 / 1440 * 100vw);

  &:hover {
    color: ${props => (props.isActive ? "#2563eb" : "#374151")};
  }

  @media (max-width: 768px) {
    font-size: calc(16 / 375 * 100vw);
    padding: calc(8 / 375 * 100vw) calc(16 / 375 * 100vw);
    width: fit-content;
  }
`;

const ContentSection = styled.div`
  margin-top: calc(24 / 1440 * 100vw);
  gap: calc(8 / 1440 * 100vw);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: calc(16 / 375 * 100vw);
    gap: calc(8 / 375 * 100vw);
  }
`;

const SpecificationRow = styled.div`
  display: flex;
  align-items: center;
  gap: calc(8 / 1440 * 100vw);
  padding: calc(8 / 1440 * 100vw) 0;
  font-size: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(16 / 375 * 100vw);
    gap: calc(8 / 375 * 100vw);
    padding: calc(8 / 375 * 100vw) 0;
  }
`;

const SpecLabel = styled.span`
  color: #4b5563;
`;

const SpecValue = styled.span`
  font-weight: 500;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: calc(8 / 1440 * 100vw);
  padding: calc(8 / 1440 * 100vw) 0;
  font-size: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(16 / 375 * 100vw);
    gap: calc(8 / 375 * 100vw);
    padding: calc(8 / 375 * 100vw) 0;
  }
`;

const StyledCheck = styled(CheckIon)`
  width: calc(20 / 1440 * 100vw);
  height: calc(20 / 1440 * 100vw);
  color: #2563eb;

  @media (max-width: 768px) {
    width: calc(20 / 375 * 100vw);
    height: calc(20 / 375 * 100vw);
  }
`;
