import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import bg from "../../images/auth/card-bg.png";
import car_img from "../../images/auth/car.png";
import gr from "../../images/auth/gr.png";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const Card = ({ page }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledCard language={language} className="desktop-only">
      <div className="card-body">
        <div className="text">
          <p>{t.authPages.card.text_line1} </p>
          <p>{t.authPages.card.text_line2}</p>
        </div>
      </div>
      {page == "signup" ? (
        <div className="footer">
          <p>{t.authPages.haveAcount}</p>
          <Link to="/login">
            <button>{t.authPages.sign_in}</button>
          </Link>
        </div>
      ) : (
        <div className="footer">
          <p>{t.authPages.dontHaveAccount}</p>
          <Link to="/sign-up">
            <button>{t.authPages.sign_up}</button>
          </Link>
        </div>
      )}
    </StyledCard>
  );
};
const StyledCard = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  position: relative;
  width: 46.944vw;
  height: 93.556vh;
  background-color: #154cd6;
  border-radius: 1.389vw;
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 58.472vw;
  }
  .card-body {
    width: 100%;
    height: 84vh;
    // border-radius: 1.389vw 1.389vw 0 0;
    // background-color: #154cd6;
    background-image: url(${car_img}), url(${gr});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    .text {
      padding: ${props =>
        props.language == "en" ? "13.333vh 8.333vw 44.556vh 4.167vw" : "13.333vh 4.167vw  44.556vh 8.333vw"};
      p {
        font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.417vw;
        color: #ffffff;
      }

      @media (min-width: 1440px) {
        p {
          font-size: 64px;
          line-height: 78px;
        }
      }
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      height: 52.5vw;
      .text {
        padding: ${props =>
          props.language == "en" ? "8.333vw 8.333vw 27.847vw 4.167vw" : "8.333vw 4.167vw27.847vw  8.333vw "};
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 9.556vh;
    border-radius: 0 0 1.389vw 1.389vw;
    padding: 1.458vw 4.167vw 1.458vw 2.222vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(13, 10, 25, 0.75);
    backdrop-filter: blur(5.436563491821289px);

    p {
      font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
      font-weight: ${props => (props.language == "en" ? "600" : "700")};
      font-size: 1.111vw;
      line-height: 1.667vw;
      color: #ffffff;
      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    button {
      // width: 8.056vw;
      padding: 0 0.833vw;
      height: 3.056vw;
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: 600;
      font-size: 1.111vw;
      line-height: 1.458vw;
      color: #ffffff;
      background: #27c1dd;
      border-radius: 0.694vw;
      cursor: pointer;
      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 21px;
      }
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      height: 5.972vw;
    }
  }
`;
export default Card;
