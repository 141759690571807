import React, { useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Compare } from "../../images/compare.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { Link } from "react-router-dom";

const CarCard = ({ car_detail, ...props }) => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const nameRef = useRef();

  const parts = car_detail.car.name_ar.split(/(\d+)/);
  return (
    <StyledCard language={language}>
      <Content>
        {car_detail?.car?.meta_data?.images && (
          <Link to={"/car/" + car_detail.car.id}>
            <img src={car_detail.car.meta_data.images.image1 || "/car-placeholder.jpg"} className="car-img" alt="car" />
          </Link>
        )}
        {language == "en" ? (
          <div
            className="name"
            ref={nameRef}
            // style={nameOverflow && language == "ar" ? { direction: "ltr" } : {}}
          >
            <Link to={"/car/" + car_detail.car.id}>{car_detail.car.name}</Link>
          </div>
        ) : (
          <div
            className="name"
            ref={nameRef}
            // style={nameOverflow && language == "ar" ? { direction: "ltr" } : {}}
          >
            {parts.map((part, index) => {
              if (/\d+/.test(part)) {
                // Applying the font-variant-numeric style to the number part
                return (
                  <span key={index} className="number">
                    {part}
                  </span>
                );
              } else return part;
            })}
          </div>
        )}

        <div className="row">
          <div className="logo-and-seller">
            <div className="logo-container">
              <img src={car_detail.car.model.brand?.logo || "car-placeholder.jpg"} alt="brand-logo" />
            </div>
            <div className="seller">
              <p className="seller-name">{language === "en" ? car_detail.seller.name : car_detail.seller.name_ar}</p>
              <p className="text">{t.carCard.seller}</p>
            </div>
          </div>
          {props.homePage && (
            <button className="compare">
              <Compare />
            </button>
          )}
        </div>
      </Content>
      <PriceSection>
        <p className="text">{t.startsFrom}</p>
        <p className="value">{(+(+car_detail.car_price).toFixed(2)).toLocaleString().replace(/\.00$/, "")}</p>
        <p className="currency">{t.sar}</p>
      </PriceSection>
    </StyledCard>
  );
};

const PriceSection = styled.div`
  background-color: #154cd6;
  color: white;
  border-bottom: 0.972vw;
  border-bottom-left-radius: 0.972vw;
  border-bottom-right-radius: 0.972vw;
  bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  gap: 0.533vw;
  padding: calc(19 / 1440 * 100vw);
  .text {
    font-weight: 400;
    font-size: calc(14 / 1440 * 100vw);
  }
  .value {
    font-weight: 700;
    font-size: calc(24 / 1440 * 100vw);
  }
  .currency {
    font-weight: 400;
    font-size: calc(14 / 1440 * 100vw);
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    padding: calc(10 / 375 * 100vw);
    .text {
      font-size: calc(14 / 375 * 100vw);
    }
    .value {
      font-size: calc(24 / 375 * 100vw);
    }
    .currency {
      font-size: calc(14 / 375 * 100vw);
    }
  }
`;

const Content = styled.div`
  padding: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(15 / 375 * 100vw);
  }
`;

const StyledCard = styled.div`
  background: #ffffff;
  border: 0.069vw solid #e4e6e8;
  border-radius: 0.972vw;
  margin: auto;
  width: calc(294 / 1440 * 100vw);
  direction: ${props => (props.language === "en" ? "ltr" : "rtl")};
  .car-img {
    width: 100%;
    height: calc(171 / 1440 * 100vw);
    object-fit: cover;
    border-radius: calc(0.556vw / 1440 * 100vw);
    margin-bottom: calc(1.111vw / 1440 * 100vw);
  }
  .name {
    .number {
      font-variant-numeric: tabular-nums;
    }
    // height: 3.819vw;
    font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};

    font-weight: 700;
    font-size: 1.25vw;
    line-height: 1.875vw;
    color: #0d0a19;
    margin-bottom: 0.833vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
    @media (min-width: 1440px) {
      font-size: 18px;
      line-height: 27px;
      width: 100%;
    }

    @media (max-width: 768px) {
      font-size: calc(16 / 375 * 100vw);
      line-height: 24px;
      margin-bottom: 1.067vw;
      width: 100%;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .compare {
      background: none;
      border: none;
      cursor: pointer;
      svg {
        width: 1.458vw;
        height: 1.042vw;
      }
    }
    .logo-and-seller {
      display: flex;
      width: 70%;
      align-items: center;
      gap: 0.833vw;
      .logo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 2.639vw;
        height: 2.639vw;
        border: 0.069vw solid #e4e6e8;
        border-radius: 0.556vw;

        @media (min-width: 1440px) {
          width: 38px;
          height: 38px;
        }
      }
      img {
        width: 2.083vw;
        object-fit: contain;
        @media (min-width: 1440px) {
          width: 30px;
        }
      }
    }
    .seller {
      .seller-name {
        font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
        width: 19.861vw;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #0d0a19;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
      .text {
        font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
        font-style: normal;
        font-weight: ${props => (props.language == "en" ? "400" : "300")};

        font-size: 0.833vw;
        line-height: 1.25vw;
        color: #9a9ea7;

        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    border: none;
    border-radius: 2.133vw;
    width: 100%;
    box-shadow: ${props =>
      props.language == "en"
        ? "1.067vw 1.067vw 2.133vw rgba(0, 0, 0, 0.12)"
        : "-1.067vw  1.067vw 2.133vw rgba(0, 0, 0, 0.12)"};

    .car-img {
      width: 100%;
      height: 32.267vw;
      border-radius: 2.133vw;
      margin-bottom: 2.133vw;
    }
    .name {
      width: 54.933vw;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 4.267vw;
      line-height: 6.4vw;
      margin-bottom: 4.267vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .price {
      margin-bottom: 4.267vw;
      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 3.733vw;
        line-height: 5.6vw;
        margin-bottom: 1.067vw;
      }
      .value {
        display: flex;
        align-items: flex-end;
        gap: 1.067vw;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 3.733vw;
        line-height: 5.6vw;
        .currency {
          font-weight: 600;
          font-size: 2.667vw;
          // line-height: 0.972vw;
        }
      }
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .compare {
        background: none;
        border: none;
        cursor: pointer;
        svg {
          width: 5.6vw;
          height: 4vw;
        }
      }
      .logo-and-seller {
        display: flex;
        align-items: center;
        gap: 3.2vw;
        .logo-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 6.4vw;
          height: 6.4vw;
          border: 0.267vw solid #e4e6e8;
          border-radius: 1.067vw;
        }
        img {
          width: 4.8vw;
        }
      }
      .seller {
        .seller-name {
          width: 46.4vw;
          font-size: 3.733vw;
          line-height: 4.533vw;
          margin-bottom: 1.067vw;
        }
        .text {
          font-size: 3.2vw;
          line-height: 4vw;
        }
      }
    }
  }
`;
export default CarCard;
