import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as MaterilasIcon } from "../../images/materilas.svg";
import { ReactComponent as PaintIcon } from "../../images/paint.svg";
import { ReactComponent as MaterialColorIcon } from "../../images/material-paint.svg";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import history from "../../util/history";
import QuotationSuccess from "./QuotationSuccess";
import { useLocation, useNavigate } from "react-router";

import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import AuthContext from "../../hooks/Auth/providers/AuthContext";
import { useRequestSubmission } from "../../hooks/CarHooks";
import { PhoneIcon } from "../assets/Icons";
import SellerPhoneModal from "./SellerPhoneModal";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { axiosCarvenui } from "../../util/integration";

const AvailabilityCard = ({ data, selectedColor, selectedMaterial, selectedMaterialColor, seller, ...props }) => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const [colors, setColors] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [materialColors, setMaterialColors] = useState([]);
  const [isAvailable, setIsAvailable] = useState();
  const [price, setPrice] = useState();
  const [detail, setDetail] = useState({});
  const [sellerLogo, setSellerLogo] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [quotationSuccess, setQuotationSuccess] = useState(false);

  const { user, isLoggedIn } = useContext(AuthContext);
  const { requestSubmission, isLoading } = useRequestSubmission();

  console.log(detail);

  const handleRequestQuotation = async () => {
    await axiosCarvenui(`/car_detail/${detail.car.id}/request_count`);

    const redirectToLoginOrVerify = path => {
      localStorage.setItem("saved_path", location.pathname);
      navigate(path);
    };

    if (user && isLoggedIn && user?.is_phone_verified) {
      const finalData = {
        car_detail: detail.id,
        seller: detail.seller.id,
        type: "inquiry",
      };
      try {
        await requestSubmission(finalData);
        setQuotationSuccess(true);
        history.push("?quotation-success=true");
      } catch (e) {
        console.log(e);
      }
    } else {
      const path = !user || !isLoggedIn ? `/login` : `/verify-user`;
      redirectToLoginOrVerify(path);
    }
  };

  useEffect(() => {
    try {
      if (data.details?.length > 0) {
        let details = data.details;
        setSellerLogo(details[0].seller.logo);
        let car_colors = details.map(d => d.car_color);
        let car_materials = details.map(d => d.interior_fabric);
        let interior_colors = details.map(d => d.interior_color);
        setColors([...new Set(car_colors)]);
        setMaterials([...new Set(car_materials)]);
        setMaterialColors([...new Set(interior_colors)]);
        setIsAvailable(data.isAvailable);
        setPrice(data.price);
      }
    } catch (e) {
      console.log(e);
    }
  }, [data]);

  useEffect(() => {
    if (selectedColor && selectedMaterial && selectedMaterialColor) {
      if (data.details?.length > 0) {
        let details = data.details;
        let detail = details.find(
          d =>
            d.car_color == selectedColor &&
            d.interior_color == selectedMaterialColor &&
            d.interior_fabric == selectedMaterial
        );

        setDetail(detail);
      }
    }
  }, [selectedColor, selectedMaterialColor, selectedMaterial]);

  return (
    <>
      <QuotationSuccess detail={detail} />
      <StyledCard language={language}>
        <div className="card-header">
          <Link to={`/seller/${seller.id}`}>
            <img src={sellerLogo} className="seller-logo" />
          </Link>

          <div className="price-info">
            <p className="price-text">{t.price}</p>
            <div className="price">
              <p className="price-value">{(+(+price).toFixed(2)).toLocaleString().replace(/\.00$/, "")}</p>
              <p className="currency">SAR</p>
            </div>
          </div>
        </div>
        <div className="selections">
          <div className="item">
            <PaintIcon />

            <div className="col">
              <p className="col-text">{t.carPage.availability.body_color}</p>
              <div className="colors-list">
                {colors.map((color, index) => (
                  <div
                    className={`color-circle`}
                    key={color}
                    style={
                      color.toLowerCase() == "white"
                        ? {
                            border: "1px solid #EEEEEE",
                            background: color,
                          }
                        : { background: color }
                    }
                  ></div>
                ))}
              </div>
            </div>
          </div>

          <div className="item">
            <MaterilasIcon />

            <div className="col">
              <p className="col-text">{t.carPage.availability.interior_material}</p>
              <div className="materials-list">
                {materials.map((material, index) => (
                  <>
                    <div key={index} className={`material ${selectedMaterial == material ? "selected-material" : ""}`}>
                      {language == "ar" ? ar.materials[material.toLowerCase()] || material : material}
                    </div>

                    {index < materials.length - 1 && <div>-</div>}
                  </>
                ))}
              </div>
            </div>
          </div>

          <div className="item">
            <MaterialColorIcon />

            <div className="col">
              <p className="col-text">{t.carPage.availability.interior_color}</p>
              <div className="colors-list">
                {materialColors.map((color, index) => (
                  <div
                    className={`color-circle`}
                    key={color}
                    style={
                      color.toLowerCase() == "white"
                        ? {
                            border: "1px solid #EEEEEE",
                            background: color,
                          }
                        : { background: color }
                    }
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="footer-txt desktop-only">{t.carPage.availability.footer_text}</div>
          <div className="actions">
            <SellerPhoneModal seller={seller}>
              <button className="phone-btn">
                <PhoneIcon />
              </button>
            </SellerPhoneModal>
            <button
              className="loan-btn"
              onClick={() => {
                history.push("/car-finance", {
                  detail: detail,
                });
              }}
            >
              {t.carPage.availability.calculateLoan}
            </button>
            <button disabled={isLoading || quotationSuccess} className="quotation-btn" onClick={handleRequestQuotation}>
              {t.carPage.availability.requestQuotation}
            </button>
          </div>
        </div>
      </StyledCard>
    </>
  );
};

const StyledCard = styled.div`
  width: calc(840 / 1440 * 100vw);
  padding: 0 0 ${desktopSizes._24px} 0;
  background: #ffffff;
  border: ${desktopSizes._1px} solid #e4e6e8;
  border-radius: ${desktopSizes._8px};
  .card-header {
    width: 100%;
    height: 7.708vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${desktopSizes._24px};
    // border-bottom: ${desktopSizes._1px} solid #eeeeee;
    .seller-logo {
      max-width: 37.292vw;
      max-height: 4.444vw;
      object-fit: contain;
    }
    .price-info {
      .price-text {
        font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
        font-style: normal;
        font-weight: ${props => (props.language == "en" ? "400" : "300")};
        font-size: ${desktopSizes._16px};
        line-height: 1.319vw;
        color: #828282;
        @media (min-width: 1440px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .price {
        display: flex;
        align-items: flex-end;
        gap: ${desktopSizes._6px};
        color: #154cd6;
        .price-value {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: ${desktopSizes._28px};
          line-height: ${desktopSizes._34px};
          @media (min-width: 1440px) {
            font-size: 28px;
            line-height: 36px;
          }
        }
        .currency {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: ${desktopSizes._16px};
          line-height: ${desktopSizes._32px};
          @media (min-width: 1440px) {
            font-size: 16px;
            line-height: 32px;
          }
        }
      }
      .info {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: ${desktopSizes._14px};
        line-height: ${desktopSizes._21px};
        color: #000000;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  .selections {
    width: 100%;
    padding: 0 ${desktopSizes._24px} ${desktopSizes._24px} ${desktopSizes._24px};
    border-bottom: ${desktopSizes._1px} solid #eeeeee;
    margin-top: 0;
    margin-bottom: ${desktopSizes._24px};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${props => props.language == "ar" && "row-reverse"};
    .item {
      display: flex;
      align-items: center;
      gap: ${desktopSizes._16px};

      svg {
        width: 3.333vw;
        width: 3.333vw;

        @media (min-width: 1440px) {
          width: 48px;
          height: 48px;
        }
      }

      .col {
        display: flex;
        flex-direction: column;
        gap: ${desktopSizes._6px};
        .col-text {
          font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
          font-style: normal;
          font-weight: ${props => (props.language == "en" ? "600" : "500")};
          font-size: ${desktopSizes._14px};
          line-height: ${desktopSizes._21px};
          color: #0d0a19;
          @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 21px;
          }
        }
        .colors-list {
          display: flex;
          align-items: center;
          gap: ${desktopSizes._16px};

          .color-circle {
            width: ${desktopSizes._18px};
            height: ${desktopSizes._18px};
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media (min-width: 1440px) {
              width: 18px;
              height: 18px;
            }
            // svg {
            //   width: 0.486vw;
            //   height: 0.347vw;
            // }
          }

          // .selected-color {
          //   border: 0.069vw solid #333333;
          // }
        }

        .materials-list {
          display: flex;
          gap: 0.278vw;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: #9a9ea7;
          @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 21px;
          }
          .material {
            border: none;
            background: none;
            outline: none;
            // cursor: pointer;
            font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
            font-style: normal;
            font-weight: ${props => (props.language == "en" ? "400" : "500")};
            font-size: 0.972vw;
            line-height: 1.458vw;
            color: #9a9ea7;
            @media (min-width: 1440px) {
              font-size: 14px;
              line-height: 21px;
            }
          }
          .selected-material {
            color: #154cd6;
            font-weight: 600;
          }
        }
      }
    }
  }
  .card-footer {
    display: flex;
    align-items: center;
    gap: ${desktopSizes._32px};
    padding: 0 ${desktopSizes._24px};
    .footer-txt {
      width: 22.153vw;
      font-family: ${props => (props.language == "en" ? "Poppins" : "GE-SS-UlrtraLight")};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "400" : "300")};
      font-size: ${desktopSizes._14px};
      line-height: ${desktopSizes._21px};
      color: #0d0a19;

      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      gap: ${desktopSizes._16px};
      button {
        cursor: pointer;
        width: 11.528vw;
        height: 2.847vw;
        border-radius: ${desktopSizes._4px};
        font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
        font-style: normal;
        font-weight: ${props => (props.language == "en" ? "600" : "500")};
        font-size: ${desktopSizes._14px};
        line-height: ${desktopSizes._21px};

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
      .quotation-btn {
        background: #154cd6;
        color: #ffffff;
      }
      .quotation-btn:disabled {
        background: #154cd6a1;
        cursor: not-allowed;
      }
      .loan-btn {
        border: 0.104vw solid #154cd6;
        background: none;
        color: #154cd6;
      }
      .phone-btn {
        background: #154cd6;
        color: #ffffff;
        width: 3.333vw;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 2vw;
          height: 2vw;
          @media (max-width: 768px) {
            width: 50px;
            height: 50px;
          }
        }
        @media (min-width: 1440px) {
          width: 40px;
          height: 40px;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: ${mobileSizes._12px};
    width: 100%;
    padding: 0 0 ${mobileSizes._24px} 0;
    border: ${mobileSizes._1px} solid #e4e6e8;
    border-radius: ${mobileSizes._8px};
    .card-header {
      padding: ${mobileSizes._16px};
      border-bottom: ${mobileSizes._1px} solid #eeeeee;
      height: 24vw;
      .seller-logo {
        max-width: 44.533vw;
        max-height: 15.467vw;
        object-fit: contain;
      }
      .price-info {
        .price-text {
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._19px};
        }
        .price {
          gap: ${mobileSizes._6px};
          align-items: flex-end;
          .price-value {
            font-weight: 600;
            font-size: ${mobileSizes._20px};
            line-height: ${mobileSizes._32px};
          }
          .currency {
            font-weight: 600;
            font-size: ${mobileSizes._14px};
            line-height: ${mobileSizes._27px};
          }
        }
        .info {
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
          color: #000000;
        }
      }
    }
    .selections {
      width: 100%;
      padding: 0 ${mobileSizes._16px} 0 ${mobileSizes._16px};
      border-bottom: 0;
      margin-top: ${mobileSizes._24px};
      margin-bottom: ${mobileSizes._24px};
      display: flex;
      align-items: initial;
      flex-direction: column;
      gap: ${mobileSizes._24px};
      .item {
        display: flex;
        align-items: center;
        gap: ${mobileSizes._16px};

        svg {
          width: 12.8vw;
          width: 12.8vw;
        }

        .col {
          display: flex;
          flex-direction: column;
          gap: ${mobileSizes._5px};
          .col-text {
            font-size: ${mobileSizes._14px};
            line-height: ${mobileSizes._21px};
          }
          .colors-list {
            display: flex;
            align-items: center;
            gap: ${mobileSizes._16px};

            .color-circle {
              width: ${mobileSizes._19px};
              height: ${mobileSizes._19px};
            }
          }

          .materials-list {
            gap: ${mobileSizes._4px};
            font-family: Inter;
            font-size: ${mobileSizes._14px};
            line-height: ${mobileSizes._21px};
            .material {
              font-family: Inter;
              font-size: ${mobileSizes._14px};
              line-height: ${mobileSizes._21px};
            }
            .selected-material {
              color: #154cd6;
            }
          }
        }
      }
    }
    .card-footer {
      padding: 0 ${mobileSizes._16px};
      .actions {
        display: flex;
        align-items: initial;
        flex-direction: column;
        gap: ${mobileSizes._16px};
        button {
          width: 82.933vw;
          height: 12.267vw;
          border-radius: ${mobileSizes._4px};
          font-size: ${mobileSizes._16px};
          line-height: ${mobileSizes._21px};
        }
        .quotation-btn {
          background: #154cd6;
          color: #ffffff;
          order: 1;
        }
        .loan-btn {
          border: 0.4vw solid #154cd6;
          background: none;
          color: #154cd6;
          order: 2;
        }
      }
    }
  }
`;
export default AvailabilityCard;
