import React from "react";
import styled from "styled-components";
import sellerHeroImage from "../../images/seller-hero.jpeg";
import Skeleton from "./Skeleton";

const ContentSkeleton = () => {
  return (
    <SkeletonContainer>
      <Skeleton width="80px" height="80px" />
      <Skeleton width="200px" height="40px" />
      <Skeleton width="350px" height="200px" />
    </SkeletonContainer>
  );
};

const Hero = ({ isLoading, seller, language, t }) => {
  return (
    <HeroSection language={language} dir={language === "ar" ? "rtl" : "ltr"}>
      <BackgroundImage language={language} />
      {!isLoading ? (
        <ContentArea>
          <Logo>
            <img src={seller?.logo} alt="seller logo" />
          </Logo>
          <CompanyName>{language === "ar" ? seller?.name_ar : seller?.name}</CompanyName>
          <Description>{t.seller.companyDescription}</Description>
        </ContentArea>
      ) : (
        <ContentSkeleton />
      )}
    </HeroSection>
  );
};

export default Hero;

const HeroSection = styled.div`
  font-family: ${props => (props.language === "en" ? "Poppins" : "Almarai")};
  & * {
    font-family: inherit;
  }
  position: relative;
  height: 80vh;
  background-position: center;
  display: flex;
  width: 100%;
  color: #fff;
`;

const BackgroundImage = styled.div`
  transform: ${props => (props.language === "ar" ? "scaleX(-1)" : "scaleX(1)")};
  background-image: url(${sellerHeroImage});
  background-size: cover;
  background-position: center;
  position: absolute;
  inset: 0;
`;

const ContentArea = styled.div`
  z-index: 10;
  padding-inline-start: calc(96 / 1440 * 100vw);
  padding-top: calc(96 / 1440 * 100vw);
  @media (max-width: 768px) {
    padding: 4vw;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: 40px;
  gap: calc(16 / 1440 * 100vw);
  font-weight: bold;
  color: #00308f;
  margin-bottom: calc(16 / 1440 * 100vw);
  img {
    height: 70px;
    object-fit: cover;
  }
`;

const CompanyName = styled.h1`
  font-size: calc(32 / 1440 * 100vw);
  color: #333;
  margin-bottom: calc(8 / 1440 * 100vw);
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: calc(24 / 375 * 100vw);
  }
`;

const Description = styled.p`
  font-size: calc(20 / 1440 * 100vw);
  color: #666;
  line-height: 1.5;
  width: 50%;
  line-height: 2;
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    font-size: calc(16 / 375 * 100vw);
  }
`;

const SkeletonContainer = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: calc(16 / 1440 * 100vw);
  padding: 4vw 10vw;
  @media (max-width: 768px) {
    padding: 4vw;
  }
`;
