import styled from "styled-components";
import useTranslation from "../hooks/useTranslation";
import SellerJoinForm from "../Components/Seller/SellerJoinForm";

const SellerJoin = () => {
  const { t, language } = useTranslation();
  return (
    <Container language={language} dir={language === "en" ? "ltr" : "rtl"}>
      <Title>{t.sellerJoin.title}</Title>
      <SellerJoinForm />
    </Container>
  );
};

const Container = styled.div`
  width: calc(1248 / 1440 * 100vw);
  margin: 0 auto;
  margin-bottom: calc((60 / 1440) * 100vw);
  * {
    font-family: ${props => (props.language === "en" ? "Poppins" : "Almarai")};
  }
`;

const Title = styled.h1`
  padding-bottom: calc((60 / 1440) * 100vw);
  padding-top: calc((60 / 1440) * 100vw);
  font-size: calc((30 / 1440) * 100vw);

  @media (max-width: 768px) {
    font-size: calc((24 / 375) * 100vw);
  }
`;

export default SellerJoin;
