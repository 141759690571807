import en from "./en";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  showMore: "عرض المزيد",
  showLess: "عرض الأقل",
  required: "يجب إدخال",
  invalid: " غير صحيح",
  passwords_dont_match: "كلمة المرور غير متطابقة",
  next: "التالي",
  calculate: "احسب",
  taxExcluded: "الضريبة مستثناة",
  totalPrice: "السعر الإجمالي",
  mobileNumber: "رقم الهاتف",
  recommended: "الموصى بها",
  completed: "إكتمال",
  years: "سنوات",
  price: "السعر",
  colors: {
    white: "أبيض",
    black: "أسود",
    grey: "رمادي",
    gray: "رمادي",
    brown: "بني",
    yellow: "أصفر",
    red: "أحمر",
    blue: "أزرق",
    orange: "برتقالي",
    green: "أخضر",
    pink: "وردي",
    gold: "ذهبي",
    silver: "فضي",
  },
  materials: {
    leather: "الجلد",
    nylon: "نايلون",
    vinyl: "فينيل",
    polyester: "بوليستر",
    synthetic: "اصطناعي",
  },
  body_types: {
    sedan: "سيدان",
    hatchback: "هاتشباك",
    suv: "سوف",
    convertible: "كونفرتبل",
    "pickup truck": " البيك أب",
    van: "الفان",
    coupe: "كوبيه",
    station: "ستيشن ",
    other: "اخري",
  },
  transmissions: {
    Automatic: "أوتوماتيكي",
    Manual: "يدوي",
    "4x4": "الدفع الرباعي",
    Hyprid: "هايبريد",
    Electric: "كهربائي",
    other: "اخري",
  },
  fuel_types: {
    Petrol: "البترول",
    Gasoline: "البنزين",
    Diesel: "الديزل",
    "Natural Gas": "الغاز الطبيعي",
    Electric: "الكهربائي",
  },
  sar: "ر.س",
  header: {
    lang: "العربية",
    signup: "إنشاء حساب",
    logout: "خروج",
    calcYouLoan: "حاسبة قرض السيارة",
    buildYourCar: "إبني سيارتك",
  },
  navBar: {
    CarsForSale: "احصل على عرض أسعار",
    buildCar: "إبني سيارتك",
    carFinance: "تمويل السيارات",
    reviews: "عرض السيارات",
    carComparison: "مقارنة السيارات",
  },

  intro: {
    title: "ابحث عن التالي سيارة رهيبة ...",
    title_l1: "ابحث عن التالي",
    title_l2: "سيارة رهيبة ...",
    text: "ابحث عن سيارات السيدان والهاتشباك وسيارات الدفع الرباعي المفضلة لديك من + 54 ماركة عالمية",
    text_l1: "ابحث عن سيارات السيدان والهاتشباك وسيارات",
    text_l2: "الدفع الرباعي المفضلة لديك من + 54 ماركة عالمية",
    search: {
      brand: "العلامة التجارية",
      model: "الموديل",
      type: "النوع",
      price: "السعر",
      search: "بحث",
    },
  },
  brands: {
    title: "استكشف السيارات من أفضل العلامات التجارية",
    mobileTitle: "استكشف السيارات من أفضل العلامات التجارية",
    carsAvalibale: "سيارة متاحة",
    showMore: "عرض المزيد",
  },
  buildCar: {
    title: "إبني سيارتك",
    text: "تخصيص سيارتك عن طريق اختيار العلامة التجارية المفضلة لديك ، نموذج ، نوع ، شكل الجسم ، اللون ، تقليم مخصص ، انتقال ، سعة المحرك ونوع الوقود",
    button: "قم ببناء سيارتك",
  },
  shapes: {
    title: "إستكشف شكل سيارتك",
  },
  calculator: {
    title: "حاسبة قرض السيارة",
    brand: "العلامة التجارية",
    model: "الموديل",
    year: "سنة الانتاج",
    color: "لون الجسم",
    type: "نوع العربية",
    button: "إحسب القرض",
  },
  carCard: {
    price: "السعر",
    seller: "بائع",
  },
  sellers: {
    topSellers: "الأكثر مبيعًا",
  },
  news: {
    latestNews: "آخر الأخبار",
    relatedNews: "اخبار ذات صلة",
  },
  reviews: {
    carvinuReviews: " إستعراض Carvinu",
    review: "مراجعة",
    readMore: " إقرأ المزيد",
    readless: " إقرأ أقل",
  },
  footer: {
    text: "سلسلة إف تلتقط تاج الشاحنة الأكثر مبيعا في أمريكا للعام 46 على التوالي ، فورد تسجل مبيعات قياسية للسيارات الكهربائية ، وتسخر رقم 2 من شركة صناعة السيارات الكهربائية ، وتوسع فورد إجمالي السوق",
    privacyPolicy: "السياسات",
    termUse: "الشروط و الأحكام",
    aboutUs: "معلومات عنا",
    ourCompany: "شركتنا",
    contactUs: "اتصل بنا",
    usefulLinks: "روابط مفيدة",
    buyCar: "شراء سيارة",
    buildYourCar: "ابني سيارتك",
    carFinance: "تمويل السيارة",
    getAQuote: "احصل على عرض سعر",
    carReviews: "تقييمات السيارات",
    carComparison: "مقارنة السيارات",
    help: "المساعدة",
    terms: "الشروط",
    privacy: "الخصوصية",
    customerFees: "رسوم العملاء",
    security: "الأمان",
    joinUsAsADealer: "انضم إلينا كتاجر",
    registerNow: "سجل الآن",
  },
  carsFilter: {
    detailedSearch: "بحث مفصل",
    carBrand: "العلامة التجاريه للسيارة",
    searchforBrand: "البحث",
    viewAllBrands: "عرض الكل",
    viewLess: "عرض أقل",
    price: "السعر",
    from: "من",
    to: "إلى",
    transmission: "النقل",
    bodyShape: "الشكل",
    fuelType: "نوع الوقود",
    engineCapacity: "سعة المحرك",
    viewResult: "عرض النتيجة",
    clear: "تنقية",
    filter: "تصفية",
  },
  carFinanceSteps: {
    carDetails: "تفاصيل السيارة",
    personalInfo: "معلومات شخصية",
    workInfo: "معلومات العمل",
    financialInfo: "معلومات مالية",
    loansInfo: "معلومات القروض",
  },

  carFinance: {
    carDetails: {
      brand: "العلامة التجارية",
      model: "الموديل",
      year: "سنة الانتاج",
      color: "لون الجسم",
      interiorMaterial: "المواد الداخلية",
      materialColor: "لون المواد",
      seller: "البائع",
    },
    personalInfo: {
      fname: "الإسم الأول",
      lname: "الإسم الأخير",
      email: "البريد الإلكتروني",
      mobileNumber: "رقم الهاتف",
      nationality: "الجنسية",
      nationalID: "الرقم القومي",
    },
    workInfo: {
      workType: "نوع العمل",
      workDuration: "مدة العمل",
      salary: "الراتب",
      payrollBank: "بنك الرواتب",
    },
    financialInfo: {
      food_expense: "نفقات الغذاء",
      wages_for_domestics: "أجور الخادمات",
      housing_rent: "السكن (الإيجار)",
      education_expense: "نفقات التعليم",
      health_care_expense: "نفقات الرعاية الصحية",
      transport_and_communication_expense: "مصاريف النقل والاتصالات",
      insurance_expense: "مصاريف التأمين",
      other_expense: "مصروفات أخرى",
    },
    loanInfo: {
      homeFinance: "التمويل العقاري",
      personalFinance: "التمويل الشخصي",
      SocialBank_others: "البنك الاجتماعي وغيرها",
      autoLease: "تأجير السيارات",
      creditCards: "بطاقات الائتمان",
    },
    result: {
      contact_alert: "سوف نتصل بك قريبا",
      banksOffers: "عروض البنوك",
      plans: "خطط",
      installment: "تقسيط",
      year: "سنة",
      downPayment: "دفعة أولى",
      loanAmount: "مبلغ القرض",
      adminFees: "الرسوم الإدارية",
      tenure: "الحيازة",
      lastBatch: "الدفعة الأخيرة",
      bookNow: "احجز الآن",
      loginToBook: "سجل الدخول و احجز الآن ",
      MonthlyInstallment: "القسط الشهري",
    },
    finicialInfoModal: {
      calculateLoan: "احسب القسط",
      savedInfo: "المعلومات المحفوظة مسبقا",
      savedInfoText: "احسب القسط باستخدام المعلومات المحفوظة مسبقا",
      newInfo: "معلومات جديدة",
      newInfoText: "استخدم حاسبة الاقساط بواسطة معلومات جديدة",
    },
  },
  buildCarPage: {
    step_title: "اختر ما تفضله",
    brand: "العلامة التجارية",
    engineCapacity: "سعة المحرك",
    carTrim: "تقليم السيارة",
    gearBox: "علبة التروس",
    paymentMethod: "طريقة الدفع",
    sectionCard: {
      title_line1: "إبني سيارتك",
      title_line2: "الخاصة بك",
    },
  },
  carPage: {
    price: {
      starting: "يبدأ من",
      text: "سعر السيارة قابل للتغيير طبقاً لمفضلاتك الشخصية ",
    },
    calculator: {
      header: "حاسبة الأقساط",
      text: "صمم خطة تقسيط خاصة بك",
    },
    specifications: {
      specifications: "المواصفات",
      body_type: "نوع الجسم",
      body_volume: "حجم الجسم",
      tank: "دبابة",
      engine: "المحرك",
      seats: "مقاعد",
      liters: "لتر",
      cylinders: " اسطوانات",
    },
    warranty: {
      title: "تغطية الضمان",
      warranty: "الضمان",
      distance: "المسافة",
      km: "كم",
      years: "سنوات",
    },
    features: {
      title: "الميزات",
      fuel_type: "نوع الوقود",
      trim: "قطع",
      internal_toolkit: "مجموعة الأدوات الداخلية",
      engine: "المحرك",
    },
    availability: {
      title: "تجد سيارتك المفضلة في",
      car_customization: "تخصيص السيارات",
      body_color: "لون السيارة",
      interior_material: "الخامات الداخلية",
      interior_color: "لون الخامات الداخلية",
      footer_text: "يمكنك طلب عرض أسعار لحجز هذه السيارة أو البدء في حساب قرضك",
      calculateLoan: "حاسبة الأقساط",
      requestQuotation: "طلب عرض سعر",
    },
    quotationSuccess: {
      title: "تم ارسال الطلب بنجاح",
    },
    fullname: "الاسم الكامل",
    mobile: "رقم الهاتف",
    createPassword: "أنشئ كلمة مرور",
    password: "كلمة المرور",
    LoginandRequest: "تسجيل الدخول و طلب عرض سعر",
    registerModal: {
      title: "يجب أن يكون لديك حساب",
      text: "الرجاء إدخال المعلومات أدناه حتى نتمكن من الرد عليك بعرض الأسعار المطلوب",
    },
    loginModal: {
      title: "يجب عليك تسجيل الدخول",
      text: "الرجاء إدخال المعلومات أدناه حتى نتمكن من الرد عليك بعرض الأسعار المطلوب",
    },
    quotationModal: {
      title: "تم استلام الطلب بنجاح",
      text: "أحد أعضاء فريقنا سيتواصل معك قريبًا",
      Okay: "حسناً",
      useLoanCalc: " استخدم حاسبة الأقساط ",
    },
  },
  carReviewsPage: {
    title: "تقييمات السيارات",
    latestReviews: "أحدث التقييمات",
    allReviews: "كل التقييمات",
  },
  authPages: {
    haveAcount: "هل لديك حساب؟",
    dontHaveAccount: "ليس لديك حساب؟",
    sign_up: "إنشاء حساب",
    sign_in: "تسجيل الدخول",
    login: "تسجيل الدخول",
    forget_password: "هل نسيت كلمة السر؟",
    email: "البريد الإلكتروني",
    fname: "الإسم الأول",
    lname: "الإسم الأخير",
    mobile: "رقم الهاتف",
    password: "كلمة المرور",
    confirm_password: "تأكيد كلمة المرور",
    new_password: "كلمة المرور الجديدة",
    confirm_new_password: "تأكيد كلمة المرور الجديدة",
    email_address: "البريد الإلكتروني",
    or: "أو",
    skip: "تخطي",
    card: {
      text_line1: "طريقة جديدة",
      text_line2: "لبيع وشراء السيارات",
    },
    sign_up_page: {
      header: "أخبرنا عن نفسك",
      text: "أدخل التفاصيل الخاصة بك لإنشاء حسابك الخاص",
      agreeTerms: "أوافق على الشروط والأحكام",
      continue: "استمر",
      success_title: "شكرًا لك!",
      success_txt_p1: " لقد أرسلنا بريدًا إلكترونيًا إلى",
      success_txt_p2: "انقر علي  رابط  التأكيد في البريد الإلكتروني للتحقق من حسابك",
    },
    login_page: {
      header: "تسجيل الدخول إلى حسابك",
      text: "أدخل التفاصيل الخاصة بك للمتابعة",
      signup_google: "التسجيل باستخدام حساب جوجل",
      signup_fb: " التسجيل باستخدام حساب فيسبوك ",
      signup_tw: "التسجيل باستخدام حساب تويتر",
    },
    forget_password_page: {
      recovery: {
        title_line1: "هل نسيت كلمة السر؟",
        title_line2: " أدخل التفاصيل الخاصة بك لاستعادة الحساب",
        text: "أدخل بياناتك للمتابعة",
        recover: "استعادة",
      },
      verify: {
        title_l1: "تحقق من حسابك",
        title_l2: "أدخل الأربعة أرقام المرسلة إلى هاتفك",
        text: "أدخل بياناتك للمتابعة",
        verify: "تحقق",
      },
      reset_password: {
        title: "اعد ضبط كلمة السر",
        text: "أدخل كلمة المرور الجديدة الخاصة بك وتأكيدها",
        setPassword: "ضبط كلمة السر",
      },
      success_reset_password: {
        title: "تم تغيير كلمة المرور بنجاح",
        text: "لقد تم تحديث كلمة المرور الخاصة بك بنجاح",
      },
    },
  },
  account: {
    accInfo: "معلومات الحساب",
    accInfoDesc: "الملف الشخصي ، الصورة والاسم",
    loginDetails: "تفاصيل الدخول",
    loginDetailsDesc: "كلمة المرور والأمان",
    expenses: "المصاريف",
    expensesDesc: "معلومات التعاقدات",
    fullName: "الاسم  الكامل",
    fullNameAr: "الاسم باللغة العربية",
    gender: "النوع",
    birthDate: "تاريخ الميلاد",
    enterBirthDate: "ادخل تاريخ الميلاد",
    email: "البريد الإلكتروني",
    enterEmail: "ادخل البريد الإلكتروني",
    address: "العنوان",
    enterAddress: "ادخل العنوان",
    nationalId: "الرقم القومي",
    enterNationalId: "ادخل الرقم القومي",
    generalInfo: "معلومات عامة",
    generalInfoDesc: "كل شخص حضر اجتماعاً من قبل يمكنه أن يتذكر عادة تبادل بطاقات العمل المألوفة للغاية.",
    deactivateAccount: "طلب وقف الحساب",
    updateAccount: "تحديث الاعدادات",
    workInfo: "معلومات العمل",
    workType: "نوع العمل",
    WorkDuration: "مدة العمل",
    enterYourWorkDuration: "ادخل مدة العمل",
    salary: "الراتب",
    payrollBank: "بنك الرواتب",
    financialInfo: "معلومات مالية",
    foodExpense: "نفقات الطعام",
    enterFoodExpense: "ادخل نفقات الطعام",
    wagesForDomestics: "أجور الخادمات",
    enterWagesForDomestics: "ادخل أجور الخادمات",
    housingRent: "السكن (الإيجار)",
    enterHousingRent: "ادخل السكن (الإيجار)",
    educationExpense: "نفقات التعليم",
    enterEducationExpense: "ادخل نفقات التعليم",
    healthCareExpense: "نفقات الرعاية الصحية",
    enterHealthCareExpense: "ادخل نفقات الرعاية الصحية",
    transportAndCommunicationExpense: "مصاريف النقل والاتصالات",
    enterTransportAndCommunicationExpense: "ادخل مصاريف النقل والاتصالات",
    insuranceExpense: "مصاريف التأمين",
    enterInsuranceExpense: "ادخل مصاريف التأمين",
    otherExpense: "مصروفات أخرى",
    enterOtherExpense: "ادخل مصروفات أخرى",
    homeFinance: "التمويل المنزلي",
    enterHomeFinance: "ادخل التمويل المنزلي",
    personalFinance: "التمويل الشخصي",
    enterPersonalFinance: "ادخل التمويل الشخصي",
    socialBankOthers: "البنك الاجتماعي وغيرها",
    enterSocialBankOthers: "ادخل البنك الاجتماعي وغيرها",
    autoLease: "تأجير السيارات",
    enterAutoLease: "ادخل تأجير السيارات",
    creditCards: "بطاقات الائتمان",
    enterCreditCards: "ادخل بطاقات الائتمان",
    loansInfo: "معلومات القروض",
    PasswordAndQuestions: "كلمة المرور والأسئلة",
    changePassword: "تغيير كلمة المرور",
    currentPassword: "كلمة المرور الحالية",
    enterCurrentPassword: "ادخل كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديدة",
    enterNewPassword: "ادخل كلمة المرور الجديدة",
    confirmPassword: "تأكيد كلمة المرور",
    enterConfirmPassword: "ادخل تأكيد كلمة المرور",
    sendCode: "إرسال الرمز",
    mobileNumber: "رقم الهاتف",
    enterMobileNumber: "ادخل رقم الهاتف",
    invalidMobileNumber: "رقم الهاتف غير صحيح",
    enter4Digits: "أدخل الارقام الأربعة المرسلة الى",
    enterToProceed: "ادخل الكود  للمتابعة",
    contactSupport: "تواصل مع الدعم",
    facingIssues: " إذا كنت تواجه أي مشكلة",
    myAccount: "حسابي",
    accountUpdated: "تم تحديث الحساب بنجاح",
    selectBank: "اختر البنك",
    selectWorkType: "اختر نوع العمل",
    employmentStatus: "حالة العمل",
    selectEmploymentStatus: "اختر حالة العمل",
    government: "حكومي",
    private: "خاص",
    retired: "متقاعد",
    stillWorking: "مازلت اعمل",
    passwordLength: "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل",
    passwordMatch: "كلمة المرور غير متطابقة",
    passwordUpdated: "تم تحديث كلمة المرور بنجاح",
    employee: "موظف",
    area: "المنطقة",
    city: "المدينة",
    enterArea: "ادخل المنطقة",
    enterCity: "ادخل المدينة",
  },
  cancel: "إلغاء",
  edit: "تعديل",
  thisFieldIsRequired: "هذا الحقل مطلوب",
  confirm: "تأكيد",
  select: "-اختر-",
  yes: "نعم",
  no: "لا",
  banks_offers: "عروض البنوك",
  no_offers: "لم يتم العثور على عروض",
  no_offers_description: "يرجى المحاولة بالبحث عن مركبات أخرى أو إدخال معلومات حساب صحيحة.",
  sellerPopup: {
    title: "نحن دائما هنا لمساعدتك",
    workingHours: "ساعات العمل من 9 صباحًا إلى 5 مساءً",
  },
  calculateAgain: "أعد استخدام الحاسبة",
  seller: {
    exploreBrands: "استكشف مجموعة العلامات التجارية",
    showMore: "عرض المزيد",
    CarsAvailable: "سيارات متاحة",
    seller: "البائع",
    companyDescription:
      "تمتلك الشركة خبرة جيدة في مجال بيع وشراء السيارات وقطع غيارها، والمعرفة في هذا المجال كاملة على مستوى منطقة الرياض.",
    CarsWeSell: "السيارات التي نبيعها",
    trusted: "نحن محل ثقة",
    something_to_make_you_love_us_more: "شيء لجعلك تحبنا أكثر",
    premium_support: "دعم مميز",
    available_for_helping_you: "نحن متاحون لمساعدتك، بما في ذلك أيام العطل",
    high_quality_cars: "سيارات عالية الجودة",
    cars_certificated_by_gurus: "سياراتنا معتمدة من خبراء لديهم أكثر من 20 عامًا من الخبرة",
    trusted_by_10_clients: "موثوق من قبل أكثر من 10 عملاء",
    happy_clients_ready_for_our_cars: "لدينا أكثر من 10 آلاف عميل سعيد يحبوننا ومستعدون لسياراتنا",
    free_cancelation: "إلغاء مجاني",
    free_cancelation_description: "لا توجد رسوم إضافية، يمكنك إلغاء حجزك في أي وقت",
    payment_with_many_plans: "الدفع مع العديد من الخطط",
    provide_us_your_details: "فقط قدم لنا تفاصيلك وسنحسب الخطة التي تناسبك",
    and_more_features: "والمزيد من الميزات",
    this_space_is_too_small: "هذا المكان صغير جدًا لنعرض لك كل ما لدينا",
    enoughQuestion: "هل لديك أي أسئلة؟",
    ContactUs: "تواصل معنا",
    noCars: "لا توجد سيارات",
    noPhone: "لا يوجد رقم هاتف",
    trustedSeller: "بائع موثوق",
    sellerDetails: "تفاصيل البائع",
    allCities: "جميع المدن",
    specialFinancingAvailable: "تمويل خاص متاح",
    seeAllSellers: "عرض الكل",
    carSellers: "بائعي السيارات",
  },
  Why: "لماذا",
  startsFrom: "يبدأ من",
  weTrust: "نحن نثق بـ",
  phoneNumber: "رقم الهاتف",
  send: "إرسال",
  comparison: {
    bodyVolume: "حجم الجسم",
    pageTitle: "مقارنة السيارات",
    description:
      "قارن بين المركبات وشاهد كيف تتنافس مع بعضها البعض من حيث التصنيف والأداء والميزات والمواصفات والسلامة والأسعار والمزيد.",
    overview: "نظرة عامة",
    name: "الاسم",
    startingPrice: "السعر الابتدائي",
    performanceFeatures: "مميزات الأداء",
    fuelType: "نوع الوقود",
    engineBatteryCapacity: "سعة المحرك \\ البطارية",
    cylinders: "الاسطوانات",
    transmission: "ناقل الحركة",
    horsepower: "القوة الحصانية",
    gearBox: "صندوق التروس",
    consumption: "استهلاك الوقود",
    driveTrain: "نظام الدفع",
    safety: "السلامة",
    airbags: "الوسائد الهوائية",
    sensors: "المستشعرات",
    safetyOptions: "خيارات السلامة",
    luxury: "الرفاهية",
    roofType: "نوع السقف",
    luxuryOptions: "خيارات الرفاهية",
    convenienceComfort: "الراحة والملاءمة",
    convenienceOptions: "خيارات الراحة",
    entertainmentTechnology: "الترفيه والتكنولوجيا",
    speakers: "السماعات",
    entertainmentOptions: "خيارات الترفيه",
    specifications: "المواصفات",
    wheelSize: "حجم العجلات",
    builtInCamera: "الكاميرا المدمجة",
    lightingType: "نوع الإضاءة",
    maxOutput: "أقصى قوة",
    maxTorque: "أقصى عزم",
    warrantyService: "الضمان والخدمة",
    years: "سنوات",
    km: "كم",

    // خيارات السلامة
    blindSpotSensor: "مستشعر النقطة العمياء",
    alarmSystem: "نظام الإنذار",
    laneDepartureWarning: "تحذير مغادرة المسار",
    tirePressureMonitor: "مراقبة ضغط الإطارات",
    sideAirbags: "وسائد هوائية جانبية",
    rearAirbags: "وسائد هوائية خلفية",
    stabilityControl: "التحكم في الثبات",
    brakeAssist: "مساعد الفرامل",
    frontRearSensors: "مستشعرات أمامية وخلفية",
    collisionAvoidance: "تجنب الاصطدام",
    driverPassengerAirbags: "وسائد هوائية للسائق والركاب",
    drivingModes: "أوضاع القيادة",
    fogLights: "مصابيح الضباب",
    protectionVisors: "واقيات الحماية",
    camera360: "كاميرا 360 درجة",
    seatBeltReminder: "التذكير بحزام الأمان",
    abs: "نظام الفرامل المانع للانغلاق",
    electronicBrakeForceDistribution: "توزيع قوة الفرامل إلكترونياً",

    // خيارات الرفاهية
    cooledSeats: "مقاعد مبردة",
    headUpDisplay: "شاشة عرض أمامية",
    leatherSeats: "مقاعد جلدية",
    premiumWheels: "عجلات فاخرة",
    activeMotionFrontSeats: "مقاعد أمامية متحركة",
    wirelessChargingPad: "لوحة شحن لاسلكي",
    integratedChrome: "كروم مدمج",
    doorCourtesyLamp: "إضاءة ترحيبية للأبواب",
    interiorAmbientLighting: "إضاءة محيطة داخلية",
    display: "شاشة عرض",
    powerSeatsLumbarControl: "تحكم كهربائي بمسند الظهر",

    // خيارات الراحة
    cruiseControl: "مثبت السرعة",
    keylessEntry: "دخول بدون مفتاح",
    startEngineButton: "زر تشغيل المحرك",
    rearCamera: "كاميرا خلفية",
    memorySeating: "ذاكرة المقاعد",
    powerLocks: "أقفال كهربائية",
    powerWindows: "نوافذ كهربائية",
    steeringWheelControls: "تحكم في عجلة القيادة",
    remoteStart: "تشغيل عن بعد",
    powerSideMirrors: "مرايا جانبية كهربائية",
    intelligentCruiseControl: "مثبت سرعة ذكي",
    leatherSteeringWheel: "عجلة قيادة جلدية",
    autoDimmingRearView: "مرآة خلفية ذاتية التعتيم",
    floorMats: "دواسات أرضية",
    multiZoneClimateControl: "تحكم متعدد المناطق بالمناخ",

    // خيارات الترفيه
    navigation: "نظام ملاحة",
    bluetooth: "بلوتوث",
    voiceCommand: "التحكم الصوتي",
    premiumSoundSystem: "نظام صوت فاخر",
    securitySystem: "نظام أمان",
    appleCarplay: "أبل كاربلاي",
    radio: "راديو",
    compareNewCar: "قارن السيارات الجديدة",
    carCustomization: "تخصيص السيارة حسب رغبتك",
    findYourPreferredCarIn: "اعثر على سيارتك المفضلة",
    compareNow: "قارن الآن",
    selectBrand: "اختر العلامة التجارية",
    selectModel: "اختر الموديل",
    addCar: "أضف سيارة",
    selectProductionYear: "اختر سنة الإنتاج",
    selectCar: "اختر السيارة",
    vinNumber: "رقم الهيكل",
    interiorToolKit: "مجموعة الأدوات الداخلية",
    bodyType: "نوع الجسم",
  },
  sellerJoin: {
    title: "انضم إلينا كتاجر",
    increasedVisibility: "زيادة الرؤية",
    enhancedCustomerReach: "تحسين وصول العملاء",
    improvedCustomerEngagement: "تحسين مشاركة العملاء",
    directMarketingOpportunities: "فرص التسويق المباشر",
    interactiveVehicleComparisons: "مقارنات المركبات التفاعلية",
    targetedAdvertisingOptions: "خيارات الإعلان المستهدف",
    twentyFourSevenAvailabilityForCustomers: "التوفر على مدار الساعة للعملاء",
    name: "الاسم",
    enterYourName: "ادخل الاسم",
    email: "البريد الإلكتروني",
    enterYourEmail: "ادخل البريد الإلكتروني",
  },
  car: {
    features: "المميزات",
    fuelType: "نوع الوقود",
    engineCapacity: "سعة المحرك",
    cylinders: "عدد الاسطوانات",
    transmission: "ناقل الحركة",
    horsepower: "القوة الحصانية",
    gearBox: "صندوق التروس",
    consumption: "استهلاك الوقود",
    driveTrain: "نظام الدفع",
    performanceFeatures: "مميزات الأداء",
  },
};
