import styled from "styled-components";

const NavButton = styled.button`
  width: calc(40 / 1440 * 100vw);
  height: calc(40 / 1440 * 100vw);
  border-radius: calc(8 / 1440 * 100vw);
  border: 1px solid #e5e7eb;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: #f9fafb;
    border-color: #d1d5db;
  }
  svg {
    rotate: ${props =>
      props.position === "start"
        ? props.language === "ar"
          ? "0deg"
          : "180deg"
        : props.language === "ar"
        ? "180deg"
        : "0deg"};
    width: calc(16 / 1440 * 100vw);
    height: calc(16 / 1440 * 100vw);

    @media (max-width: 768px) {
      width: calc(16 / 375 * 100vw);
      height: calc(16 / 375 * 100vw);
    }
  }
  @media (max-width: 768px) {
    width: calc(30 / 375 * 100vw);
    height: calc(30 / 375 * 100vw);
    border-radius: calc(8 / 375 * 100vw);
  }
`;

export default NavButton;
