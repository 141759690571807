import { forwardRef } from "react";
import styled from "styled-components";

const StyledFormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc((8 / 1440) * 100vw);
  /* padding: calc((12 / 1440) * 100vw) 0; */
  position: relative;

  &:has(button) {
    display: flex;
    justify-content: flex-end;
    gap: calc((12 / 1440) * 100vw);
  }
  span {
    position: absolute;
    inset-inline-end: calc((8 / 1440) * 100vw);
    top: calc((38 / 1440) * 100vw);
    background-color: white;
    color: #7a7a7a;
  }

  @media (max-width: 768px) {
    gap: calc((8 / 375) * 100vw);
  }
`;

const Label = styled.label`
  font-weight: 600;
  font-size: calc((14 / 1440) * 100vw);
  color: #3b4453;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: calc((14 / 375) * 100vw);
  }
`;

const Error = styled.div`
  font-size: calc((12 / 1440) * 100vw);
  color: red;

  @media (max-width: 768px) {
    font-size: calc((12 / 375) * 100vw);
  }
`;

export function FormRow({ legend, label, error, children }) {
  return (
    <StyledFormRow>
      {label && <Label htmlFor={children.props.id}>{label}</Label>}
      {children}
      {legend && <span className="legend">{legend}</span>}
      {error && <Error>{error}</Error>}
    </StyledFormRow>
  );
}

export const Input = forwardRef(({ type, invalid, ...props }, ref) => {
  return <StyledInput type={type} invalid={invalid} {...props} ref={ref} />;
});

export const StyledInput = styled.input`
  border: none;
  background-color: white;
  border: 1px solid ${props => (props.invalid ? "#ef4444" : "#e2e8f0")};
  &::placeholder {
    color: #afafaf;
    font-size: calc(14 / 1440 * 100vw);
  }
  border-radius: calc((6 / 1440) * 100vw);
  padding: calc((13 / 1440) * 100vw) calc((14 / 1440) * 100vw);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  @media (max-width: 768px) {
    padding: calc((13 / 375) * 100vw) calc((14 / 375) * 100vw);
    font-size: calc((14 / 375) * 100vw);
    border-radius: calc((8 / 375) * 100vw);
    &::placeholder {
      font-size: calc((14 / 375) * 100vw);
    }
  }
`;

export const StyledSelect = styled.select`
  border: none;
  background-color: white;
  border-radius: 6px;
  padding: 0.8rem 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  &:focus {
    outline: none;
    transition: all 0.5s ease;
  }
  &:disabled {
    color: #111;
    font-weight: bold;
  }

  option {
    font-size: 15px;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    font-size: calc((15 / 375) * 100vw);
  }
`;
