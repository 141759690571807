import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { mobileSizes } from "../../util/sizes";
import { range } from "../../util/utilFunctions";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const ProgressStepper = ({ steps, currentStep }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledProgressStepper language={language}>
      {language == "en" ? (
        <div className="percentage desktop-only">
          {(currentStep / steps) * 100}% {t.completed}
        </div>
      ) : (
        <div className="percentage desktop-only">
          {t.completed}
          &nbsp;
          <span> %{(currentStep / steps) * 100} </span>
        </div>
      )}

      <div className="steps">
        {range(steps).map((step, i) => (
          <div className={`step ${currentStep > i ? "completed" : ""}`}></div>
        ))}
      </div>
    </StyledProgressStepper>
  );
};

const StyledProgressStepper = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  width: 38.403vw;
  .percentage {
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
    font-style: normal;
    font-weight: 500;
    font-size: 0.972vw;
    line-height: 1.458vw;
    color: #0d0a19;
    margin-bottom: 0.556vw;
    width: 100%;
    display: flex;
    justify-content: end;
    span {
      font-family: Poppins;
    }
    @media (min-width: 1440px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .steps {
    display: flex;
    align-items: center;
    .step {
      height: 0.347vw;
      background: rgba(13, 10, 25, 0.1);
      border-radius: 2.778vw;
    }
    .completed {
      background: #154cd6;
    }
  }
  @media (max-width: 768px) {
    width: 91.467vw;
    .steps {
      .step {
        height: ${mobileSizes._4px};
        border-radius: ${mobileSizes._5px};
      }
    }
  }
`;
export default ProgressStepper;
