import * as React from "react";
import "./modal.css";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import styled from "styled-components";

export default function ContentModal({ next, calculate }) {
  const [value, setValue] = React.useState("");
  const language = useSelector((state) => state.language);
  const useStyles = makeStyles({
    root: {
      "&.checked": {
        backgroundColor: "#f0f0f0", // Change to your desired background color
        borderColor: "#154CD6", // Change to your desired border color
      },
    },
  });

  const t = language === "en" ? en : ar;
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleNextClick = () => {

    if (value === "new") {
      next()
      
    }else{
      calculate()
    }
    
  };

  return (
    <StyledContainer language={language}>
      <FormControl>
        <h4 id="calculate-title">{t.carFinance.finicialInfoModal.calculateLoan}</h4>
        <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          defaultValue="top"
          onChange={handleChange}
          value={value}
        >
          <div className="radio-group">
            <FormControlLabel
              value="saved"
              control={<Radio />}
              label={
                <div className="main-radio-info">
                  <span className="radio-title">{t.carFinance.finicialInfoModal.savedInfo}</span>
                  <span className="radio-text">{t.carFinance.finicialInfoModal.savedInfoText}</span>
                </div>
              }
              labelPlacement="start"
              className={`${classes.root} ${value === "saved" ? "checked" : ""}`}
            />
            <FormControlLabel
              value="new"
              control={<Radio />}
              label={
                <div className="main-radio-info">
                  <span className="radio-title">{t.carFinance.finicialInfoModal.newInfo}</span>
                  <span className="radio-text">{t.carFinance.finicialInfoModal.newInfoText}</span>
                </div>
              }
              labelPlacement="start"
              className={`${classes.root} ${value === "new" ? "checked" : ""}`}
            />
          </div>
        </RadioGroup>
        <Button variant="contained" color="primary" onClick={handleNextClick} disabled={!value} className="next-button">
          {t.next}
        </Button>
      </FormControl>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  * {
    font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
  }
  .next-button {
    margin-top: 16px;
  }
`;
