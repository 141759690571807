import React, { useState } from "react";
import styled from "styled-components";
import SelectDropdown from "../MyAccount/SelectDropdown";
import { useGetBrands, useGetCars, useGetModels } from "../../hooks/CarHooks";
import useTranslation from "../../hooks/useTranslation";
import { PlusIcon, XIcon } from "../assets/Icons";

function SelectionCard({ car, setCar }) {
  const { t, language } = useTranslation();
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCar, setSelectedCar] = useState(null);

  const { brands, isLoadingBrands, getBrands } = useGetBrands();
  const { models, isLoadingModels, getModels } = useGetModels({ brand: selectedBrand });
  const { cars, isLoadingCars, getCars } = useGetCars({ car__model: selectedModel, car__year: selectedYear });

  const productionYearOptions = Array.from({ length: new Date().getFullYear() - 1950 + 2 }, (_, i) => {
    const year = new Date().getFullYear() + 1 - i;
    return { value: year, label: year };
  });

  const brandsOptions =
    brands?.results?.map(brand => ({
      value: brand.id,
      label: language === "en" ? brand.name : brand.name_ar || brand.name,
    })) || [];

  const modelsOptions =
    models?.results?.map(model => ({
      value: model.id,
      label: language === "en" ? model.name : model.name_ar || model.name,
    })) || [];

  const carsOptions =
    cars?.results?.map(car => ({
      value: car.car.id,
      label: language === "en" ? car.car.name : car.car.name_ar || car.car.name,
    })) || [];

  const handleRemove = () => {
    setSelectedBrand(null);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedCar(null);
    setCar(null);
  };

  console.log(selectedBrand, selectedModel, selectedCar);

  return (
    <CardContainer>
      {car && (
        <Icon onClick={handleRemove}>
          <XIcon />
        </Icon>
      )}
      <div className="placeholder">
        {!car ? (
          <>
            <AddCircle>
              <PlusIcon />
            </AddCircle>
            <AddText>Add car</AddText>
          </>
        ) : (
          <CarImage src={car.car.meta_data.images.image1 || "/car-placeholder.jpg"} alt="car" />
        )}
      </div>
      <div className="container">
        <SelectDropdown
          isCompare
          onTrigger={getBrands}
          isLoading={isLoadingBrands}
          value={selectedBrand}
          onChange={setSelectedBrand}
          placeholder={t.comparison.selectBrand}
          options={brandsOptions}
        />
        <SelectDropdown
          isCompare
          onTrigger={selectedBrand && getModels}
          isLoading={isLoadingModels}
          value={selectedModel}
          onChange={setSelectedModel}
          placeholder={t.comparison.selectModel}
          options={modelsOptions}
        />

        <SelectDropdown
          isCompare
          value={selectedYear}
          onChange={setSelectedYear}
          placeholder={t.comparison.selectProductionYear}
          options={productionYearOptions}
        />

        <SelectDropdown
          isCompare
          onTrigger={selectedModel && selectedYear && getCars}
          isLoading={isLoadingCars}
          value={selectedCar}
          onChange={value => {
            setSelectedCar(value);
            const car = cars?.results.find(car => car.car.id === value);
            setCar(car);
          }}
          placeholder={t.comparison.selectCar}
          options={carsOptions}
        />
      </div>
    </CardContainer>
  );
}

export default SelectionCard;

const CardContainer = styled.div`
  width: calc(400 / 1440 * 100vw);
  position: relative;
  border: 2px dashed #e0e0e0;
  border-radius: calc(8 / 1440 * 100vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  .placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(150 / 1440 * 100vw);
  }

  .container {
    width: 100%;
    border: 2px solid #e0e0e0;
    width: 90%;
    margin: calc(18 / 1440 * 100vw);
    border-radius: calc(8 / 1440 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 768px) {
    width: calc(160 / 375 * 100vw);
    margin-bottom: calc(16 / 375 * 100vw);
    border-radius: 8px;

    .container {
      margin: calc(12 / 375 * 100vw);
    }

    .placeholder {
      height: calc(90 / 375 * 100vw);
    }
  }
`;

const AddCircle = styled.div`
  width: calc(72 / 1440 * 100vw);
  height: calc(72 / 1440 * 100vw);
  border: 2px dashed #e0e0e0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(8 / 1440 * 100vw);
  color: #999;
  margin-top: calc(30 / 1440 * 100vw);

  svg {
    fill: #999;
    stroke: #999;
  }

  @media (max-width: 768px) {
    width: calc(48 / 375 * 100vw);
    height: calc(48 / 375 * 100vw);
    margin-bottom: calc(8 / 375 * 100vw);
    margin-top: calc(20 / 375 * 100vw);
  }
`;

const AddText = styled.span`
  color: #999;
  font-size: calc(16 / 1440 * 100vw);
  margin-bottom: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(12 / 375 * 100vw);
  }
`;

const CarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Icon = styled.button`
  position: absolute;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: calc(8 / 1440 * 100vw);
  svg {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding: calc(8 / 375 * 100vw);
  }
`;
