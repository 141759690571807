import React, { useState } from "react";
import styled from "styled-components";
import ComparisonTable from "../Components/CarComparsion/ComparisonTable";
import useTranslation from "../hooks/useTranslation";
import SelectionCard from "../Components/CarComparsion/selectionCard";
import SellersListing from "../Components/Home/SellerSection";

const CarComparison = () => {
  const { t, language } = useTranslation();
  const [car1, setCar1] = useState(null);
  const [car2, setCar2] = useState(null);
  const [showTable, setShowTable] = useState(false);

  console.log(car1, car2);

  return (
    <Container language={language} dir={language === "ar" ? "rtl" : "ltr"}>
      <Header>{t.comparison?.pageTitle}</Header>
      <Subtitle>{t.comparison.description}</Subtitle>

      <ComparisonSection>
        <FeatureList>
          {/* <h2>Compare New Cars</h2> */}
          <h2>{t.comparison.compareNewCar}</h2>
          <Feature>{t.comparison.performanceFeatures}</Feature>
          <Feature>{t.comparison.carCustomization}</Feature>
          <Feature>{t.comparison.findYourPreferredCarIn}</Feature>
        </FeatureList>

        <div className="selectionCard">
          <CarColumn>
            <SelectionCard car={car1} setCar={setCar1} />
          </CarColumn>

          <CarColumn>
            <SelectionCard car={car2} setCar={setCar2} />
          </CarColumn>
        </div>
      </ComparisonSection>

      <CompareButton disabled={!car1 || !car2} onClick={() => setShowTable(true)}>
        {t.comparison.compareNow}
      </CompareButton>
      {showTable && <ComparisonTable car1={car1} car2={car2} />}
      <SellersListing />
    </Container>
  );
};

export default CarComparison;

const Container = styled.div`
  * {
    font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
  }
  width: calc(1248 / 1440 * 100vw);
  margin: 0 auto;
  padding-top: calc(30 / 1440 * 100vw);
  padding-bottom: calc(30 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding-top: calc(16 / 375 * 100vw);
    padding-bottom: calc(16 / 375 * 100vw);
    width: 100%;
  }
`;

const Header = styled.h1`
  text-align: center;
  font-size: calc(30 / 1440 * 100vw);
  margin-bottom: calc(8 / 1440 * 100vw);

  @media (max-width: 768px) {
    text-align: start;
    font-size: calc(16 / 375 * 100vw);
    margin: 0 calc(16 / 375 * 100vw);
    font-weight: 700;
  }
`;

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  font-size: calc(16 / 1440 * 100vw);
  margin-bottom: calc(48 / 1440 * 100vw);

  @media (max-width: 768px) {
    text-align: start;
    font-size: calc(14 / 375 * 100vw);
    line-height: calc(20 / 375 * 100vw);
    margin: 0 calc(16 / 375 * 100vw) calc(24 / 375 * 100vw);
  }
`;

const ComparisonSection = styled.div`
  display: grid;
  grid-template-columns: calc(300 / 1440 * 100vw) 1fr;
  gap: calc(32 / 1440 * 100vw);
  margin-bottom: calc(48 / 1440 * 100vw);

  .selectionCard {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 calc(16 / 375 * 100vw);
  }
`;

const FeatureList = styled.div`
  padding-top: calc(32 / 1440 * 100vw);

  h2 {
    margin-bottom: calc(16 / 1440 * 100vw);
    font-size: calc(24 / 1440 * 100vw);
    color: #263959;
  }

  @media (max-width: 768px) {
    padding-top: calc(16 / 375 * 100vw);

    h2 {
      font-size: calc(16 / 375 * 100vw);
      margin-bottom: calc(8 / 375 * 100vw);
    }
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: calc(8 / 1440 * 100vw);
  margin-bottom: calc(16 / 1440 * 100vw);
  color: #666;
  font-size: calc(14 / 1440 * 100vw);

  &::before {
    content: "✓";
    color: #4caf50;
    font-size: calc(16 / 1440 * 100vw);

    @media (max-width: 768px) {
      font-size: calc(14 / 375 * 100vw);
    }
  }

  @media (max-width: 768px) {
    font-size: calc(12 / 375 * 100vw);
    margin-bottom: calc(8 / 375 * 100vw);
    gap: calc(4 / 375 * 100vw);
  }
`;

const CarColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(24 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(16 / 375 * 100vw);
  }
`;

const CompareButton = styled.button`
  background-color: #0052cc;
  color: white;
  border: none;
  border-radius: calc(8 / 1440 * 100vw);
  padding: calc(16 / 1440 * 100vw) calc(32 / 1440 * 100vw);
  font-size: calc(16 / 1440 * 100vw);
  cursor: pointer;
  margin: calc(16 / 1440 * 100vw) auto calc(32 / 1440 * 100vw);
  display: block;

  &:hover {
    background-color: #0043a7;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    margin: calc(16 / 375 * 100vw) auto;
    padding: calc(10 / 375 * 100vw) calc(24 / 375 * 100vw);
    font-size: calc(16 / 375 * 100vw);
    border-radius: calc(8 / 375 * 100vw);
  }
`;

