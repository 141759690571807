import React, { useEffect } from "react";
import useApi from "../../hooks/useApi";
import styled from "styled-components";

function SellerBanks({ t }) {
  const { get, data } = useApi();

  useEffect(() => {
    get("/bank", { page: 1, page_size: 6 });
  }, [get]);


  return (
    <Container>
      <h1>{t.seller.trusted}</h1>
      {data?.results.map(bank => (
        <BankLogo src={bank.logo} key={bank.id} />
      ))}
    </Container>
  );
}

export default SellerBanks;

const Container = styled.div`
  padding: 4vw 10vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  h1 {
    grid-column: 1 / -1;
    text-align: center;
    font-size: 42px;
    color: #333;
    margin-bottom: 2rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    h1 {
      font-size: 32px;
    }
  }
`;

const BankLogo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 0.5rem;
`;
