import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { ReactComponent as ArrowRight } from "../../images/arrow-right2.svg";
import { ReactComponent as LanguageIcon } from "../../images/language.svg";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../../redux/slices/LanguageSlice";
import { ReactComponent as Close } from "../../images/black-close.svg";
import logo from "../../images/logo.svg";
import Modal from "../assets/Modal";

const MobileNavMenu = ({}) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Modal urlKey={"mobile-menu"} className="mobile-only">
      <StyledMenu language={language}>
        <div className="header">
          <div className="col1">
            <button
              className="close-btn"
              onClick={() => {
                navigate(location.pathname);
              }}
            >
              <Close />
            </button>
            <Link to="/">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </div>
          <button
            className="change-language link-group"
            onClick={() => {
              dispatch(changeLanguage());
            }}
          >
            <LanguageIcon />
            <p>{t.header.lang}</p>
          </button>
        </div>
        <div className="links">
          <Link className="link-group" to="/cars">
            <p> {t.navBar.CarsForSale}</p>
            <ArrowRight />
          </Link>
          <Link className="link-group" to="/build-car">
            <p>{t.navBar.buildCar}</p>
            <ArrowRight />
          </Link>
          <Link className="link-group" to="/car-finance">
            <p>{t.navBar.carFinance}</p>
            <ArrowRight />
          </Link>
          <Link className="link-group" to="/reviews">
            <p>{t.navBar.reviews}</p>
            <ArrowRight />
          </Link>
          <Link className="link-group" to="/car-comparison">
            <p>{t.navBar.carComparison}</p>
            <ArrowRight />
          </Link>
        </div>
      </StyledMenu>
    </Modal>
  );
};
const StyledMenu = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  height: 100vh;
  width: 100vw;
  background: #fff;
  padding: ${mobileSizes._24px} ${mobileSizes._16px};
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${mobileSizes._30px};
    .col1 {
      display: flex;
      align-items: center;
      gap: ${mobileSizes._8px};

      .close-btn {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        svg {
          width: ${mobileSizes._24px};
          height: ${mobileSizes._24px};
        }
      }
      .logo {
        width: 25.867vw;
        height: ${mobileSizes._24px};
      }
    }
    .change-language {
      direction: ltr;
      display: flex;
      gap: ${mobileSizes._8px};
      align-items: center;
      justify-content: start;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      padding: 0;
      width: auto;
      // margin-top: ${mobileSizes._32px};
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    gap: ${mobileSizes._16px};
  }
  .link-group {
    width: 100%;
    border-bottom: ${mobileSizes._1px} solid #eee;
    padding-bottom: ${mobileSizes._16px};
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: #0d0a19;
      font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
      font-size: ${mobileSizes._16px};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "600" : "700")};
      line-height: ${mobileSizes._24px};
    }
    svg {
      width: ${mobileSizes._24px};
      height: ${mobileSizes._24px};
      -webkit-transform: ${props => props.language == "ar" && " scaleX(-1)"};
      -moz-transform: ${props => props.language == "ar" && " scaleX(-1)"};
      -o-transform: ${props => props.language == "ar" && " scaleX(-1)"};
      transform: ${props => props.language == "ar" && " scaleX(-1)"};
    }
  }
`;

export default MobileNavMenu;
