import React, { useState, useEffect } from "react";
import styled from "styled-components";
import s1 from "../../images/sellers/seller1.png";
import s2 from "../../images/sellers/seller2.png";
import { Link } from "react-router-dom";
import { ReactComponent as Next } from "../../images/next.svg";
import { axiosCarvenui } from "../../util/integration";
import { mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import PrevNextPagination from "../assets/PrevNextPagination";
import BoxSkeleton from "../assets/Skeleton/BoxSkeleton";

const Sellers = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const [loading, setLoading] = useState(false);
  const [sellers, setSellers] = useState(null);
  const [query, setQuery] = useState({
    page: 1,
    page_size: 4,
  });
  useEffect(() => {
    const getSellers = async () => {
      try {
        setLoading(true);
        const res = await axiosCarvenui.get(`/seller`, {
          params: {
            ...query,
          },
        });
        const data = res.data;
        console.log("DATA: ", res)
        setSellers(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getSellers();
  }, [query]);
  const handlePageChange = (page) => {
    setQuery({ ...query, page });
  };
  return (
    <StyledSection language={language} sellersCols={Math.ceil(sellers?.results?.length / 2)}>
      <div className="header">
        <div className="section-header">{t.sellers.topSellers}</div>
        <div className="desktop-only">
          <PrevNextPagination
            currentPage={query.page}
            totalItems={sellers?.count || 0} // Total items from backend
            pageSize={query.page_size} // Fixed at 10
            onPageChange={handlePageChange} // Pass function to update page
          />
        </div>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  margin-top: 5.556vw;

  // padding: ${(props) =>
    props.language == "en" ? " 0 0 0 10.417vw" : "0 10.694vw 0 0"};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) =>
    props.language == "en" ? " 0 10.694vw 0 10.417vw" : "0  10.694vw 0px 10.694vw"};

    .view-all {
      display: flex;
      align-items: center;
      gap: 0.556vw;

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 1.25vw;
        line-height: 1.875vw;
        color: #154cd6;
      }
      svg {
        width: 1.25vw;
        height: 1.25vw;
      }
    }
  }
  .sellers {
    display: flex;
    align-items: center;
    gap: 1.944vw;
    margin-top: 2.222vw;
    overflow-x: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .seller.skeleton {
    background-color: #f2f2f2;
    border: none;
  }
  .sellers::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .seller {
    width: 18.264vw;
    min-width: 18.264vw;
    height: 9.653vw;
    background: #ffffff;
    border: 0.069vw solid #e4e6e8;
    border-radius: 0.972vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-right: 0.972vw;
    // margin-left: 0.972vw;

    img {
      width: 12.778vw;
      height: 6.319vw;
      object-fit: contain;
    }
  }
  .seller: first-child {
    margin-left: ${(props) => props.language == "en" && "10.417vw"};
    margin-right: ${(props) => props.language == "ar" && "10.694vw"};
  }

  .seller: last-child {
    margin-right: ${(props) => props.language == "en" && "1.944vw"};
    margin-left: ${(props) => props.language == "ar" && "1.944vw"};
  }
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    .header {
      padding: 0;
      padding-left: ${(props) => props.language == "en" && mobileSizes._16px};
      padding-right: ${(props) => props.language == "ar" && mobileSizes._16px};
      .view-all {
        gap: 2.667vw;

        p {
          font-size: 3.733vw;
          line-height: 5.6vw;
        }
        svg {
          width: 3.2vw;
          height: 3.2vw;
        }
      }
    }
    .sellers {
      display: grid;
      grid-template-columns: ${(props) => `repeat(${props.sellersCols}, 44vw)`};
      gap: ${mobileSizes._8px} ${mobileSizes._8px};
      margin-top: 4.267vw;
      padding: 0 ${mobileSizes._16px} ${mobileSizes._32px} ${mobileSizes._16px};
    }
    .seller {
      width: 44vw;
      height: 19.467vw;
      border: none;
      border-radius: 2.133vw;

      box-shadow: ${(props) =>
    props.language == "en"
      ? "1.067vw 1.067vw 2.133vw rgba(0, 0, 0, 0.12)"
      : "-1.067vw 1.067vw 2.133vw rgba(0, 0, 0, 0.12)"};

      margin-left: 0;
      margin-right: 0;
      img {
        width: 34.933vw;
        height: 17.333vw;
      }
    }
    .seller: first-child {
      margin: 0;
    }
    .seller: last-child {
      margin: 0;
    }
  }
`;
export default Sellers;
