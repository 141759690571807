import React, { useEffect } from "react";
import { Outlet } from "react-router";
import NavBar from "./Components/Home/NavBar";
import Header from "./Components/Home/Header";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Footer from "./Components/assets/Footer";

const DefaultContainer = () => {
  const location = useLocation();
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;

  useEffect(() => {
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId);
    }
  }, [googleAnalyticsId]);

  useEffect(() => {
    if (googleAnalyticsId) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [googleAnalyticsId, location]);

  return (
    <>
      <Header />
      <NavBar />
      <Outlet />
      {/* <Footer /> */}
      <Footer />
    </>
  );
};

export default DefaultContainer;
