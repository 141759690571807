import React, { useState, useEffect } from "react";
import styled from "styled-components";
import shape2 from "../../images/shape1.png";
import { axiosCarvenui } from "../../util/integration";
import SelectMenu from "../assets/SelectMenu";
import StyledForm from "../assets/StyledForm";
import history from "../../util/history";
import covered_car from "../../images/home-covered-car.png";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { useForm } from "react-hook-form";
import LoanCarCard from "./LoanCarCard";

const LoanCalculator = ({ initialBrands, initialLoading, initialNextBrands }) => {
  const [formData, setFormData] = useState({});
  const [brands, setBrands] = useState([]);
  const [years, setYears] = useState([]);
  const [models, setModels] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [carDetails, setCarDetails] = useState([]);
  const [reachedInput, setReachedInput] = useState(1);
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [nextBrands, setNextBrands] = useState("");
  const [nextModels, setNextModels] = useState("");
  const [nextLoading, setNextLoading] = useState(false);
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = useForm();
  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  useEffect(() => {
    setBrands(initialBrands);
    setLoading(initialLoading);
    setNextBrands(initialNextBrands);
  }, [initialBrands, initialLoading, initialNextBrands]);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const getModels = async () => {
      try {
        const res = await axiosCarvenui.get(`car_model/?brand=${formData.brand}&page_size=50`);
        const data = res.data.results.map(model => {
          return {
            ...model,
            value: model.id,
          };
        });
        setModels(data);
        setNextModels(res.data.next);
      } catch (e) {}
    };
    if (formData.brand) {
      setModels([]);
      getModels();
      setFormData(prev => ({ brand: prev.brand }));
      setReachedInput(2);
      clearData();
    }
  }, [formData.brand]);

  useEffect(() => {
    if (models.length == 1) {
      setFormData({ ...formData, model: models[0].value });
      setError("model", null);
    }
  }, [models]);

  useEffect(() => {
    const getCarDetails = async () => {
      try {
        let res;
        if (formData.brand) {
          res = await axiosCarvenui.get(`/car_detail/?car__model=${formData.model}`);
        }

        if (res) {
          let details = res.data.results;
          let yearsArr = [];
          setCarDetails(details);
          details.map(d => {
            yearsArr.push({
              name: d.car?.year,
              value: d.car?.year,
              name_ar: d.car?.year,
            });
          });

          setYears([...new Map(yearsArr.map(y => [y.name, y])).values()]);

          setReachedInput(3);
        }
      } catch (error) {
        console.log(error);
      }
    };
    setYears([]);
    setFormData(prev => ({ brand: prev.brand, model: prev.model }));
    getCarDetails();
  }, [formData.model]);

  useEffect(() => {
    if (years.length == 1) {
      setFormData({ ...formData, year: years[0].value });
      setError("year", null);
    }
  }, [years]);

  useEffect(() => {
    if (formData.year) {
      const filterdDetails = carDetails.filter(d => d.car.year === formData.year);
      // setCarDetails(filterdDetails);
      let colors_arr = filterdDetails.map(d => {
        return {
          name: d.car_color,
          value: d.car_color,
          name_ar: ar.colors[d.car_color.toLowerCase()],
        };
      });

      setColors([...new Map(colors_arr.map(c => [c.name, c])).values()]);

      setReachedInput(4);
      setFormData(prev => ({
        brand: prev.brand,
        model: prev.model,
        year: prev.year,
      }));
    }
  }, [formData.year]);

  const clearData = () => {
    setYears([]);
    setColors([]);
    setReachedInput(2);
  };
  useEffect(() => {
    if (colors.length == 1) {
      setFormData({ ...formData, car_color: colors[0].value });
      setError("car_color", null);
    }
  }, [colors]);
  const calcSubmit = handleSubmit(() => {
    history.push("/car-finance", { calcData: formData });
  });

  const getNextBrands = async () => {
    if (nextBrands !== "" && nextBrands !== null && nextLoading == false) {
      setNextLoading(true);
      let query = nextBrands.split("api/")[1];
      const res = await axiosCarvenui.get(query);
      setBrands([
        ...brands,
        ...res.data.results.map(brand => ({
          ...brand,
          value: brand.id,
        })),
      ]);
      setNextBrands(res.data.next);
      setNextLoading(false);
    }
  };

  const searchBrand = async value => {
    const res = await axiosCarvenui.get("/brand?per_page=50&name__icontains=" + value);
    const data = res.data.results.map(brand => {
      return {
        ...brand,
        value: brand.id,
      };
    });
    setBrands(data);
    setNextBrands(res.data.next);
  };

  const getNextModels = async () => {
    if (nextModels !== "" && nextModels !== null && nextLoading == false) {
      setNextLoading(true);
      let query = nextModels.split("api/")[1];
      const res = await axiosCarvenui.get(query);
      setModels([
        ...models,
        ...res.data.results.map(model => ({
          ...model,
          value: model.id,
        })),
      ]);
      setNextModels(res.data.next);
      setNextLoading(false);
    }
  };

  const brand_field = {
    name: "brand",
    label: t.calculator.brand,
    displayName: t.calculator.brand,
    placeholder: isMobile ? t.calculator.brand : null,
    type: "select",
    options: brands,
    getPaginationData: getNextBrands,
    allowTyping: true,
    search: searchBrand,

    // disableArabic: true,
  };

  const model_field = {
    name: "model",
    label: t.calculator.model,
    displayName: t.calculator.model,
    placeholder: isMobile ? t.calculator.model : null,
    type: "select",
    options: models,
    disabled: reachedInput < 2,
    getPaginationData: getNextModels,
    // disableArabic: true,
  };

  const year_field = {
    name: "year",
    label: t.calculator.year,
    displayName: t.calculator.year,
    placeholder: isMobile ? t.calculator.year : null,
    type: "select",
    options: years,
    disabled: reachedInput < 3,
    disableArabic: true,
  };

  const color_field = {
    name: "car_color",
    label: t.calculator.color,
    displayName: t.calculator.color,
    placeholder: isMobile ? t.calculator.color : null,
    type: "select",
    options: colors,
    disabled: reachedInput < 4,
  };

  const fields = [brand_field, model_field, year_field, color_field];

  return (
    <StyledSection language={language}>
      <div className="container">
        <div className="section-header">{t.calculator.title}</div>

        <div className="form-container">
          <StyledForm
            onSubmit={e => {
              e.preventDefault();
              // handleSubmit();
            }}
          >
            <div className="fields">
              {fields.map(field => (
                <div className="input-div" key={field.name}>
                  <p className="desktop-only">{field.label}</p>
                  <SelectMenu
                    inputWidth="11.181vw"
                    field={field}
                    value={formData[field.name]}
                    disabled={field.disabled}
                    setValue={value => {
                      setFormData({
                        ...formData,
                        [field.name]: value,
                      });
                      setError(field.name, null);
                    }}
                    getPaginationData={field.getPaginationData}
                    registerObj={{
                      ...register(field.name, {
                        required: {
                          value: field.required === false ? false : formData[field.name] ? false : true,
                          message: `required`,
                        },
                      }),
                    }}
                    search={field.search || null}
                    allowTyping={field.allowTyping || false}
                  />
                  {errors[field.name]?.message && (
                    <p className="error-text">
                      {language == "en"
                        ? `${field.displayName} is ${t[errors[field.name]?.message]}`
                        : `${t[errors[field.name]?.message]} ${field.displayName} `}
                    </p>
                  )}
                </div>
              ))}
            </div>
            <div className="btn-container">
              <button onClick={calcSubmit}>{t.calculator.button}</button>
            </div>
          </StyledForm>
        </div>
      </div>
      <LoanCarCard />
    </StyledSection>
  );
};
const StyledSection = styled.div`
  direction: ${props => (props.language === "en" ? "ltr" : "rtl")};
  position: relative;
  height: 38.264vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: calc(1248 / 1440 * 100vw);
  /* width: 100%; */
  overflow: hidden;
  .container {
    width: 31.736vw;
    .form-container {
      margin-top: 1.667vw;

      form {
        .fields {
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 1.667vw;
          column-gap: 1.111vw;
          // margin-bottom: 2.222vw;
          .input-div {
            input {
              width: 15.278vw;
              height: auto;
              padding: ${props =>
                props.language == "en" ? "1.25vw 2.222vw 1.25vw 1.111vw" : "1.25vw 1.111vw  1.25vw 2.222vw "};

              // height: 4.306vw;
              background-size: 0.694vw 0.694vw;
              font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
              font-weight: 500;
              font-size: 1.111vw;
              line-height: 1.667vw;
              background-position-x: ${props => (props.language == "en" ? "91%" : "9%")};
              border-radius: 0;
              @media (min-width: 1440px) {
                font-size: 16px;
                line-height: 24px;
              }
            }
            input::placeholder {
              font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
              font-style: normal;
              font-weight: ${props => (props.language == "en" ? "500" : "300")};
              font-size: 0.972vw;
              line-height: 1.458vw;
              color: #9a9ea7;
              opacity: 1;

              @media (min-width: 1440px) {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }
        .btn-container {
          margin-top: 2.222vw;
          button {
            width: 31.736vw;
            height: 3.194vw;
            border-radius: 0.278vw;
          }
        }
      }
    }
  }
  .shape1 {
    /* position: absolute; */
    width: 38.36vw;
    height: 24.931vw;
    /* left: ${props => props.language == "en" && " 44.21vw"};
    right: ${props => props.language == "ar" && "44.236vw"}; */
    top: 6.667vw;
    background: #ffffff;
    box-shadow: 0px 0.556vw 0.833vw rgba(153, 155, 168, 0.15);
    border-radius: 0.972vw;
    z-index: 10000;
    /* padding: ${desktopSizes._40px} ${desktopSizes._27px}; */
    img {
      width: 34.653vw;
      height: 19.444vw;
      -webkit-transform: ${props => props.language === "ar" && "scaleX(-1)"};
      transform: ${props => props.language === "ar" && "scaleX(-1)"};
    }
  }
  .shape2 {
    width: 46.38vw;
    height: 25.556vw;
    position: absolute;
    left: ${props => props.language == "en" && "55.625vw"};
    right: ${props => props.language == "ar" && "55.625vw"};
    -webkit-transform: ${props => props.language === "ar" && "scaleX(-1)"};
    transform: ${props => props.language === "ar" && "scaleX(-1)"};
    top: 13.403vw;
  }

  @media (max-width: 768px) {
    margin-top: 6.4vw;
    padding: 0 4.267vw;
    height: auto;
    display: flex;
    /* flex-direction: column; */
    /* reverse the order of the elements */
    flex-direction: column-reverse;
    overflow: visible;
    .container {
      width: 100%;

      .form-container {
        margin-top: ${mobileSizes._16px};
        background: #ffffff;
        border-radius: ${mobileSizes._8px};
        padding: ${mobileSizes._8px} ${mobileSizes._16px} ${mobileSizes._24px} ${mobileSizes._16px};
        form {
          .fields {
            display: flex;
            flex-direction: column;
            .menu-container {
              width: 82.933vw;
            }
            .input-div {
              label {
                font-size: ${mobileSizes._12px};
                line-height: ${mobileSizes._18px};
              }
              input {
                width: 82.933vw;
                background-size: ${mobileSizes._10px} ${mobileSizes._10px};
                background-color: #fff;
                border: 0;
                border-bottom: ${mobileSizes._1px} solid #eeeeee;
                padding: 4.8vw 0;
                outline: none;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: ${mobileSizes._16px};
                line-height: ${mobileSizes._24px};
              }
              input::placeholder {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: ${mobileSizes._16px};
                line-height: ${mobileSizes._19px};
                color: #9a9ea7;
              }
            }
          }
          .btn-container {
            margin-top: ${mobileSizes._16px};
            button {
              cursor: pointer;
              width: 82.933vw;
              height: 14.933vw;
              border-radius: ${mobileSizes._8px};
              font-size: ${mobileSizes._16px};
              line-height: ${mobileSizes._21px};
            }
          }
        }
      }
    }
  }
`;
export default LoanCalculator;
