import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const StyledForm = ({ children, onSubmit, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <Form onSubmit={onSubmit} language={language} noValidate autoComplete="off">
      <>{children}</>
    </Form>
  );
};
const Form = styled.form`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  .fields {
    display: flex;
    flex-direction: column;
    gap: 1.667vw;
    .input-div {
      position: relative;
      p {
        font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
        font-style: normal;
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.458vw;
        margin-bottom: 0.556vw;

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
      .error-text {
        font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
        font-style: normal;
        font-size: ${desktopSizes._12px};
        line-height: ${desktopSizes._18px};
        font-weight: 400;
        margin-top: ${desktopSizes._4px};
        margin-bottom: 0;
        color: #eb0100;

        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
      input::placeholder {
        font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
        font-style: normal;
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #9a9ea7;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
      .currency {
        position: absolute;
        right: ${props => props.language == "en" && "1.389vw"};
        left: ${props => props.language == "ar" && "1.389vw"};
        top: 3.472vw;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #828282;

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }

        @media (max-width: 768px) {
          right: ${props => props.language == "en" && `${mobileSizes._18px}`};
          left: ${props => props.language == "ar" && `${mobileSizes._18px}`};
          top: 11.2vw;
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
        }
      }
      input,
      select {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #0d0a19;
        display: block;
        padding: 1.736vw 1.111vw;
        outline: none;
        border-radius: 0.556vw;
        border: solid 0.07vw #eeeeee;
        background-color: #ffffff;
        width: 29.167vw;
        // height: 5vw;

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
      input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .active_label {
        padding-top: 2.5vw;
        padding-bottom: 1.111vw;
        @media (max-width: 768px) {
          padding-top: ${mobileSizes._30px};
          padding-bottom: ${mobileSizes._8px};
        }
      }
      .arabic-input {
        font-family: Almarai;
      }
      .number-placeholder::placeholder {
        font-family: "Poppins";
      }
      .password-input {
        padding-right: ${props => props.language == "en" && "4.167vw"};
        padding-left: ${props => props.language == "ar" && "4.167vw"};
      }
      .password-icon {
        background: none;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: ${props => props.language == "en" && "1.667vw"};
        left: ${props => props.language == "ar" && "1.667vw"};
        top: 1.667vw;
        svg {
          width: 1.667vw;
          height: 1.667vw;

          @media (min-width: 1440px) {
            width: 24px;
            height: 24px;
          }
        }
      }
      .password-input {
        padding-right: ${props => props.language == "en" && "12.8vw"};
        padding-left: ${props => props.language == "ar" && "12.8vw"};
      }

      .label {
        font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
        font-style: normal;
        font-weight: ${props => (props.language == "en" ? "400" : "500")};
        font-size: 0.833vw;
        line-height: 1.25vw;
        color: #9a9ea7;
        position: absolute;
        top: ${desktopSizes._12px};
        left: ${props => props.language == "en" && "1.111vw"};
        right: ${props => props.language == "ar" && "1.111vw"};
        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .mobile-input-div {
        position: relative;
        // @media (min-width: 1440px) {
        //   .label {
        //     top: 16px;
        //   }
        // }
        input {
          padding: ${props =>
            props.language == "en" ? "1.111vw 1.111vw 1.111vw 8.681vw" : "1.111vw 8.681vw 1.111vw 1.111vw "};

          -webkit-appearance: none;
          margin: 0;
          -moz-appearance: textfield;
        }
        .select-code {
          position: absolute;
          left: ${props => props.language == "en" && "1.111vw"};
          right: ${props => props.language == "ar" && "1.111vw"};
          top: 2.667vh;
          width: 8.681vw;
          @media (min-width: 769px) and (max-width: 1024px) {
            top: 1.667vw;
          }
          input {
            color: transparent;
            width: 6.944vw;
            height: 2.444vh;
            font-size: 0px;
            outline: none;
            border: none;
            background-color: white;
            background-size: 0.694vw 0.694vw;
            background-position: ${props => (props.language == "en" ? "98% 50%" : "2% 50%")};
            padding: 0;
            @media (min-width: 769px) and (max-width: 1024px) {
              height: 1.528vw;
            }
            // @media (min-width: 1440px) {
            //   height: 40px;
            // }

            // @media (max-width: 768px) {
            //   width: 100%;
            //   height: 16.667vw;
            //   border-radius: 2.051vw;
            //   border: solid 0.256vw #d9e1e7;
            // }
          }
        }
      }
    }
  }
  .btn-container {
    margin-top: 1.667vw;
    button {
      width: 29.167vw;
      height: 3.75vw;
      cursor: pointer;
      outline: none;
      background: #154cd6;
      border-radius: 0.694vw;
      font-family: ${props => (props.language == "en" ? "Poppins" : "Almarai")};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "600" : "700")};
      font-size: 1.111vw;
      line-height: 1.458vw;
      color: #ffffff;
      cursor: pointer;
      &:disabled {
        opacity: 0.3;
      }

      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  @media (max-width: 768px) {
    .fields {
      gap: ${mobileSizes._16px};
      .input-div {
        p {
          font-weight: 400;
          font-size: ${mobileSizes._12px};
          line-height: ${mobileSizes._18px};
          margin-bottom: ${mobileSizes._6px};
        }

        .error-text {
          font-size: ${mobileSizes._12px};
          line-height: ${mobileSizes._18px};
          margin-top: ${mobileSizes._4px};
        }
        input::placeholder {
          font-weight: ${props => (props.language == "en" ? "400" : "500")};
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
        }
        input,
        select {
          font-weight: ${props => (props.language == "en" ? "400" : "500")};
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
          padding: ${mobileSizes._20px} ${mobileSizes._16px};
          border-radius: ${mobileSizes._8px};
          border: solid ${mobileSizes._1px} #eeeeee;
          width: 91.467vw;
          height: auto;
        }
        .password-icon {
          right: ${props => props.language == "en" && mobileSizes._16px};
          left: ${props => props.language == "ar" && mobileSizes._16px};
          top: ${mobileSizes._18px};
          svg {
            width: ${mobileSizes._24px};
            height: ${mobileSizes._24px};
          }
        }
        .label {
          font-weight: ${props => (props.language == "en" ? "400" : "500")};
          font-size: ${mobileSizes._12px};
          line-height: ${mobileSizes._18px};
          top: ${mobileSizes._8px};
          left: ${props => props.language == "en" && mobileSizes._16px};
          right: ${props => props.language == "ar" && mobileSizes._16px};
        }

        .mobile-input-div {
          input {
            padding: ${props =>
              props.language == "en"
                ? `${mobileSizes._16px} ${mobileSizes._16px} ${mobileSizes._16px} 30vw`
                : `${mobileSizes._16px} 30vw ${mobileSizes._16px} ${mobileSizes._16px} `};
          }
          .select-code {
            position: absolute;
            left: ${props => props.language == "en" && mobileSizes._16px};
            right: ${props => props.language == "ar" && mobileSizes._16px};
            top: ${mobileSizes._20px};
            width: 25vw;
            input {
              color: transparent;
              width: 25vw;
              height: ${mobileSizes._21px};
              font-size: 0px;
              outline: none;
              border: none;
              background-color: white;
              background-size: ${mobileSizes._10px} ${mobileSizes._10px};
              background-position: ${props => (props.language == "en" ? "98% 50%" : "2% 50%")};
              padding: 0;
            }
          }
        }
      }
    }
    .btn-container {
      margin-top: ${mobileSizes._24px};
      button {
        width: 91.467vw;
        height: 13.6vw;
        border-radius: ${mobileSizes._8px};
        font-family: ${props => (props.language == "en" ? "Inter" : "Almarai")};
        font-weight: ${props => (props.language == "en" ? "600" : "700")};
        font-size: ${mobileSizes._16px};
        line-height: ${mobileSizes._19px};
      }
    }
  }
`;
export default StyledForm;
