import React, { useState } from "react";
import styled from "styled-components";
import { FacebookIcon, InstagramIcon, PlusIcon, TwitterIcon } from "./Icons";
import logo from "../../images/logo.svg";
import useTranslation from "../../hooks/useTranslation";
import { Link } from "react-router-dom";

const FooterContainer = styled.footer`
  background: #ffffff;
  * {
    font-family: ${props => (props.language === "en" ? "Poppins" : "Almarai")};
  }
`;

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: calc(40 / 1440 * 100vw);
  margin-bottom: calc(48 / 1440 * 100vw);
  width: calc(1254 / 1440 * 100vw);
  padding-top: calc(100 / 1440 * 100vw);
  margin: 0 auto;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: calc(32 / 1440 * 100vw);
  }
`;

const Logo = styled.div`
  margin-bottom: calc(16 / 1440 * 100vw);

  img {
    width: calc(162 / 1440 * 100vw);
  }

  @media (max-width: 768px) {
    margin-bottom: calc(16 / 375 * 100vw);
    img {
      width: calc(120 / 375 * 100vw);
    }
  }
`;

const Description = styled.p`
  color: #6b7280;
  font-size: calc(14 / 1440 * 100vw);
  line-height: 1.5;
  margin-bottom: calc(24 / 1440 * 100vw);
  max-width: calc(480 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(14 / 375 * 100vw);
    margin-bottom: calc(16 / 375 * 100vw);
    max-width: 100%;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: calc(16 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(16 / 375 * 100vw);
    margin: calc(10 / 375 * 100vw) 0;
  }
`;

const SocialIcon = styled.a`
  width: calc(40 / 1440 * 100vw);
  height: calc(40 / 1440 * 100vw);
  background: #f3f4f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    background: #263959;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  svg {
    width: calc(16 / 1440 * 100vw);
    height: calc(16 / 1440 * 100vw);
  }

  @media (max-width: 768px) {
    width: calc(40 / 375 * 100vw);
    height: calc(40 / 375 * 100vw);
    svg {
      width: calc(16 / 375 * 100vw);
      height: calc(16 / 375 * 100vw);
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    opacity: ${props => (props.isOpen ? 1 : 0)};
    height: ${props => (props.isOpen ? "auto" : 0)};
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
`;

const ColumnTitle = styled.h3`
  color: #263959;
  font-size: calc(18 / 1440 * 100vw);
  font-weight: 600;
  margin-bottom: calc(24 / 1440 * 100vw);
  display: flex;
  align-items: center;
  gap: calc(10 / 1440 * 100vw);

  svg {
    width: calc(20 / 1440 * 100vw);
    height: calc(20 / 1440 * 100vw);
    fill: #263959;
    stroke: #263959;
  }

  button {
    background: none;
    border: none;
    visibility: hidden;
  }

  @media (max-width: 768px) {
    font-size: calc(18 / 375 * 100vw);
    margin-bottom: calc(16 / 375 * 100vw);
    gap: calc(13 / 375 * 100vw);
    svg {
      width: calc(17 / 375 * 100vw);
      height: calc(17 / 375 * 100vw);
    }
    button {
      visibility: visible;
    }
  }
`;

const ColumnLink = styled.a`
  color: #6b7280;
  text-decoration: none;
  font-size: calc(14 / 1440 * 100vw);
  margin-bottom: calc(16 / 1440 * 100vw);
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #1e3a8a;
  }

  @media (max-width: 768px) {
    font-size: calc(14 / 375 * 100vw);
    margin-bottom: calc(16 / 375 * 100vw);
  }
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(32 / 1440 * 100vw) 0;
  border-top: 1px solid #e5e7eb;
  width: calc(1254 / 1440 * 100vw);
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: calc(16 / 375 * 100vw);
    text-align: center;
  }
`;

const Copyright = styled.p`
  color: #6b7280;
  font-size: calc(14 / 1440 * 100vw);

  @media (max-width: 768px) {
    font-size: calc(14 / 375 * 100vw);
  }
`;

const BottomLinks = styled.div`
  display: flex;
  gap: calc(24 / 1440 * 100vw);

  @media (max-width: 768px) {
    gap: calc(16 / 375 * 100vw);
  }
`;

const DealerSection = styled.div`
  background: #f3f7ff;
  padding: calc(24 / 1440 * 100vw);

  margin-top: calc(48 / 1440 * 100vw);

  @media (max-width: 768px) {
    padding: calc(24 / 375 * 100vw);
    margin-top: calc(32 / 375 * 100vw);
  }
`;

const Wrapper = styled.div`
  width: calc(1254 / 1440 * 100vw);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: calc(16 / 375 * 100vw);
  }
`;

const DealerTitle = styled.h2`
  color: #0052ff;
  font-size: calc(32 / 1440 * 100vw);
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: calc(24 / 375 * 100vw);
  }
`;

const RegisterLink = styled(Link)`
  width: calc(288 / 1440 * 100vw);
  background: #ffffff;
  color: #0052ff;
  border: 2px solid #0052ff;
  border-radius: calc(8 / 1440 * 100vw);
  padding: calc(12 / 1440 * 100vw) calc(24 / 1440 * 100vw);
  font-weight: 600;
  font-size: calc(16 / 1440 * 100vw);
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #0052ff;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: calc(288 / 375 * 100vw);
    padding: calc(12 / 375 * 100vw) calc(24 / 375 * 100vw);
    font-size: calc(16 / 375 * 100vw);
  }
`;

const Footer = () => {
  const { t, language } = useTranslation();
  const [isOpen, setIsOpen] = useState({
    aboutUs: false,
    usefulLinks: false,
    help: false,
  });

  const toggleOpen = key => {
    setIsOpen({ ...isOpen, [key]: !isOpen[key] });
  };

  return (
    <>
      <FooterContainer dir={language === "en" ? "ltr" : "rtl"} language={language}>
        <TopSection>
          <div>
            <Logo>
              <img src={logo} alt="logo" />
            </Logo>
            <Description>{t.footer.text}</Description>
            <SocialLinks>
              <SocialIcon href="#" $primary>
                <InstagramIcon />
              </SocialIcon>
              <SocialIcon href="#">
                <FacebookIcon />
              </SocialIcon>
              <SocialIcon href="#">
                <TwitterIcon />
              </SocialIcon>
            </SocialLinks>
          </div>

          <Column>
            <ColumnTitle>
              {t.footer.aboutUs}
              <button onClick={() => toggleOpen("aboutUs")}>
                <PlusIcon />
              </button>
            </ColumnTitle>

            <LinksWrapper isOpen={isOpen.aboutUs}>
              <ColumnLink href="#">{t.footer.ourCompany}</ColumnLink>
              <ColumnLink href="#">{t.footer.contactUs}</ColumnLink>
            </LinksWrapper>
          </Column>

          <Column>
            <ColumnTitle>
              {t.footer.usefulLinks}
              <button onClick={() => toggleOpen("usefulLinks")}>
                <PlusIcon />
              </button>
            </ColumnTitle>
            <LinksWrapper isOpen={isOpen.usefulLinks}>
              <ColumnLink href="#">{t.footer.buyCar}</ColumnLink>
              <ColumnLink href="#">{t.footer.buildYourCar}</ColumnLink>
              <ColumnLink href="#">{t.footer.carFinance}</ColumnLink>
              <ColumnLink href="#">{t.footer.getAQuote}</ColumnLink>
              <ColumnLink href="#">{t.footer.carReviews}</ColumnLink>
              <ColumnLink href="#">{t.footer.carComparison}</ColumnLink>
            </LinksWrapper>
          </Column>

          <Column>
            <ColumnTitle>
              {t.footer.help}
              <button onClick={() => toggleOpen("help")}>
                <PlusIcon />
              </button>
            </ColumnTitle>
            <LinksWrapper isOpen={isOpen.help}>
              <ColumnLink href="#">{t.footer.terms}</ColumnLink>
              <ColumnLink href="#">{t.footer.privacy}</ColumnLink>
              <ColumnLink href="#">{t.footer.customerFees}</ColumnLink>
              <ColumnLink href="#">{t.footer.security}</ColumnLink>
            </LinksWrapper>
          </Column>
        </TopSection>
        <DealerSection>
          <Wrapper>
            <DealerTitle>{t.footer.joinUsAsADealer}</DealerTitle>
            <RegisterLink to="/dealer-join">{t.footer.registerNow}</RegisterLink>
          </Wrapper>
        </DealerSection>

        <BottomSection>
          <Copyright>Copyright 2024 SpreeSolutions</Copyright>
          <BottomLinks>
            <ColumnLink href="#">{t.footer.privacyPolicy}</ColumnLink>
            <ColumnLink href="#">{t.footer.termUse}</ColumnLink>
          </BottomLinks>
        </BottomSection>
      </FooterContainer>
    </>
  );
};

export default Footer;
